import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  appr: {
    label: 'Затверджено', name: 'МаксНомерПодписи', visible: true, type: 'bool',
  },
  isApproved: {
    label: 'Отримано', name: 'ДокументУтвержден', visible: true, type: 'bool',
  },
  invoice: { label: 'Квитанція', name: 'Квитанция', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  docSendToLogica: { label: 'Документ відправка до Логіки', name: 'ДокОтправкаВЛогику' },
  area: { label: 'Код регіону', name: 'КодРегиона' },
  disrtict: { label: 'Код району', name: 'КодРайона' },
  KATOTTG: { label: 'Код за КАТОТТГ території територіальної громади', name: 'КодКАТОТТГ' },
  name: { label: 'Найменування органу місцевого самоврядування', name: 'НаимМС' },
  noteAdd1: { label: 'Коментар до додатку 1', name: 'КомментарийДод1' },
  noteAdd2: { label: 'Коментар до додатку 2', name: 'КомментарийДод2' },
  noteAdd2P: { label: 'Коментар до додатку 2П', name: 'КомментарийДод2П' },
  noteAdd3: { label: 'Коментар до додатку 3', name: 'КомментарийДод3' },
  noteAdd3P: { label: 'Коментар до додатку 3П', name: 'КомментарийДод3П' },
  noteAdd4: { label: 'Коментар до додатку 4', name: 'КомментарийДод4' },
  noteAdd5: { label: 'Коментар до додатку 5', name: 'КомментарийДод5' },
  noteAdd6: { label: 'Коментар до додатку 6', name: 'КомментарийДод6' },
  noteAdd7: { label: 'Коментар до додатку 7', name: 'КомментарийДод7' },
  noteAdd8: { label: 'Коментар до додатку 8', name: 'КомментарийДод8' },
  noteAdd11: { label: 'Коментар до додатку 11', name: 'КомментарийДод11' },
  year: { label: 'Рiк', name: 'Год', visible: true },
  isCheck: { label: 'НашлиОшибки', name: 'НашлиОшибки', visible: true },
};

/**
 * @const
 */
const tables = {
  TABL_10: {
    name: 'Табл10',
    label: 'Табл 10',
    columns: {
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
      koutuuMs: {
        label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення',
        name: 'КодКОАТУУ_МС',
      },
      nameMs: {
        label: 'Назва органу, що прийняв рішення',
        name: 'КАТОТТГ_МС',
      },
      koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add2Tax: {
        label: 'Номер додатка зі ставками  податку (Зем.податок)',
        name: 'ДодЗемля',
      },
      add2TaxAdd: {
        label: 'Додатковий номер додатка зі ставками податку (за наявності) (Зем.податок)',
        name: 'ДодЗемляДоп',
      },
      add2Privilege: {
        label: 'Номер додатка з пільгами (Зем.податок)',
        name: 'ДодЗемляЛьготы',
      },
      add2PrivilegeAdd: {
        label: 'Додатковий номер додатка з пільгами (за наявності) (Зем.податок)',
        name: 'ДодЗемляДопЛьготы',
      },
      add3Tax: {
        label: 'Номер додатка зі ставками  податку (Под.на нерух.майно, відмінне від зем.ділянки)',
        name: 'ДодНедв',
      },
      add3TaxAdd: {
        label: 'Додатковий номер додатка зі ставками податку (за наявності) (Под.на нерух.майно, відмінне від зем.д',
        name: 'ДодНедвДоп',
      },
      add3Privilege: {
        label: 'Номер додатка з пільгами (Под.на нерух.майно, відмінне від зем.ділянки)',
        name: 'ДодНедвЛьготы',
      },
      add3PrivilegeAdd: {
        label: 'Додатковий номер додатка з пільгами (за наявності) (Под.на нерух.майно, відмінне від зем.д',
        name: 'ДодНедвДопЛьготы',
      },
      add4Tax: {
        label: 'Номер додатка (Тур.збір)',
        name: 'ДодТур',
      },
      add4TaxAdd: {
        label: 'Додатковий номер додатка (за наявності) (Тур.збір)',
        name: 'ДодТурДоп',
      },
      add5Tax: {
        label: 'Номер додатка (Збір за місця для паркування транспортних засобів)',
        name: 'ДодПарковка',
      },
      add5TaxAdd: {
        label: 'Додатковий номер додатка (за наявності) (Збір за місця для паркування транспортних засобів)',
        name: 'ДодПарковкаДоп',
      },
      add6Tax: {
        label: 'Номер додатка (Транспортний податок)',
        name: 'ДодТранспорт',
      },
      add6TaxAdd: {
        label: 'Додатковий номер додатка (за наявності) (Транспортний податок)',
        name: 'ДодТранспортДоп',
      },
      add7Tax: {
        label: 'Номер додатка (Єдиний податок для платників єдиного податку першої групи)',
        name: 'ДодЕН1',
      },
      add7TaxAdd: {
        label: 'Додатковий номер додатка (за наявності) (Єдиний податок для платників єдиного податку першої групи)',
        name: 'ДодЕН1Доп',
      },
      add8Tax: {
        label: 'Номер додатка (Єдиний податок для платників єдиного податку другої групи)',
        name: 'ДодЕН2',
      },
      add8TaxAdd: {
        label: 'Додатковий номер додатка (за наявності) (Єдиний податок для платників єдиного податку другої групи)',
        name: 'ДодЕН2Доп',
      },
      add11Rent: {
        label: 'Номер додатка (Орендна плата)',
        name: 'ДодОренда',
      },
      add11RentAdd: {
        label: 'Додатковий номер додатка (за наявності) (Орендна плата)',
        name: 'ДодОрендаДоп',
      },
    },
  },
  TABL_20: {
    name: 'Табл20',
    label: 'Табл 20',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 2', name: 'Додаток2' },
    },
  },
  TABL_30: {
    name: 'Табл30',
    label: 'Табл 30',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 3', name: 'Додаток2П' },
    },
  },
  TABL_40: {
    name: 'Табл40',
    label: 'Табл 40',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 4 ', name: 'Додаток3' },
    },
  },
  TABL_50: {
    name: 'Табл50',
    label: 'Табл 50',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 5 ', name: 'Додаток3П' },
    },
  },
  TABL_60: {
    name: 'Табл60',
    label: 'Табл 60',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 6 ', name: 'Додаток4' },
    },
  },
  TABL_70: {
    name: 'Табл70',
    label: 'Табл 70',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 7 ', name: 'Додаток5' },
    },
  },
  TABL_80: {
    name: 'Табл80',
    label: 'Табл 80',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 8 ', name: 'Додаток6' },
    },
  },
  TABL_90: {
    name: 'Табл90',
    label: 'Табл 90',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 9 ', name: 'Додаток7' },
    },
  },
  TABL_100: {
    name: 'Табл100',
    label: 'Табл 100',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 10 ', name: 'Додаток8' },
    },
  },
  TABL_110: {
    name: 'Табл110',
    label: 'Табл 110',
    columns: {
      code: { label: 'Код КАТОТТГ (КОАТУУ) органу, що прийняв рішення', name: 'КодКАТОТТГ' },
      koatuu: { label: 'Назва органу місцевого самоврядування, що прийняв рішення', name: 'КОАТУУ' },
      solutionNumber: {
        label: 'Номер рішення',
        name: 'НомерРешения',
      },
      solutionDate: {
        label: 'Дата рішення',
        name: 'ДатаРешения',
      },
      forceDate: {
        label: 'Дата набрання чинності рішення',
        name: 'ДатаЧинності',
      },
      dateFrom: {
        label: 'Дата з якої застосовуються ставки та пільги',
        name: 'ДатаЗ',
      },
       koatuuAto: {
        label: 'Код КАТОТТГ (КОАТУУ) АТО, розміщених на території ...',
        name: 'КодКОАТУУ_АТО',
      },
      nameAto: {
        label: 'Назва АТО, розміщених на території ...',
        name: 'КАТОТТГ_АТО',
      },
      add: { label: 'Додаток 11', name: 'Додаток11' },
    },
  },
  descendantsDocuments: {
    name: 'Доки',
    label: 'Документи підпорядкованих розпорядників',
    columns: {
      document: { label: 'Документ', name: 'Документ' },
      isApproved: { label: 'Затверджений', name: 'Утвержден' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги',
  name: 'taxes',
  backendName: 'НалогиИЛьготы',
  columns,
  frontend: 'doc/taxes',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'isApproved',
      'appr',
      'note',
      'author',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
