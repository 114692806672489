/* eslint-disable react/no-array-index-key */
import React, {
  memo, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

const SUMS = [
  'consolidated_plan_total',
  'consolidated_plan_pay',
  'consolidated_plan_labor_payment',
  'consolidated_plan_medicines',
  'consolidated_plan_food',
  'consolidated_plan_services',
  'consolidated_plan_social',
  'consolidated_plan_other',
  'utilization_plan_total',
  'utilization_plan_pay',
  'utilization_plan_labor_payment',
  'utilization_plan_medicines',
  'utilization_plan_food',
  'utilization_plan_services',
  'utilization_plan_social',
  'utilization_plan_other',
  'cash_expenses_total',
  'cash_expenses_pay',
  'cash_expenses_labor_payment',
  'cash_expenses_medicines',
  'cash_expenses_food',
  'cash_expenses_services',
  'cash_expenses_social',
  'cash_expenses_other',
];
function HealthCareExpensesRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  authority,
}) {
  const amountChangeHandler = useCallback(
    (e, newValue, m) => {
      if (m < 8) {
        const total = [...Array(7)].reduce(
          (R, _i, k) => (R + (k + 1 === m ? newValue : row[SUMS[k + 1]] || 0)),
          0,
        );
        onChange(e, rowIndex, {
          [SUMS[m]]: newValue,
          consolidated_plan_total: total,
        });
      } else if (m > 8 && m < 16) {
        const total = [...Array(7)].reduce(
          (R, _i, k) => (R + (k + 9 === m ? newValue : row[SUMS[k + 9]] || 0)),
          0,
        );
        onChange(e, rowIndex, {
          [SUMS[m]]: newValue,
          utilization_plan_total: total,
        });
      } else {
        const total = [...Array(7)].reduce(
          (R, _i, k) => (R + (k + 17 === m ? newValue : row[SUMS[k + 17]] || 0)),
          0,
        );
        onChange(e, rowIndex, {
          [SUMS[m]]: newValue,
          cash_expenses_total: total,
        });
      }
    },
    [onChange, row, rowIndex],
  );

  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerFilter = useMemo(() => ({
    parent: authority ? authority.id : 0,
  }), [authority]);
  const consolidatedPlanTotal = tableHooks.useNumberInputProps('consolidated_plan_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const consolidatedPlanPay = tableHooks.useNumberInputProps('consolidated_plan_pay', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const consolidatedPlanLaborPayment = tableHooks.useNumberInputProps('consolidated_plan_labor_payment', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const consolidatedPlanMedicines = tableHooks.useNumberInputProps('consolidated_plan_medicines', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const consolidatedPlanFood = tableHooks.useNumberInputProps('consolidated_plan_food', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const consolidatedPlanServices = tableHooks.useNumberInputProps('consolidated_plan_services', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const consolidatedPlanSocial = tableHooks.useNumberInputProps('consolidated_plan_social', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const consolidatedPlanOther = tableHooks.useNumberInputProps('consolidated_plan_other', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilizationPlanTotal = tableHooks.useNumberInputProps('utilization_plan_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilizationPlanPay = tableHooks.useNumberInputProps('utilization_plan_pay', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilizationPlanLaborPayment = tableHooks.useNumberInputProps('utilization_plan_labor_payment', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilizationPlanMedicines = tableHooks.useNumberInputProps('utilization_plan_medicines', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilizationPlanFood = tableHooks.useNumberInputProps('utilization_plan_food', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilizationPlanServices = tableHooks.useNumberInputProps('utilization_plan_services', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilizationPlanSocial = tableHooks.useNumberInputProps('utilization_plan_social', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const utilizationPlanOther = tableHooks.useNumberInputProps('utilization_plan_other', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesTotal = tableHooks.useNumberInputProps('cash_expenses_total', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesPay = tableHooks.useNumberInputProps('cash_expenses_pay', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesLaborPayment = tableHooks.useNumberInputProps('cash_expenses_labor_payment', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesMedicines = tableHooks.useNumberInputProps('cash_expenses_medicines', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesFood = tableHooks.useNumberInputProps('cash_expenses_food', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesServices = tableHooks.useNumberInputProps('cash_expenses_services', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesSocial = tableHooks.useNumberInputProps('cash_expenses_social', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const cashExpensesOther = tableHooks.useNumberInputProps('cash_expenses_other', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumProps = useMemo(
    () => [
      consolidatedPlanTotal, consolidatedPlanPay, consolidatedPlanLaborPayment,
      consolidatedPlanMedicines,
      consolidatedPlanFood, consolidatedPlanServices, consolidatedPlanSocial, consolidatedPlanOther,
      utilizationPlanTotal, utilizationPlanPay, utilizationPlanLaborPayment,
      utilizationPlanMedicines,
      utilizationPlanFood, utilizationPlanServices, utilizationPlanSocial, utilizationPlanOther,
      cashExpensesTotal, cashExpensesPay, cashExpensesLaborPayment, cashExpensesMedicines,
      cashExpensesFood, cashExpensesServices, cashExpensesSocial, cashExpensesOther,
    ],
    [cashExpensesFood, cashExpensesLaborPayment, cashExpensesMedicines, cashExpensesOther,
      cashExpensesPay, cashExpensesServices, cashExpensesSocial, cashExpensesTotal,
      consolidatedPlanFood, consolidatedPlanLaborPayment, consolidatedPlanMedicines,
      consolidatedPlanOther, consolidatedPlanPay,
      consolidatedPlanServices, consolidatedPlanSocial, consolidatedPlanTotal, utilizationPlanFood,
      utilizationPlanLaborPayment, utilizationPlanMedicines, utilizationPlanOther,
      utilizationPlanPay, utilizationPlanServices, utilizationPlanSocial, utilizationPlanTotal],
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
            <TableControls.ItemPicker
              {...disposerProps}
              filter={disposerFilter}
              noHierarchy
            />
          </TabelRowCell>
        </Col>
      </Row>
      <Row>
        {[...Array(6)].map((c, colN) => (
          <Col sm={2} key={colN}>
            <Row noGutters>
              {[...Array(4)].map((r, rowN) => (
                <Col sm={12} key={rowN}>
                  <TabelRowCell
                    column={SUMS[colN * 4 + rowN]}
                    highlighted={highlights.includes(SUMS[colN * 4 + rowN])}
                  >
                    <TableControls.NumberInput
                      {...sumProps[colN * 4 + rowN]}
                      readOnly={(colN * 4 + rowN) === 0 || (colN * 4 + rowN) === 8
                          || (colN * 4 + rowN) === 16 || sumProps[colN * 4 + rowN].readOnly}
                      onChange={(e, v) => amountChangeHandler(e, v, (colN * 4 + rowN))}
                    />
                  </TabelRowCell>
                </Col>
              ))}
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

HealthCareExpensesRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

HealthCareExpensesRow.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(HealthCareExpensesRow);
