import React, {
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import { DocumentBody, Footer } from '../../../../components/Containers/docs';
import useEditor from '../../../newEditor/hook/editor';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import EditorContainer from '../../../newEditor/editorContainer';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { HidableContainer } from '../../../../components/Styled/Misc';
import ApprovedInfo from '../../common/approvedInfo';
import SendToLogicaButton from '../../common/components/SendToLogicaButton';
import useEditorParams from '../../../newEditor/hook/params';
import LoadLevelDownButton from './loadLeveldown';
import { LabelTab, TabContainer } from '../../../basicEditor/tpController/styles';
import LevelDownRows2 from './tp/expensesForWagesLevelDownRow';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    // eslint-disable-next-line no-unused-vars
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.expensesForWages.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const signVariantProps = editorHooks.useItemInputProps('authority_sign_variant', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const monthProps = editorHooks.useSelectorInputProps('month', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g1Props = editorHooks.useNumberInputProps('r01g1', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g2Props = editorHooks.useNumberInputProps('r01g2', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g3Props = editorHooks.useNumberInputProps('r01g3', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g4Props = editorHooks.useNumberInputProps('r01g4', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g5Props = editorHooks.useNumberInputProps('r01g5', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g6Props = editorHooks.useNumberInputProps('r01g6', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g7Props = editorHooks.useNumberInputProps('r01g7', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g8Props = editorHooks.useNumberInputProps('r01g8', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g9Props = editorHooks.useNumberInputProps('r01g9', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g10Props = editorHooks.useNumberInputProps('r01g10', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g11Props = editorHooks.useNumberInputProps('r01g11', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g12Props = editorHooks.useNumberInputProps('r01g12', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g13Props = editorHooks.useNumberInputProps('r01g13', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g14Props = editorHooks.useNumberInputProps('r01g14', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g15Props = editorHooks.useNumberInputProps('r01g15', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g16Props = editorHooks.useNumberInputProps('r01g16', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g17Props = editorHooks.useNumberInputProps('r01g17', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g18Props = editorHooks.useNumberInputProps('r01g18', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g19Props = editorHooks.useNumberInputProps('r01g19', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g20Props = editorHooks.useNumberInputProps('r01g20', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g21Props = editorHooks.useNumberInputProps('r01g21', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g22Props = editorHooks.useNumberInputProps('r01g22', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g23Props = editorHooks.useNumberInputProps('r01g23', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g24Props = editorHooks.useNumberInputProps('r01g24', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g25Props = editorHooks.useNumberInputProps('r01g25', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g26Props = editorHooks.useNumberInputProps('r01g26', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g27Props = editorHooks.useNumberInputProps('r01g27', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g28Props = editorHooks.useNumberInputProps('r01g28', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g29Props = editorHooks.useNumberInputProps('r01g29', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g30Props = editorHooks.useNumberInputProps('r01g30', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g31Props = editorHooks.useNumberInputProps('r01g31', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g32Props = editorHooks.useNumberInputProps('r01g32', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g33Props = editorHooks.useNumberInputProps('r01g33', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g34Props = editorHooks.useNumberInputProps('r01g34', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g35Props = editorHooks.useNumberInputProps('r01g35', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g36Props = editorHooks.useNumberInputProps('r01g36', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g37Props = editorHooks.useNumberInputProps('r01g37', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g38Props = editorHooks.useNumberInputProps('r01g38', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g39Props = editorHooks.useNumberInputProps('r01g39', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g40Props = editorHooks.useNumberInputProps('r01g40', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g41Props = editorHooks.useNumberInputProps('r01g41', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g42Props = editorHooks.useNumberInputProps('r01g42', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g43Props = editorHooks.useNumberInputProps('r01g43', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g44Props = editorHooks.useNumberInputProps('r01g44', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g45Props = editorHooks.useNumberInputProps('r01g45', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g46Props = editorHooks.useNumberInputProps('r01g46', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g47Props = editorHooks.useNumberInputProps('r01g47', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g48Props = editorHooks.useNumberInputProps('r01g48', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g49Props = editorHooks.useNumberInputProps('r01g49', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g50Props = editorHooks.useNumberInputProps('r01g50', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g51Props = editorHooks.useNumberInputProps('r01g51', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g52Props = editorHooks.useNumberInputProps('r01g52', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g53Props = editorHooks.useNumberInputProps('r01g53', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g54Props = editorHooks.useNumberInputProps('r01g54', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const r01g55Props = editorHooks.useNumberInputProps('r01g55', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const dataRef = useRef();
  dataRef.current = data;

  const onLoadHandler = useCallback(
    (documents) => {
      actions.onDraft(
        dataRef.current,
        {
          load_leveldown_expenses_for_wages_documents: {
            documents,
          },
        },
      );
    },
    [actions],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.expensesForWages.backendURL}
          id={id}
          repr={data.repr}
        >
          <SendToLogicaButton
            url={meta.doc.expensesForWages.backendURL}
            documnetId={data.id}
            disabled={!data.executed || changed}
          />
        </DocCommandPanel>
            )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid className="p-0">
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col sm={2}>
                <EditorControls.SelectorInput {...monthProps} />
              </Col>
              <Col sm={2}>
                <EditorControls.YearInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...signVariantProps} />
              </Col>
            </Row>
            <ApprovedInfo
              approved={data.approved}
              approvedText={data.invoice}
              type="approved"
              url={`${meta.doc.expensesForWages.backendURL}${data.id}/`}
            />
            <LoadLevelDownButton
              authority={data.authority}
              docDate={data.doc_date}
              year={data.year}
              disabled={!permissions.canChange}
              onLoadHandler={onLoadHandler}
              id={data.id}
            />
          </Container>
          <Container fluid className="p-0">
            <span>
              Сума виплаченої заробітної плати з утриманнями та нарахуваннями на неї
              за період, що передує звітному, протягом звітного місяця*
            </span>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g1Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g2Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g3Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g4Props} />
              </Col>
            </Row>
            <span>Фактично зайняті ставки у звітномумісяці, од. </span>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g5Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g6Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g7Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g8Props} />
              </Col>
            </Row>
            <span>Затверджено кошторисних призначень на звітний місяць </span>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g9Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g10Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g11Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g12Props} />
              </Col>
            </Row>
            <span>
              Нараховано заробітну плату за рахунок освітньої
              субвенції для виплати за звітний місяць
            </span>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g13Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g14Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g15Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g16Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g17Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g18Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g19Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g20Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g21Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g22Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g23Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g24Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g25Props} />
              </Col>
            </Row>
            <span>
              Нараховано заробітну плату за рахунок
              місцевих бюджетів для виплати за звітний місяць
            </span>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g26Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g27Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g28Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g29Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g30Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g31Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g32Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g33Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g34Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g35Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g36Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g37Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g38Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g39Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g40Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g41Props} />
              </Col>
            </Row>
            <span>
              ДОВІДКОВО: із загальної суми нарахованої заробітної плати,
              обсяг коштів, спрямований для оплати (КЕКВ 2110):
            </span>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g42Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g43Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g44Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g45Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g46Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g47Props} />
              </Col>
            </Row>
            <span>
              Виплата нарахованої заробітної плати за звітний місяць
              з урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.:
            </span>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g48Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g49Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g50Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g51Props} />
              </Col>
            </Row>
            <span>
              Виплата нарахованої заробітної плати за звітний місяць з
              урахуванням утримань (КЕКВ 2110 (2111+2112) у т.ч.:
            </span>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g52Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g53Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g54Props} />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorControls.NumberInput {...r01g55Props} />
              </Col>
            </Row>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="rowset">
          {fields && fields.expensesforwagesleveldownrow_set && (
            <Tab eventKey="docs" title={fields.expensesforwagesleveldownrow_set.label}>
              <TabContainer>
                <LevelDownRows2
                  data={data}
                  actions={actions}
                  readOnly={!permissions.canChange}
                />
              </TabContainer>
            </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        readOnly={!permissions.canChange}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>
  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};
export default Editor;
