import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';

import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';
import md from '../../../../../../constants/meta/documents/taxes';

const meta = md.tables.TABL_10;
const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght,
}) => (
  <TableHeaderRow noGutters className="px-4">
    <Col className="px-0 mx-0">
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'solutionNumber'}
          highlighted={highlights.includes('solutionNumber')}
          onToggleHighlght={() => onToggleHighlght('solutionNumber')}
        >
          {meta.columns.solutionNumber.label}
        </TableHeaderCell>
      </Col>

      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'solutionDate'}
          highlighted={highlights.includes('solutionDate')}
          onToggleHighlght={() => onToggleHighlght('solutionDate')}
        >
          {meta.columns.solutionDate.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'forceDate'}
          highlighted={highlights.includes('forceDate')}
          onToggleHighlght={() => onToggleHighlght('forceDate')}
        >
          {meta.columns.forceDate.label}
        </TableHeaderCell>
      </Col>
      <Col>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'dateFrom'}
          highlighted={highlights.includes('dateFrom')}
          onToggleHighlght={() => onToggleHighlght('dateFrom')}
        >
          {meta.columns.dateFrom.label}
        </TableHeaderCell>
      </Col>
    </Col>
    <Col lg={2} className="px-0 mx-0">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'koutuuMs'}
            highlighted={highlights.includes('koutuuMs')}
            onToggleHighlght={() => onToggleHighlght('koutuuMs')}
          >
            {meta.columns.koutuuMs.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'nameMs'}
            highlighted={highlights.includes('nameMs')}
            onToggleHighlght={() => onToggleHighlght('nameMs')}
          >
            {meta.columns.nameMs.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'koatuuAto'}
            highlighted={highlights.includes('koatuuAto')}
            onToggleHighlght={() => onToggleHighlght('koatuuAto')}
          >
            {meta.columns.koatuuAto.label}
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'nameAto'}
            highlighted={highlights.includes('nameAto')}
            onToggleHighlght={() => onToggleHighlght('nameAto')}
          >
            {meta.columns.nameAto.label}
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col className="px-0 mx-0">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add2Tax'}
            highlighted={highlights.includes('add2Tax')}
            onToggleHighlght={() => onToggleHighlght('add2Tax')}
          >
            Додаток 2
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add2TaxAdd'}
            highlighted={highlights.includes('add2TaxAdd')}
            onToggleHighlght={() => onToggleHighlght('add2TaxAdd')}
          >
            Додаток 2 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add2Privilege'}
            highlighted={highlights.includes('add2Privilege')}
            onToggleHighlght={() => onToggleHighlght('add2Privilege')}
          >
            Додаток 3
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add2PrivilegeAdd'}
            highlighted={highlights.includes('add2PrivilegeAdd')}
            onToggleHighlght={() => onToggleHighlght('add2PrivilegeAdd')}
          >
            Додаток 3 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col className="px-0 mx-0">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add3Tax'}
            highlighted={highlights.includes('add3Tax')}
            onToggleHighlght={() => onToggleHighlght('add3Tax')}
          >
            Додаток 4
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add3TaxAdd'}
            highlighted={highlights.includes('add3TaxAdd')}
            onToggleHighlght={() => onToggleHighlght('add3TaxAdd')}
          >
            Додаток 4 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add3Privilege'}
            highlighted={highlights.includes('add3Privilege')}
            onToggleHighlght={() => onToggleHighlght('add3Privilege')}
          >
            Додаток 5
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add3PrivilegeAdd'}
            highlighted={highlights.includes('add3PrivilegeAdd')}
            onToggleHighlght={() => onToggleHighlght('add3PrivilegeAdd')}
          >
            Додаток 5 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col className="px-0 mx-0">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add4Tax'}
            highlighted={highlights.includes('add4Tax')}
            onToggleHighlght={() => onToggleHighlght('add4Tax')}
          >
            Додаток 6
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add4TaxAdd'}
            highlighted={highlights.includes('add4TaxAdd')}
            onToggleHighlght={() => onToggleHighlght('add4TaxAdd')}
          >
            Додаток 6 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add5Tax'}
            highlighted={highlights.includes('add5Tax')}
            onToggleHighlght={() => onToggleHighlght('add5Tax')}
          >
            Додаток 7
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add5TaxAdd'}
            highlighted={highlights.includes('add5TaxAdd')}
            onToggleHighlght={() => onToggleHighlght('add5TaxAdd')}
          >
            Додаток 7 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col className="px-0 mx-0">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add6Tax'}
            highlighted={highlights.includes('add6Tax')}
            onToggleHighlght={() => onToggleHighlght('add6Tax')}
          >
            Додаток 8
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add6TaxAdd'}
            highlighted={highlights.includes('add6TaxAdd')}
            onToggleHighlght={() => onToggleHighlght('add6TaxAdd')}
          >
            Додаток 8 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add7Tax'}
            highlighted={highlights.includes('add7Tax')}
            onToggleHighlght={() => onToggleHighlght('add7Tax')}
          >
            Додаток 9
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add7TaxAdd'}
            highlighted={highlights.includes('add7TaxAdd')}
            onToggleHighlght={() => onToggleHighlght('add7TaxAdd')}
          >
            Додаток 9 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col className="px-0 mx-0">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add8Tax'}
            highlighted={highlights.includes('add8Tax')}
            onToggleHighlght={() => onToggleHighlght('add8Tax')}
          >
            Додаток 10
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add8TaxAdd'}
            highlighted={highlights.includes('add8TaxAdd')}
            onToggleHighlght={() => onToggleHighlght('add8TaxAdd')}
          >
            Додаток 10 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
    <Col className="px-0 mx-0">
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add11Rent'}
            highlighted={highlights.includes('add11Rent')}
            onToggleHighlght={() => onToggleHighlght('add11Rent')}
          >
            Додаток 11
          </TableHeaderCell>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'add11RentAdd'}
            highlighted={highlights.includes('add11RentAdd')}
            onToggleHighlght={() => onToggleHighlght('add11RentAdd')}
          >
            Додаток 11 (додатковий номер)
          </TableHeaderCell>
        </Col>
      </Row>
    </Col>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
};

TPHeader.defaultProps = {
  activeCol: '',
};

export default memo(TPHeader);
