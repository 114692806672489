const frontendURL = '/doc/expensesOnHealth/';
const backendURL = '/api/merezha/healthcareexpensesheader/';
const name = 'Документ про видатки закладів охорони здоров\'я';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
