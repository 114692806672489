/* eslint-disable react/no-array-index-key */
import React, {
  memo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col, InputGroup,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const PROCENTS = {
  prWork: 'sumWork',
  prCom: 'sumCom',
  prMed: 'sumMed',
  prProd: 'sumProd',
  prP: 'sumP',
  prW: 'sumW',
  prOther: 'sumOther',
};
function FreeRem3Row({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const budgetProps = tableHooks.useItemInputProps('budget', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumProps = tableHooks.useNumberInputProps('sum', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumTProps = tableHooks.useNumberInputProps('sumT', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumWorkProps = tableHooks.useNumberInputProps('sumWork', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumComProps = tableHooks.useNumberInputProps('sumCom', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumMedProps = tableHooks.useNumberInputProps('sumMed', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumProdProps = tableHooks.useNumberInputProps('sumProd', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumPProps = tableHooks.useNumberInputProps('sumP', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumWProps = tableHooks.useNumberInputProps('sumW', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumOtherProps = tableHooks.useNumberInputProps('sumOther', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const calcSumRasp = useCallback(
    (r) => {
      const sumRasp = Object.values(PROCENTS).reduce((R, cName) => R + (r[cName] || 0), 0);
      return Object.keys(PROCENTS).reduce((R, prName) => {
        const pr = sumRasp ? Math.round(((r[PROCENTS[prName]] || 0) * 10000) / sumRasp) / 100 : 0;
        return {
          o: { ...R.o, [prName]: pr },
          r: R.r - r[PROCENTS[prName]],
          p: R.p - pr,
        };
      }, { o: { ...r, sumRasp }, r: sumRasp, p: 100 }).o;
    },
    [],
  );

  const onChangeSumPart = useCallback(
    (e, v, attrName) => {
      const newRow = { ...row, [attrName]: v };
      onChange(e, rowIndex, calcSumRasp(newRow));
    },
    [calcSumRasp, onChange, row, rowIndex],
  );
  return (
    <>
      <Col sm={2} className="border-right">
        <TabelRowCell column="budget" highlighted={highlights.includes('budget')}>
          <TableControls.ItemPicker {...budgetProps} />
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sum" highlighted={highlights.includes('sum')}>
          <TableControls.NumberInput {...sumProps} />
        </TabelRowCell>
        <TabelRowCell column="sumT" highlighted={highlights.includes('sumT')}>
          <TableControls.NumberInput {...sumTProps} />
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sumRasp" highlighted={highlights.includes('sumRasp')} className="justify-content-end pe-2 me-1 pb-1 pt-2 font-italic text-muted border rounded fs--1">
          {(row.sumRasp || 0).toLocaleString('uk', moneysStingOptions)}
        </TabelRowCell>
        <TabelRowCell column="sumWork" highlighted={highlights.includes('sumWork')}>
          <TableControls.NumberInput
            {...sumWorkProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumWork')}
            append={(
              <InputGroup.Text>
                {(row.prWork || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
             )}
          />
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sumCom" highlighted={highlights.includes('sumCom')}>
          <TableControls.NumberInput
            {...sumComProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumCom')}
            append={(
              <InputGroup.Text>
                {(row.prCom || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
             )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumMed" highlighted={highlights.includes('sumMed')}>
          <TableControls.NumberInput
            {...sumMedProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumMed')}
            append={(
              <InputGroup.Text>
                {(row.prMed || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
             )}
          />
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sumProd" highlighted={highlights.includes('sumProd')}>
          <TableControls.NumberInput
            {...sumProdProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumProd')}
            append={(
              <InputGroup.Text>
                {(row.prProd || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
             )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumP" highlighted={highlights.includes('sumP')}>
          <TableControls.NumberInput
            {...sumPProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumP')}
            append={(
              <InputGroup.Text>
                {(row.prP || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
             )}
          />
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sumW" highlighted={highlights.includes('sumW')}>
          <TableControls.NumberInput
            {...sumWProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumW')}
            append={(
              <InputGroup.Text>
                {(row.prW || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
             )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumOther" highlighted={highlights.includes('sumOther')}>
          <TableControls.NumberInput
            {...sumOtherProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumOther')}
            append={(
              <InputGroup.Text>
                {(row.prOther || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
             )}
          />
        </TabelRowCell>
      </Col>
    </>
  );
}

FreeRem3Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

FreeRem3Row.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(FreeRem3Row);
