import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button, Modal, Nav,
} from 'react-bootstrap';
import {
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorControls } from '../../../../../components/bootstrap_components/editorControls';
import PeriodSelection from '../../../../../constants/meta/enums/periodSelection';
import { DOC, M, TABLE } from './consts';
import DocItem from './docItem';

function DocSelectionModal({
  onOk, year, readOnly, onSR,
}) {
  // console.log(docs);
  const ref = useRef();
  const [opened, setOpened] = useState(false);
  const [docs, setDocs] = useState([]);
  const [period, setPeriod] = useState({
    StartDate: 1,
    EndDate: 1,
    Variant: PeriodSelection.Custom.name,
  });
  useEffect(() => {
    if (opened) {
      const d = new Date(year, 0, 1);
      d.setMinutes(-d.getTimezoneOffset());
      const sd = d.valueOf();
      d.setMonth(11, 31);
      const se = d.valueOf();
      setPeriod({
        StartDate: sd,
        EndDate: se,
        Variant: PeriodSelection.Custom.name,
      });
    }
  }, [opened, year]);

  const okHandler = useCallback(
    () => {
      setOpened(false);
      onOk({
        [TABLE]: docs
          .filter((d) => d[M]),
      });
    },
    [docs, onOk],
  );

  const onToggleDoc = useCallback(
    (e, docId, v) => setDocs((o) => o.map((d) => (d[DOC].id === docId ? ({ ...d, [M]: v }) : d))),
    [],
  );

  const onShowDocs = useCallback(
    async () => {
      const r = await onSR('GET_LOWLEVEL_DOCS', { period }, false);
      setDocs(r[TABLE]);
    },
    [onSR, period],
  );

  const docCount = docs.filter((d) => d[M]).length;

  return (
    <>
      <Button onClick={() => setOpened(true)} disabled={readOnly} ref={ref} size='sm'>
        <FontAwesomeIcon icon={faFileImport} className="me-2" />
        Заповнити з даних розпорядників нижчого рівня
      </Button>
      <Modal
        show={opened}
        onHide={() => setOpened(false)}
        size="lg"
        dialogClassName="mw-100"
        scrollable
        container={ref.current ? ref.current.parentNode : document.body}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Оберіть документи для завантаження
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditorControls.PeriodInput value={period} onChange={(e, v) => setPeriod(v)} label="Відібрати документи за період" />
          <Nav className="gap-1">
            <Button onClick={onShowDocs} variant="primary">
              Отримати документи
            </Button>
            <Button onClick={okHandler} variant="success" disabled={!docCount}>
              Завантажити
              <Badge variant="danger">{docCount}</Badge>
            </Button>
          </Nav>
          <div className="mt-2">
            {docs.map((doc) => (
              <DocItem doc={doc} onToggle={onToggleDoc} />
            ))}
          </div>

        </Modal.Body>

      </Modal>
    </>
  );
}

DocSelectionModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onSR: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
};

DocSelectionModal.defaultProps = {
  readOnly: false,
};

export default DocSelectionModal;
