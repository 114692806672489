import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../api/req';
import checkTask from '../../../api/checktask';
import md from '../../../meta/doc/transferToLogica';
import AppContext from '../../../providers/CiatAppContext';
import { CiatAppContext } from '../../../providers';

const BACKENDURL = '/api/authorityplans/send_changes_to_logica/';

const rozpusType = {
  1: 'R4',
  2: 'K',
  3: 'M',
};

const useExportChangesToLogica = () => {
  const navigate = useNavigate();
  const { auth } = useContext(AppContext);
  const { currentUser } = useContext(CiatAppContext);
  const isSuperuser = currentUser.is_superuser;

  const [authority, setAuthority] = useState(null); // authority
  const [beginDate, setBeginDate] = useState(); // begin_date
  const [endDate, setEndDate] = useState(); // end_date
  const [signVariant, setSignVariant] = useState();

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [type, setType] = useState(1); // variant
  const [sufNumber, setSufNumber] = useState('');

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const getInitial = useCallback(() => {
    const loader = async () => {
      const r = await api.get(BACKENDURL, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setBeginDate(rData.begin_date);
        setEndDate(rData.end_date);
        setType(parseInt(Object.entries(rozpusType).find(
          ([key, value]) => key && value[0] === rData.rozpys_type[0],
        )?.[0], 10));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  useEffect(
    () => {
      getInitial();
      setAuthority(currentUser.authority);
    },
    [currentUser.authority, getInitial],
  );

  const createTransferDoc = useCallback(
    () => {
      const creator = async () => {
        const params = {
          authority,
          begin_date: beginDate,
          end_date: endDate,
          sign_variant: signVariant,
          rozpys_type: `${rozpusType[type]}${sufNumber}`,
        };
        const r = await api.post(BACKENDURL, auth, params);
        if (!r.ok) {
          if (r.status === 400) {
            const d = await r.json();
            const message = Object.keys(d).reduce((R, k) => d[k].join(', '), '');
            throw new Error(message);
          }
          throw new Error(`${r.status} ${r.statusText}`);
        }
        return r.json();
      };
      onLoadStart();
      creator()
        .then(({ task_id: taskId }) => checkTask(taskId, auth))
      // eslint-disable-next-line no-console
        .then((d) => {
          if (d.result.errors) {
            throw new Error(d.result.errors.join(', '));
          }
          navigate(`${md.frontendURL}${d.result.container.id}/`);
        })
        .catch((e) => setErr(e.message))
        .finally(() => onLoadEnd());
    },
    [onLoadStart, authority, beginDate, endDate,
      signVariant, type, sufNumber, auth, navigate, onLoadEnd],
  );

  return {
    createTransferDoc,
    loading,
    err,
    setErr,
    type,
    setType,
    sufNumber,
    setSufNumber,
    signVariant,
    setSignVariant,
    isSuperuser,
    authority,
    setAuthority,
    beginDate,
    setBeginDate,
    endDate,
    setEndDate,

  };
};

export default useExportChangesToLogica;
