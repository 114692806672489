const frontendURL = '/doc/freeRem4Add2024/';
const backendURL = '/api/merezha/freerem4/';
const name = 'Інформація щодо стану розподілу вільних залишків коштів місцевих бюджетів (Додаток 4)';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
