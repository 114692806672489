import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Col, InputGroup,
} from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import { tableHooks, TableControls } from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';
import { moneysStingOptions } from '../../../../../../constants/moneys';

const PROCENTS = {
  prWork: 'sumWork',
  prCom: 'sumCom',
  prMed: 'sumMed',
  prProd: 'sumProd',
  prP: 'sumP',
  prW: 'sumW',
  prOther: 'sumOther',
  prSoc: 'sumSoc',
  prReabil: 'sumReabil',
  prTer: 'sumTer',
  prMob: 'sumMob',
  prSec: 'sumSec',
  prBuild: 'sumBuild',
  prFort: 'sumFort',
  prDebt: 'sumDebt',
  prGarant: 'sumGarant',
  prAdd: 'sumAdd',
  prZF: 'sumZF',
};

function FreeRem4Row({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
}) {
  const budgetProps = tableHooks.useItemInputProps('budget', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumProps = tableHooks.useNumberInputProps('sum', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumTProps = tableHooks.useNumberInputProps('sumT', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumWorkProps = tableHooks.useNumberInputProps('sumWork', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumComProps = tableHooks.useNumberInputProps('sumCom', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumMedProps = tableHooks.useNumberInputProps('sumMed', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumProdProps = tableHooks.useNumberInputProps('sumProd', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumOtherProps = tableHooks.useNumberInputProps('sumOther', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumRaspSubvProps = tableHooks.useNumberInputProps('sumRaspSubv', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumSocProps = tableHooks.useNumberInputProps('sumSoc', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumReabilProps = tableHooks.useNumberInputProps('sumReabil', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumTerProps = tableHooks.useNumberInputProps('sumTer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumMobProps = tableHooks.useNumberInputProps('sumMob', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumSecProps = tableHooks.useNumberInputProps('sumSec', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumBuildProps = tableHooks.useNumberInputProps('sumBuild', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumFortProps = tableHooks.useNumberInputProps('sumFort', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumDebtProps = tableHooks.useNumberInputProps('sumDebt', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumGarantProps = tableHooks.useNumberInputProps('sumGarant', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumReturnProps = tableHooks.useNumberInputProps('sumReturn', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumAddProps = tableHooks.useNumberInputProps('sumAdd', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const sumZFProps = tableHooks.useNumberInputProps('sumZF', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const calcSumRasp = useCallback(
    (r) => {
      const sumRasp = Object.values(PROCENTS).reduce((R, cName) => R + (r[cName] || 0), 0);
      const sumUnused = Math.round(
        ((r.sum || 0) - (r.sumRasp || 0) - (r.sumRaspSubv || 0) - (r.sumReturn || 0)) * 100,
      ) / 100;
      const prRaspAll = Math.round(r.sum
        ? ((sumRasp - (r.sumRaspSubv || 0)) * 10000) / r.sum
        : 0) / 100;
      const t = (r.sum || 0) - (r.sumT || 0);
      const prRasp = Math.round(t ? (sumRasp * 10000) / t : 0) / 100;

      return Object.keys(PROCENTS).reduce((R, prName) => {
        const pr = sumRasp
          ? Math.round(
            ((r[PROCENTS[prName]] || 0) * 10000)
          / ((sumRasp || 0) - (r.sumAdd || 0) - (r.sumZF || 0)),
          ) / 100
          : 0;

        return {
          o: { ...R.o, [prName]: pr },
          r: R.r - r[PROCENTS[prName]],
          p: R.p - pr,
        };
      }, {
        o: {
          ...r, sumRasp, sumUnused, prRaspAll, prRasp,
        },
        r: sumRasp,
        p: 100,
      }).o;
    },
    [],
  );

  const onChangeSumPart = useCallback(
    (e, v, attrName) => {
      const newRow = { ...row, [attrName]: v };
      onChange(e, rowIndex, calcSumRasp(newRow));
    },
    [calcSumRasp, onChange, row, rowIndex],
  );
  return (
    <>
      <Col sm={2} className="border-right">
        <TabelRowCell column="budget" highlighted={highlights.includes('budget')}>
          <TableControls.ItemPicker {...budgetProps} />
        </TabelRowCell>
        <TabelRowCell column="sum" highlighted={highlights.includes('sum')}>
          <TableControls.NumberInput {...sumProps} onChange={(e, v) => onChangeSumPart(e, v, 'sum')} />
        </TabelRowCell>
        <TabelRowCell column="sumT" highlighted={highlights.includes('sumT')}>
          <TableControls.NumberInput {...sumTProps} onChange={(e, v) => onChangeSumPart(e, v, 'sumT')} />
        </TabelRowCell>
        <TabelRowCell
          column="sumRasp"
          highlighted={highlights.includes('sumRasp')}
          className="justify-content-end pe-2 me-1 pb-1 pt-2 font-italic text-muted border rounded fs--1"
        >
          {(row.sumRasp || 0).toLocaleString('uk', moneysStingOptions)}
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sumWork" highlighted={highlights.includes('sumWork')}>
          <TableControls.NumberInput
            {...sumWorkProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumWork')}
            append={(
              <InputGroup.Text>
                {(row.prWork || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumCom" highlighted={highlights.includes('sumCom')}>
          <TableControls.NumberInput
            {...sumComProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumCom')}
            append={(
              <InputGroup.Text>
                {(row.prCom || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumMed" highlighted={highlights.includes('sumMed')}>
          <TableControls.NumberInput
            {...sumMedProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumMed')}
            append={(
              <InputGroup.Text>
                {(row.prMed || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumProd" highlighted={highlights.includes('sumProd')}>
          <TableControls.NumberInput
            {...sumProdProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumProd')}
            append={(
              <InputGroup.Text>
                {(row.prProd || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sumSoc" highlighted={highlights.includes('sumSoc')}>
          <TableControls.NumberInput
            {...sumSocProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumSoc')}
            append={(
              <InputGroup.Text>
                {(row.prSoc || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumReabil" highlighted={highlights.includes('sumReabil')}>
          <TableControls.NumberInput
            {...sumReabilProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumReabil')}
            append={(
              <InputGroup.Text>
                {(row.prReabil || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumTer" highlighted={highlights.includes('sumTer')}>
          <TableControls.NumberInput
            {...sumTerProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumTer')}
            append={(
              <InputGroup.Text>
                {(row.prTer || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumMob" highlighted={highlights.includes('sumMob')}>
          <TableControls.NumberInput
            {...sumMobProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumMob')}
            append={(
              <InputGroup.Text>
                {(row.prMob || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sumSec" highlighted={highlights.includes('sumSec')}>
          <TableControls.NumberInput
            {...sumSecProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumSec')}
            append={(
              <InputGroup.Text>
                {(row.prSec || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumBuild" highlighted={highlights.includes('sumBuild')}>
          <TableControls.NumberInput
            {...sumBuildProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumBuild')}
            append={(
              <InputGroup.Text>
                {(row.prBuild || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumFort" highlighted={highlights.includes('sumFort')}>
          <TableControls.NumberInput
            {...sumFortProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumFort')}
            append={(
              <InputGroup.Text>
                {(row.prFort || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumDebt" highlighted={highlights.includes('sumDebt')}>
          <TableControls.NumberInput
            {...sumDebtProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumDebt')}
            append={(
              <InputGroup.Text>
                {(row.prDebt || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell column="sumGarant" highlighted={highlights.includes('sumGarant')}>
          <TableControls.NumberInput
            {...sumGarantProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumGarant')}
            append={(
              <InputGroup.Text>
                {(row.prGarant || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumOther" highlighted={highlights.includes('sumOther')}>
          <TableControls.NumberInput
            {...sumOtherProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumOther')}
            append={(
              <InputGroup.Text>
                {(row.prOther || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumRaspSubv" highlighted={highlights.includes('sumRaspSubv')}>
          <TableControls.NumberInput
            {...sumRaspSubvProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumRaspSubv')}
          />
        </TabelRowCell>
        <TabelRowCell column="sumReturn" highlighted={highlights.includes('sumReturn')}>
          <TableControls.NumberInput
            {...sumReturnProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumReturn')}
          />
        </TabelRowCell>
        <TabelRowCell
          column="sumUnused"
          highlighted={highlights.includes('sumUnused')}
          className="justify-content-end pe-2 me-1 pb-1 pt-2 font-italic text-muted border rounded fs--1"
        >
          {(row.sumUnused || 0).toLocaleString('uk', moneysStingOptions)}
        </TabelRowCell>
      </Col>
      <Col sm={2} className="border-right">
        <TabelRowCell
          column="prRaspAll"
          highlighted={highlights.includes('prRaspAll')}
          className="justify-content-end pe-2 me-1 pb-1 pt-2 font-italic text-muted border rounded fs--1"
        >
          {(row.prRaspAll || 0).toLocaleString('uk', moneysStingOptions)}
        </TabelRowCell>
        <TabelRowCell
          column="prRasp"
          highlighted={highlights.includes('prRasp')}
          className="justify-content-end pe-2 me-1 pb-1 pt-2 font-italic text-muted border rounded fs--1"
        >
          {(row.prRasp || 0).toLocaleString('uk', moneysStingOptions)}
        </TabelRowCell>
        <TabelRowCell column="sumAdd" highlighted={highlights.includes('sumAdd')}>
          <TableControls.NumberInput
            {...sumAddProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumAdd')}
            append={(
              <InputGroup.Text>
                {(row.prAdd || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
        <TabelRowCell column="sumZF" highlighted={highlights.includes('sumZF')}>
          <TableControls.NumberInput
            {...sumZFProps}
            onChange={(e, v) => onChangeSumPart(e, v, 'sumZF')}
            append={(
              <InputGroup.Text>
                {(row.prZF || 0).toLocaleString('uk', moneysStingOptions)}
                %
              </InputGroup.Text>
                        )}
          />
        </TabelRowCell>
      </Col>
    </>
  );
}

FreeRem4Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  authority: foreignPropType,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
};

FreeRem4Row.defaultProps = {
  errors: {},
  authority: null,
  readOnly: false,
  readOnlyFields: [],
};

export default memo(FreeRem4Row);
