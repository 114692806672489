import React, {
  useCallback, useState, useMemo, useContext,
} from 'react';
import {
  Button, Navbar, DropdownButton, Dropdown, Alert, Nav, Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan, faFileDownload, faCheckDouble, faHistory,
} from '@fortawesome/free-solid-svg-icons';
import Comment from './comment';
import Tables from './Table';
import ResultVerifyPage from './resultVerifyPage';
import forms from './forms';
import api from '../../../api/req';
import LogcaLoginModal from '../LogikaLogin/LogcaLoginModal';
import { CiatAppContext, LogicaContext, WinManagerContext } from '../../../providers';
import meta from '../../../meta';
import PageHeader from '../../../components/blanks/common/PageHeader';
import FalconCloseButton from '../../../components/blanks/common/CloseButton';
import { Loader } from '../../../components/bootStrap';

const StyledButton = styled(Button)`
margin-right: 2px;
margin-top: 2px;
display: flex;
align-items: center`;

const StyledDropdownButton = styled(DropdownButton)`
display: flex;
margin-right: 5px
`;

const Text = styled.p`
text-align: center;
margin: 0;
margin-left: 2px`;

function Editor() {
  const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
  const [year, setYear] = useState(() => new Date().getFullYear());
  const [list, setList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [selectedBs, setSelectedBs] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [commentPage, setCommentPage] = useState(false);
  const [filter, setFilter] = useState({});
  const [order, setOrder] = useState({});
  const [resultVerifyPage, setResultVerifyPage] = useState(false);
  // Відкриття модалки
  const [resultVerify, setResultVerify] = useState(null);
  // Весь список спроб верифікації по 1-му документу
  const [resultVerifyList, setResultVerifyList] = useState(null);
  // Список файлів наданих користувачу
  const [resJson, setResJson] = useState(null);
  const [err, setErr] = useState(null);
  const [logikaErrors, setLogikaErrors] = useState([]);
  // const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);
  // Останній результат верифікації кожного з файлів
  const [verifyResults, setVerifyResults] = useState(null);

  const { ticket } = useContext(LogicaContext);
  const { currentUser } = useContext(CiatAppContext);
  const role = currentUser.authority.disposer_kind;
  const admin = currentUser.is_superuser;

  const findDocInOurBase = async (id) => {
    const res = await api.post$('logica/find_doc_in_our_base', { id });

    if (res) {
      const data = await res.json();
      return data.docInBase;
    }
    return false;
  };

  const getValidationResults = useCallback(async (array, address) => {
    const arr = array.map((i) => i.id.toString());
    const link = `https://logica.minfin.gov.ua/ws/api${address}`;
    setIsLoading(true);
    const res = await fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: arr }),
    });
    const results = await res.json();

    const loadResults = async (id, result) => {
      const lastVersion = Object.keys(result).map((key) => ({
        key,
        v: new Date(key),
      })).sort((a, b) => a.v.valueOf() - b.v.valueOf())
        .reduce((R, r) => r, null);
      if (!lastVersion) {
        throw new Error(`Нет версий документа с id=${id}`);
      }
      const url = result[lastVersion.key].receipt;
      if (url) {
        const ress = await fetch(url);
        if (ress.ok) {
          const json = await ress.json();
          return { [id]: { success: json.success } };
        }
        throw new Error(`${ress.status} ${ress.statusText}`);
      }
      throw new Error('Нема урлов');
    };
    const resultStatus = await Promise
      .allSettled(Object.keys(results).map((id) => loadResults(id, results[id])));
    const successes = resultStatus
      .filter((r) => r.status === 'fulfilled')
      .reduce((R, r) => ({ ...R, ...r.value }), {});

    setVerifyResults((prev) => {
      const result = prev ? { ...prev, ...successes } : { ...successes };
      return result;
    });
  }, []);

  const getList = useCallback(async (e) => {
    const filterType = e.target.value;
    setSelectedIds([]);
    setIsLoading(true);

    try {
      const res = await fetch(
        `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORTS?filter=${filterType}&period=${year}`,
        { headers: { Ticket: ticket, accept: 'application/json' } },
      );
      const bsRes = await fetch(
        `https://logica.minfin.gov.ua/ws/api/_REPORTS.GETBS?filter=${filterType}&period=${year}`,
        { headers: { Ticket: ticket, accept: 'application/json' } },
      );
      if (res.status === 401 || bsRes.status === 401) {
        setResponse(null);
        setIsLoading(false);
        setError('Необхідно авторизуватись!');
        return;
      }
      setError(null);
      setLogikaErrors([]);
      const r = await res.json();
      const bs = await bsRes.json();
      if (bs.result?.length > 0 && r.result?.length > 0) {
        setList([...bs.result,
          ...r.result.filter((resArr) => !bs.result.map((bsArr) => bsArr.id).includes(resArr.id))]);
        getValidationResults(r.result, '/_REPORTS.REPORT.STATUS.POST');
        getValidationResults(bs.result, '/_REPORTS.GETBSSTATUS');
      } else if (bs.result?.length > 0) {
        setList(bs.result);
        getValidationResults(bs.result, '/_REPORTS.GETBSSTATUS');
      } else {
        setList(r.result);
        getValidationResults(r.result, '/_REPORTS.REPORT.STATUS.POST');
      }
      setResponse(null);
    } catch (er) {
      setIsLoading(false);
      setResponse(null);
      setError(er.message);
    }
    setIsLoading(false);
  }, [getValidationResults, ticket, year]);

  const getFile = useCallback(async () => {
    setIsLoading(true);
    try {
      const link = selectedBs.length > 0
        ? `https://logica.minfin.gov.ua/ws/api/_REPORTS.GETBSDATA?id=${selectedBs[0]}`
        : `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT?id=${selectedIds[0]}`;
      const res = await fetch(
        link,
        { headers: { Ticket: ticket, accept: 'application/json' } },
      );
      const r = await res.json();
      window.open(`${r.pdflink}`, '_blank');
      setIsLoading(false);
      setResponse(null);
    } catch (e) {
      setIsLoading(false);
      setResponse(null);
      setError(e.message);
    }
  }, [selectedBs, selectedIds, ticket]);

  const onClearErr = useCallback(
    () => {
      setError(null);
      setLogikaErrors([]);
      setResponse(null);
    },
    [],
  );

  const handleApproved = useCallback(async (approved, comment) => {
    const appr = async (id, approv, com) => {
      let link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.APPROVE?id=${id}&approved=${approv}&comment=${com}`;
      if (approv) {
        link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.APPROVE?id=${id}&approved=${approv}`;
      }
      const res = await fetch(link, { headers: { Ticket: ticket, accept: 'application/json' } });
      if (res.ok) {
        const d = await res.json();
        return d;
      }
      throw new Error(`Помилка встановлення / знаття позначки на видалення ${id} (${res.status} ${res.statusText})`);
    };
    setIsLoading(true);
    setErr(null);
    setResponse(null);
    setLogikaErrors([]);
    Promise.allSettled(selectedIds.map((id) => appr(id, approved, comment)))
      .then((results) => {
        const errors = results
          .filter((r) => r.status === 'rejected')
          .map((r) => r.reason);
        if (errors.length) setErr(errors.join(', '));

        const le = results
          .filter((r) => r.status === 'fulfilled' && !r.value.success);
        le.map((el) => setLogikaErrors((prev) => [...prev, el.value.message]));

        // const ld = results
        //   .filter((r) => r.status === 'fulfilled' && r.value.success);
        if (errors.length === 0 && le.length === 0) {
          setResponse('Зміни пройшли успішно!');
        }
      })
      .catch((e) => setErr(e.message))
      .finally(() => setIsLoading(false));
  }, [selectedIds, ticket]);

  const handlePublish = useCallback(async (publish, comment) => {
    const publ = async (id, pub, com) => {
      let link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.PUBLISH?id=${id}&publish=${publish}&comment=${com}`;
      if (pub) {
        link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.PUBLISH?id=${id}&publish=${pub}`;
      }
      setIsLoading(true);
      const res = await fetch(link, { headers: { Ticket: ticket, accept: 'application/json' } });
      if (res.ok) {
        const d = await res.json();
        return d;
      }
      throw new Error(`Помилка встановлення / знаття позначки на видалення ${id} (${res.status} ${res.statusText})`);
    };
    setIsLoading(true);
    setErr(null);
    setResponse(null);
    setLogikaErrors([]);
    Promise.allSettled(selectedIds.map((id) => publ(id, publish, comment)))
      .then((results) => {
        const errors = results
          .filter((r) => r.status === 'rejected')
          .map((r) => r.reason);
        if (errors.length) setErr(errors.join(', '));

        const le = results
          .filter((r) => r.status === 'fulfilled' && !r.value.success);
        le.map((el) => setLogikaErrors((prev) => [...prev, el.value.message]));

        // const ld = results
        //   .filter((r) => r.status === 'fulfilled' && r.value.success);
        if (errors.length === 0 && le.length === 0) {
          setResponse('Зміни пройшли успішно!');
        }
      })
      .catch((e) => setErr(e.message))
      .finally(() => setIsLoading(false));
  }, [selectedIds, ticket]);

  const handleCancel = useCallback(async () => {
    try {
      const link = selectedBs.length > 0
        ? `https://logica.minfin.gov.ua/ws/api/_REPORTS.CANCELBS?id=${selectedBs[0]}`
        : `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.CANCEL?id=${selectedIds[0]}`;
      setIsLoading(true);
      const res = await fetch(link, { headers: { Ticket: ticket, accept: 'application/json' } });
      if (res.status === 401) {
        setResponse(null);
        setIsLoading(false);
        setError('Необхідно авторизуватись!');
        return;
      }
      setError(null);
      const r = await res.json();
      setResponse(r.success ? 'Звіт скасовано успішно!' : r.message);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setResponse(null);
      setError(e.message);
    }
  }, [selectedBs, selectedIds, ticket]);

  const getStatusInfo = useCallback(async () => {
    try {
      setResultVerifyList(null);
      const link = selectedBs.length > 0
        ? `https://logica.minfin.gov.ua/ws/api/_REPORTS.GETBSSTATUS?ids=['${selectedIds[0]}']`
        : `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.STATUS?ids=['${selectedIds[0]}']`;
      setIsLoading(true);
      const res = await fetch(link);
      const r = await res.json();
      if (Object.entries(r).length === 0) {
        setResponse(null);
        setIsLoading(false);
        return setError('Для перевірки результату веріфікації перейдіть до розділу "Тип звіту.(табл)", де "Тип звіту.(табл)" - назва відповідного розділу.');
      }
      setResultVerify(Object.entries(Object.values(r)[0]));
      setResultVerifyPage(true);
      setIsLoading(false);
    } catch (e) {
      setResponse(null);
      setError(e.message);
    }
    return setIsLoading(false);
  }, [selectedBs, selectedIds]);

  const getHistory = useCallback(async () => {
    try {
      setResultVerifyList(null);
      const link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.HISTORY?id=${selectedIds[0]}`;
      setIsLoading(true);
      const res = await fetch(link, { headers: { Ticket: ticket, accept: 'application/json' } });
      const r = await res.json();
      window.open(`${r.receipt}`, '_blank');
      setIsLoading(false);
      setResponse(null);
    } catch (e) {
      setIsLoading(false);
      setResponse(null);
      setError(e.message);
    }
  }, [selectedIds, ticket]);

  // eslint-disable-next-line no-unused-vars
  const signDocByFinDep = useCallback(async (publish) => {
    const signFile = async (id) => {
      const docInBase = await findDocInOurBase(id);
      if (docInBase) {
        let res = { ok: true };
        if (publish) {
          const link = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.APPROVE?id=${id}&approved=true`;

          res = await fetch(link, { headers: { Ticket: ticket, accept: 'application/json' } });
        }
        if (res.ok) {
          const link2 = `https://logica.minfin.gov.ua/ws/api/_REPORTS.REPORT.SIGNED?id=${id}&publish=${publish}`;
          setIsLoading(true);
          const res2 = await fetch(link2, { headers: { Ticket: ticket, accept: 'application/json' } });
          if (res2.ok) {
            const d2 = await res2.json();
            return d2;
          }
          throw new Error(`Помилка підпису документа ${id} (${res.status} ${res.statusText})`);
        }
        throw new Error(`Помилка при затверджені ${id} (${res.status} ${res.statusText})`);
      } else {
        throw new Error(`Документ з id ${id} не знайдено в нашій базі`);
      }
    };
    setIsLoading(true);
    setErr(null);
    setResponse(null);
    setLogikaErrors([]);
    Promise.allSettled(selectedIds.map((id) => signFile(id)))
      .then((results) => {
        const errors = results
          .filter((r) => r.status === 'rejected')
          .map((r) => r.reason);
        if (errors.length) setErr(errors.join(', '));

        const le = results
          .filter((r) => r.status === 'fulfilled' && !r.value.success);
        le.map((el) => setLogikaErrors((prev) => [...prev, el.value.message]));
        if (errors.length === 0 && le.length === 0) {
          setResponse('Зміни пройшли успішно!');
        }
      })
      .catch((e) => setErr(e.message))
      .finally(() => setIsLoading(false));
  }, [selectedIds, ticket]);

  const getResult = useCallback(async (url) => {
    try {
      if (url) {
        const res = await fetch(url);

        if (res.ok) {
          const json = await res.json();
          setResJson(json);
        } else {
          setError(`Помилка HTTP: ${res.status}`);
        }
      } else setResJson({ success: false, messages: ['Валідація не була пройдена'] });
    } catch (e) {
      setIsLoading(false);
      setResponse(null);
      setError(e.message);
    }
  }, []);

  const onSetOrder = useCallback((e, form, column) => {
    setOrder({
      [form]: {
        ...column,
      },
    });
  }, []);

  const onSetFilter = useCallback((e, form, partFilter) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      [form]: {
        ...oldFilter[form] || {},
        ...partFilter,
      },
    }));
  }, []);

  const onClearFilter = useCallback((e, form, keys = []) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      [form]: oldFilter[form] && Object.keys(oldFilter[form])
        .filter((key) => !keys.includes(key))
        .reduce(
          (RK, key) => ({ ...RK, [key]: oldFilter[form][key] }),
          {},
        ),
    }));
  }, []);

  const orderingForm = useCallback((a, b, formOrder) => {
    if (Object.values(formOrder).includes('DESC')) {
      if (Object.keys(formOrder)[0] === 'lastEditDate' || Object.keys(formOrder)[0] === 'addDate') {
        return (new Date(b[Object.keys(formOrder)]).valueOf()
            - new Date(a[Object.keys(formOrder)]).valueOf());
      }
      return b.analytics[Object.keys(formOrder)] - a.analytics[Object.keys(formOrder)];
    }

    if (Object.values(formOrder).includes('ASC')) {
      if (Object.keys(formOrder)[0] === 'lastEditDate' || Object.keys(formOrder)[0] === 'addDate') {
        return (new Date(a[Object.keys(formOrder)]).valueOf()
            - new Date(b[Object.keys(formOrder)]).valueOf());
      }
      return a.analytics[Object.keys(formOrder)] - b.analytics[Object.keys(formOrder)];
    }
    return new Date(b.lastEditDate).valueOf() - new Date(a.lastEditDate).valueOf();
  }, []);

  const displayList = useMemo(
    () => {
      if (list) {
        const codes = new Set(list.map((frm) => frm.code).filter((code) => code in forms));
        const result = [...codes].reduce(
          (R, code) => {
            let items = list.filter((row) => row.code === code);
            items = items.map((row) => {
              const {
                _ACTUAL, _PROJAPPROVED, _REPORT_PROJAPPR, _ISPUBLISH, _SIGNEDBYFINDEP,
              } = row.analytics;
              return {
                ...row,
                analytics: {
                  ...row.analytics,
                  _ACTUAL: (!_ACTUAL || (_ACTUAL === 'Ні')) ? 'Ні' : 'Так',
                  _ISPUBLISH: (!_ISPUBLISH || (_ISPUBLISH === 'Ні')) ? 'Ні' : 'Так',
                  _PROJAPPROVED: _PROJAPPROVED === 'A' ? 'Затверджено' : 'Проект',
                  _REPORT_PROJAPPR: _REPORT_PROJAPPR === 'A' ? 'Кінцеве рішення' : 'Проект',
                  success: '?',
                  _SIGNEDBYFINDEP: (!_SIGNEDBYFINDEP || (_SIGNEDBYFINDEP === 'Ні')) ? 'Ні' : 'Так',
                },
              };
            });
            if (verifyResults) {
              items = items.map((l) => {
                if (l.id in verifyResults) {
                  return {
                    ...l,
                    analytics:
                    { ...l.analytics, success: verifyResults[l.id].success ? 'Верифіковано' : 'З помилками' },
                  };
                }
                return l;
              });
            }
            const formFilter = filter[code] || {}; // {f: 1, a: 2}
            const filteredFormData = items.filter((row) => Object.keys(formFilter)
              .reduce(
                (filterR, field) => {
                  if (filterR) {
                    if (field === 'addDate' || field === 'lastEditDate') {
                      return row[field] === formFilter[field];
                    }
                    return row.analytics[field] === formFilter[field];
                  }
                  return false;
                },
                true,
              ));
            const formOrder = order[code] || {};
            const orderedFormData = [...filteredFormData]
              .sort((a, b) => orderingForm(a, b, formOrder));
            return {
              ...R,
              [code]: {
                items,
                order: formOrder,
                filter: formFilter,
                display_list: orderedFormData,
              },
            };
          },
          {},
        );
        return result;
      }
      return null;
    },
    [filter, list, order, orderingForm, verifyResults],
  );

  const getResultVerifyList = useCallback((type) => {
    switch (type) {
      case 0:
        setResultVerifyList(Array.of(resultVerify.find((el) => el[1].receipt)));
        break;
      case 1:
        setResultVerifyList(resultVerify.filter((el) => el[1].receipt));
        break;
      default:
        setResultVerifyList(resultVerify);
    }
  }, [resultVerify]);

  const years = useMemo(
    () => {
      const d = new Date();
      return [...Array(d.getFullYear() - 2020 + 1)].map((e, i) => i + 2020);
    },
    [],
  );

  return (
    <>
      <PageHeader
        title={meta.srv.DocumentsInLogica.name}
        description={meta.srv.DocumentsInLogica.name}
        className="mb-3"
      >
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => {
              dellComponentFromWindowsManager();
            }}
            title="Закрити"
          />
        </div>
      </PageHeader>
      <LogcaLoginModal />
      {isLoading && <Loader />}
      {error && (
        <Alert variant="danger" dismissible onClose={onClearErr}>
          <Alert.Heading>
            {error}
          </Alert.Heading>
        </Alert>
      )}
      <Nav className="gap-2">
        {(!ticket || (error === 'Необхідно авторизуватись!')) && (
          <Navbar.Brand
            href={meta.srv.LogikaLogin.frontendURL}
            onClick={() => setError(null)}
          >
            Авторизація у LOGICA
          </Navbar.Brand>
        )}

        <DropdownButton variant="outline-success" id="dropdown-item-button" title={year} style={{ marginRight: '10px' }}>
          {years.map((y) => (
            <Dropdown.Item key={y} as="button" onClick={() => setYear(y)}>{y}</Dropdown.Item>
          ))}
        </DropdownButton>

        <DropdownButton variant="outline-primary" id="dropdown-item-button" title="Тип відбору форм">
          <Dropdown.Item as="button" onClick={getList} value="0">Тільки документи установи</Dropdown.Item>
          <Dropdown.Item as="button" onClick={getList} value="1">Документи установи та підпорядкованих ГРК</Dropdown.Item>
          <Dropdown.Item as="button" onClick={getList} value="2">Документи бюджетів, що входять до зведенного бюджету (області, району, міста)</Dropdown.Item>
        </DropdownButton>
      </Nav>

      <Modal
        show={resultVerifyPage}
        size="xl"
        onHide={() => setResultVerifyPage(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Результат відправлення документу до ЛОГІКА
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResultVerifyPage
            resultVerify={resultVerify}
            onClose={() => setResultVerifyPage(false)}
            getResult={getResult}
            resJson={resJson}
            getResultVerifyList={getResultVerifyList}
            resultVerifyList={resultVerifyList}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={commentPage}
        size="xl"
        onHide={() => setCommentPage(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Підпис даних
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Comment
            onClose={() => setCommentPage(false)}
            func={commentPage === 'approved' ? handleApproved : handlePublish}
          />
        </Modal.Body>
      </Modal>

      {(err || logikaErrors.length > 0) && (
        <Alert variant="danger" dismissible onClose={onClearErr}>
          <Alert.Heading>
            {err && err}
            {/* eslint-disable-next-line react/no-array-index-key */}
            {logikaErrors.length > 0 && logikaErrors.map((e, idx) => <li key={idx}>{e}</li>)}
          </Alert.Heading>
        </Alert>
      )}

      {!err && logikaErrors.length === 0 && response === 'Зміни пройшли успішно!' && (
        <Alert variant="success" onClose={onClearErr}>
          <Alert.Heading>
            Вітаємо!
          </Alert.Heading>
          {response}
        </Alert>
      )}

      {list && (
        <>
          <Nav className="gap-2" key={list.lenght}>
            <StyledButton variant="outline-danger" onClick={handleCancel} disabled={selectedIds.length !== 1}>
              <FontAwesomeIcon icon={faBan} />
              <Text>Скасувати звіт</Text>
            </StyledButton>

            <StyledDropdownButton id="dropdown-item-button" title="Змінити ознаку &quot;Затверджено&quot;" drop="right" variant="outline-warning" disabled={((role !== '01') && (!admin)) || (selectedIds.length === 0)}>
              <Dropdown.Item as="button" onClick={() => handleApproved(true)} value="0">Затвердити</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setCommentPage('approved')} value="1">Зняти затвердження</Dropdown.Item>
            </StyledDropdownButton>

            <StyledDropdownButton id="dropdown-item-button" title="Змінити ознаку &quot;Опубліковано&quot;" drop="right" variant="outline-warning" disabled={(selectedIds.length === 0)}>
              <Dropdown.Item as="button" onClick={() => handlePublish(true)} value="0">Опублікувати</Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setCommentPage('publish')} value="1">Зняти опублікування</Dropdown.Item>
            </StyledDropdownButton>

            <StyledButton variant="outline-success" onClick={getFile} disabled={selectedIds.length !== 1}>
              <FontAwesomeIcon icon={faFileDownload} />
              <Text>Скачати</Text>
            </StyledButton>

            <StyledButton variant="outline-info" onClick={getStatusInfo} disabled={selectedIds.length !== 1}>
              <FontAwesomeIcon icon={faCheckDouble} />
              <Text>Результат верифікації</Text>
            </StyledButton>

            <StyledButton variant="outline-secondary" onClick={getHistory} disabled={selectedIds.length !== 1}>
              <FontAwesomeIcon icon={faHistory} />
              <Text>Історія змін</Text>
            </StyledButton>
          </Nav>

          <Tables
            data={list}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setSelectedBs={setSelectedBs}
            displayList={displayList}
            onSetFilter={onSetFilter}
            onClearFilter={onClearFilter}
            onSetOrder={onSetOrder}
          />
        </>
      )}
    </>
  );
}

export default Editor;
