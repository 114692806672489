import React, {
  useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Container, Accordion, Col, Row, Dropdown, Button,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import md from '../../../../constants/meta/documents/budgetRequest';
import {
  DeptManagementAnalysisTP, DocsTP, ExtraIndicatorsTP, ExtraMoneyTP,
  IndicatorsTP,
  InvestProgramsTP,
  InvestExtraMoneyTP,
  KDBForecastTP,
  KEKVTP, RegulationsTP,
  TargetProgramsTP,
  Tasks2019Table,
  TasksTP,
  WageStructureTP,
  WorkersAmountTP, ReturnTP, IndicatorsForTargetTP, KFBForecastTP,
} from './tabs';
import {
  CommandPanelColor, StyledGreyLabel, StyledLabel, SubText,
} from '../../../../components/Form/styledForm';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import ContextAwareToggle from '../../../../components/bootstrap_components/accordion';
import { CustomToggle } from '../../../../components/bootstrap_components';

import { soSelector } from '../../_common/selectors';

function BudgetRequestTabs({
  data, actions, permissions,
}) {
  const year = data[md.columns.year.name] || 0;
  const readOnly = !permissions.canChange;
  const sessionOptions = useSelector(soSelector);
  const FIFilter = useMemo(() => [{
    fieldName: 'ФинансовыйОрган',
    value: data[md.columns.FI.name],
  }], [data]);
  const turnBuildObjOn = data[md.columns.turnBuildObjOn.name];
  const kekv = useRef();
  const tasks2019 = useRef();
  const tasks = useRef();
  const indicators = useRef();
  const workersAmount = useRef();
  const wageStructure = useRef();
  const targetPrograms = useRef();
  const investPrograms = useRef();
  const resultAnalysis = useRef();
  const debtManagementAnalysis = useRef();
  const budgetManagementAnalysis = useRef();
  const regulations = useRef();
  const targetLawBasis = useRef();
  const kdb = useRef();
  const kfb = useRef();
  const extraMoney = useRef();
  const extraIndicators = useRef();
  const consequences0 = useRef();
  const investExtraMoney = useRef();
  const returnTb = useRef();
  const indicatorsForTarget = useRef();
  const FI = data[md.columns.FI.name];
  const budget = data[md.columns.budget.name];
  const isAdmin = sessionOptions.get('is_admin', false);

  const BudgetFilter = useMemo(
    () => [{
      fieldName: 'Бюджет',
      value: budget,
    }],
    [budget],
  );
  const BudgetOwnerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: budget,
    }],
    [budget],
  );
  const csuFilter = useMemo(
    () => [{
      fieldName: 'parent',
      value: FI,
    }],
    [FI],
  );
  const FIOwnerFilter = useMemo(
    () => [{
      fieldName: 'Владелец',
      value: FI,
    }],
    [FI],
  );

  return (
    <Tabs defaultActiveKey="All">
      <Tab title="Загальні" eventKey="All" tabClassName="font-weight-bold">
        <Container fluid className="mx-1 my-4">
          <Row>
            {isAdmin ? (
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.budget.label}
                  value={data[md.columns.budget.name]}
                  onChange={(e, value) => {
                    actions.onChange({
                      [md.columns.budget.name]: value,
                    });
                  }}
                  modelType="cat"
                  modelName="budget"
                  filter={BudgetFilter}
                  readOnly={readOnly}
                />
              </Col>
            ) : (
              <Col>
                <StyledGreyLabel>
                  {md.columns.budget.label}
                  :
                  {' '}
                  <SubText>
                    {' '}
                    { data[md.columns.budget.name] && data[md.columns.budget.name].repr }
                  </SubText>
                </StyledGreyLabel>
              </Col>
            )}
            {isAdmin ? (
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.FI.label}
                  value={data[md.columns.FI.name]}
                  onChange={(e, value) => {
                    actions.onChange({
                      [md.columns.FI.name]: value,
                    });
                  }}
                  modelType="cat"
                  modelName="csu"
                  filter={csuFilter}
                  readOnly={readOnly}
                />
              </Col>
            ) : (
              <Col>
                <StyledGreyLabel>
                  {md.columns.FI.label}
                  :
                  {' '}
                  <SubText>
                    {' '}
                    { data[md.columns.FI.name] && data[md.columns.FI.name].repr }
                  </SubText>
                </StyledGreyLabel>
              </Col>
            )}
            {isAdmin ? (
              <Col>
                <EditorControls.ItemPicker
                  label={md.columns.author.label}
                  value={data[md.columns.author.name]}
                  onChange={(e, value) => {
                    actions.onChange({
                      [md.columns.author.name]: value,
                    });
                  }}
                  modelType="cat"
                  modelName="users"
                  readOnly={readOnly}
                />
              </Col>
            ) : (
              <Col>
                <StyledGreyLabel>
                  {md.columns.author.label}
                  :
                  {' '}
                  <SubText>
                    {' '}
                    { data[md.columns.author.name] && data[md.columns.author.name].repr }
                  </SubText>
                </StyledGreyLabel>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <EditorControls.DateInput
                label={md.columns.date.label}
                value={data[md.columns.date.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.date.name]: value,
                  [md.columns.year.name]: new Date(value).getFullYear(),
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.NumberInput
                label={md.columns.number.label}
                value={data[md.columns.number.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.number.name]: value,
                })}
                readOnly
              />
            </Col>
            <Col>
              <EditorControls.YearInput
                label={md.columns.year.label}
                value={data[md.columns.year.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.year.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.CSU.label}
                value={data[md.columns.CSU.name]}
                onChange={(e, value) => {
                  actions.onChange({
                    [md.columns.CSU.name]: value,
                  });
                }}
                modelType="cat"
                modelName="csu"
                noHierarchy
                canErase
                filter={csuFilter}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              {data[md.columns.docByKBP7.name] ? (
                <EditorControls.ItemPicker
                  label={md.columns.KPKV.label}
                  value={data[md.columns.KPKV.name]}
                  onChange={async (e, value) => {
                    await actions.onChange({
                      [md.columns.KPKV.name]: value,
                    });
                    actions.onSR('CHANGE_PROGRAM');
                  }}
                  modelType="cat"
                  modelName="kbp7Classifier"
                  filter={BudgetOwnerFilter}
                  readOnly={readOnly}
                />
              ) : (
                <EditorControls.ItemPicker
                  label={md.columns.program.label}
                  value={data[md.columns.program.name]}
                  onChange={async (e, value) => {
                    await actions.onChange({
                      [md.columns.program.name]: value,
                    });
                    actions.onSR('CHANGE_PROGRAM');
                  }}
                  modelType="cat"
                  modelName="elementKFK"
                  filter={FIOwnerFilter}
                  readOnly={readOnly}
                />
              )}
            </Col>
            <Col style={{
              justifySelf: 'center',
              alignSelf: 'center',
            }}
            >
              <StyledLabel style={{ color: 'red' }}>
                {md.columns.codClassifierKBP.label}
                :
                {data[md.columns.codClassifierKBP.name]}
              </StyledLabel>
            </Col>
            <Col style={{
              justifySelf: 'center',
              alignSelf: 'center',
            }}
            >
              <StyledLabel style={{ color: 'red' }}>
                {md.columns.codClassifierKBP7.label}
                :
                {data[md.columns.codClassifierKBP7.name]}
              </StyledLabel>
            </Col>
            { !data[md.columns.docByKBP7.name] && (
              <Col style={{
                justifySelf: 'center',
                alignSelf: 'center',
              }}
              >
                <StyledLabel style={{ color: 'red' }}>
                  {md.columns.codClassifierKFKBK.label}
                  :
                  {data[md.columns.codClassifierKFKBK.name]}
                </StyledLabel>
              </Col>
            )}
          </Row>
        </Container>
      </Tab>
      <Tab title="Видатки" eventKey="SpendingsTab" tabClassName="font-weight-bold">
        <Container fluid className="mx-1 my-4" style={{ padding: '1px' }}>
          <Accordion defaultActiveKey="kekv">
            <Container fluid className="mx-1 my-4" style={{ padding: '1px' }}>
              <Row noGutters>
                <Col sm={1} className="d-flex flex-column">
                  <ContextAwareToggle eventKey="kekv" el={kekv} addStyle={{ padding: '2.5%  0' }}>
                    Видатки за КЕКВ
                  </ContextAwareToggle>
                  {(data[md.columns.showIndicatorsForTarget.name]) && (
                    <ContextAwareToggle eventKey="indicatorsForTarget" el={indicatorsForTarget} addStyle={{ padding: '2.5%  0' }}>
                      Цілі державної політики
                    </ContextAwareToggle>
                  )}
                  <ContextAwareToggle eventKey="tasks2019" el={tasks2019} addStyle={{ padding: '2.5%  0' }}>
                    {md.tables.tasks2019.label}
                  </ContextAwareToggle>
                  <ContextAwareToggle eventKey="tasks" el={tasks} addStyle={{ padding: '2.5%  0' }}>
                    Напрями та Цілі
                  </ContextAwareToggle>
                  <ContextAwareToggle eventKey="indicators" el={indicators} addStyle={{ padding: '2.5%  0' }}>
                    Показники
                  </ContextAwareToggle>
                  <ContextAwareToggle eventKey="wageStructure" el={wageStructure} addStyle={{ padding: '2.5%  0' }}>
                    {md.tables.wageStructure.label}
                  </ContextAwareToggle>
                  <ContextAwareToggle eventKey="workersAmount" el={workersAmount} addStyle={{ padding: '2.5%  0' }}>
                    {md.tables.workersAmount.label}
                  </ContextAwareToggle>
                  <ContextAwareToggle eventKey="targetPrograms" el={targetPrograms} addStyle={{ padding: '2.5%  0' }}>
                    Цільові/регіональні програми
                  </ContextAwareToggle>
                  <ContextAwareToggle eventKey="investPrograms" el={investPrograms} addStyle={{ padding: '2.5%  0' }}>
                    {md.tables.investPrograms.label}
                  </ContextAwareToggle>
                  <ContextAwareToggle eventKey="resultAnalysis" el={resultAnalysis} addStyle={{ padding: '2.5%  0' }}>
                    {md.columns.ResultAnalysis.label}
                  </ContextAwareToggle>
                  <Dropdown className="my-2 ps-1" size="sm">
                    <Dropdown.Toggle as={CustomToggle}>
                      <span style={{ fontSize: '0.8em' }}> Аналіз управління заборгованістю </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <ContextAwareToggle eventKey="debtManagementAnalysis" el={debtManagementAnalysis} addStyle={{ padding: '2.5%  0' }}>
                        {md.tables.debtManagementAnalysis.label}
                      </ContextAwareToggle>
                      <ContextAwareToggle eventKey="budgetManagementAnalysis" el={budgetManagementAnalysis} addStyle={{ padding: '2.5%  0' }}>
                        {md.columns.BudgetManagementAnalysis.label}
                      </ContextAwareToggle>
                    </Dropdown.Menu>
                  </Dropdown>
                  <ContextAwareToggle eventKey="regulations" el={regulations} addStyle={{ padding: '2.5%  0' }}>
                    {md.tables.regulations.label}
                  </ContextAwareToggle>
                  <ContextAwareToggle eventKey="targetLawBasis" el={targetLawBasis} addStyle={{ padding: '2.5%  0' }}>
                    {md.columns.TargetLawBasis.label}
                  </ContextAwareToggle>
                </Col>
                <Col>
                  <Accordion.Collapse eventKey="kekv">
                    <div ref={kekv}>
                      <KEKVTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="tasks">
                    <div ref={tasks}>
                      <TasksTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="tasks2019">
                    <div ref={tasks2019}>
                      <Tasks2019Table data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="indicators">
                    <div ref={indicators}>
                      <IndicatorsTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="wageStructure">
                    <div ref={wageStructure}>
                      <WageStructureTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="workersAmount">
                    <div ref={workersAmount}>
                      <WorkersAmountTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="targetPrograms">
                    <div ref={targetPrograms}>
                      <TargetProgramsTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="investPrograms">
                    <div ref={investPrograms}>
                      <InvestProgramsTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="resultAnalysis">
                    <div ref={resultAnalysis}>
                      <Container fluid>
                        <Row>
                          <Col>
                            <EditorControls.TextInput
                              label={`Аналіз результатів, досягнутих внаслідок використання коштів 
                              спеціального фонду бюджету у ${year - 2} році, очікувані результати у ${year - 1} році`}
                              value={data[md.columns.groundsAndJustificationOfSpecialFundExpenditures.name]}
                              onChange={(e, value) => {
                                actions.onChange({
                                  [md.columns.groundsAndJustificationOfSpecialFundExpenditures.name]: value,
                                });
                              }}
                              readOnly={readOnly}
                            />
                          </Col>
                          <Col>
                            <EditorControls.TextInput
                              label={`Аналіз результатів, досягнутих внаслідок використання коштів загального фонду бюджету у ${year - 2} році, очікувані результати у ${year - 1}, обгрунтування необхідності передбачення видатків/надання кредитів на ${year} - ${year + 2} роки`}
                              value={data[md.columns.zfResultAnalysis.name]}
                              onChange={(e, value) => {
                                actions.onChange({
                                  [md.columns.zfResultAnalysis.name]: value,
                                });
                              }}
                              readOnly={readOnly}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="debtManagementAnalysis">
                    <div ref={debtManagementAnalysis}>
                      <DeptManagementAnalysisTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="budgetManagementAnalysis">
                    <div ref={budgetManagementAnalysis}>
                      <Container fluid>
                        <Row>
                          <Col>
                            <EditorControls.TextInput
                              label={md.columns.liabilitiesManagementAnalysis.label}
                              value={data[md.columns.liabilitiesManagementAnalysis.name]}
                              onChange={(e, value) => {
                                actions.onChange({
                                  [md.columns.liabilitiesManagementAnalysis.name]: value,
                                });
                              }}
                              readOnly={readOnly}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="regulations">
                    <div ref={regulations}>
                      <RegulationsTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="targetLawBasis">
                    <div ref={targetLawBasis}>
                      <Container fluid>
                        <Row>
                          <Col>
                            <Button
                              onClick={() => actions.onSR('FILL_TARGET_FROM_FORECAST')}
                              variant="success"
                            >
                              Заповнити мету з прогнозів
                            </Button>
                            <EditorControls.TextInput
                              label={md.columns.budgetProgramPurpose.label}
                              value={data[md.columns.budgetProgramPurpose.name]}
                              onChange={(e, value) => {
                                actions.onChange({
                                  [md.columns.budgetProgramPurpose.name]: value,
                                });
                              }}
                              readOnly={readOnly}
                            />
                          </Col>
                          <Col className="d-flex flex-column justify-content-end">
                            <EditorControls.TextInput
                              label={md.columns.disposerPurpose.label}
                              value={data[md.columns.disposerPurpose.name]}
                              onChange={(e, value) => {
                                actions.onChange({
                                  [md.columns.disposerPurpose.name]: value,
                                });
                              }}
                              readOnly={readOnly}
                              rows={4}
                            />
                          </Col>
                          <Col>
                            <Button
                              onClick={() => actions.onSR('FILL_TARGET_BASIS_FORECAST')}
                              variant="success"
                            >
                              Заповнити підстави з прогнозів
                            </Button>
                            <EditorControls.TextInput
                              label={md.columns.basis.label}
                              value={data[md.columns.basis.name]}
                              onChange={(e, value) => {
                                actions.onChange({
                                  [md.columns.basis.name]: value,
                                });
                              }}
                              readOnly={readOnly}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey="indicatorsForTarget">
                    <div ref={indicatorsForTarget}>
                      <IndicatorsForTargetTP data={data} actions={actions} readOnly={readOnly} />
                    </div>
                  </Accordion.Collapse>
                </Col>
              </Row>
            </Container>
            {/* <Accordion.Collapse eventKey="kekv"> */}
            {/*  <div ref={kekv}> */}
            {/*    <KEKVTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="tasks"> */}
            {/*  <div ref={tasks}> */}
            {/*    <TasksTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="tasks2019"> */}
            {/*  <div ref={tasks2019}> */}
            {/*    <Tasks2019Table data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="indicators"> */}
            {/*  <div ref={indicators}> */}
            {/*    <IndicatorsTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="wageStructure"> */}
            {/*  <div ref={wageStructure}> */}
            {/*    <WageStructureTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="workersAmount"> */}
            {/*  <div ref={workersAmount}> */}
            {/*    <WorkersAmountTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="targetPrograms"> */}
            {/*  <div ref={targetPrograms}> */}
            {/*    <TargetProgramsTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="investPrograms"> */}
            {/*  <div ref={investPrograms}> */}
            {/*    <InvestProgramsTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="resultAnalysis"> */}
            {/*  <div ref={resultAnalysis}> */}
            {/*    <Container fluid> */}
            {/*      <Row> */}
            {/*        <Col> */}
            {/*          <EditorControls.TextInput */}
            {/*            label={`Аналіз результатів, досягнутих внаслідок використання коштів спеціального фонду бюджету у ${year - 2} році, очікувані результати у ${year - 1} році`} */}
            {/*            value={data[md.columns.groundsAndJustificationOfSpecialFundExpenditures.name]} */}
            {/*            onChange={(e, value) => { */}
            {/*              actions.onChange({ */}
            {/*                [md.columns.groundsAndJustificationOfSpecialFundExpenditures.name]: value, */}
            {/*              }); */}
            {/*            }} */}
            {/*            readOnly={readOnly} */}
            {/*          /> */}
            {/*        </Col> */}
            {/*        <Col> */}
            {/*          <EditorControls.TextInput */}
            {/*            label={`Аналіз результатів, досягнутих внаслідок використання коштів загального фонду бюджету у ${year - 2} році, очікувані результати у ${year - 1}, обгрунтування необхідності передбачення видатків/надання кредитів на ${year} - ${year + 2} роки`} */}
            {/*            value={data[md.columns.zfResultAnalysis.name]} */}
            {/*            onChange={(e, value) => { */}
            {/*              actions.onChange({ */}
            {/*                [md.columns.zfResultAnalysis.name]: value, */}
            {/*              }); */}
            {/*            }} */}
            {/*            readOnly={readOnly} */}
            {/*          /> */}
            {/*        </Col> */}
            {/*      </Row> */}
            {/*    </Container> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="debtManagementAnalysis"> */}
            {/*  <div ref={debtManagementAnalysis}> */}
            {/*    <DeptManagementAnalysisTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="budgetManagementAnalysis"> */}
            {/*  <div ref={budgetManagementAnalysis}> */}
            {/*    <Container fluid> */}
            {/*      <Row> */}
            {/*        <Col> */}
            {/*          <EditorControls.TextInput */}
            {/*            label={md.columns.liabilitiesManagementAnalysis.label} */}
            {/*            value={data[md.columns.liabilitiesManagementAnalysis.name]} */}
            {/*            onChange={(e, value) => { */}
            {/*              actions.onChange({ */}
            {/*                [md.columns.liabilitiesManagementAnalysis.name]: value, */}
            {/*              }); */}
            {/*            }} */}
            {/*            readOnly={readOnly} */}
            {/*          /> */}
            {/*        </Col> */}
            {/*      </Row> */}
            {/*    </Container> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="regulations"> */}
            {/*  <div ref={regulations}> */}
            {/*    <RegulationsTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="targetLawBasis"> */}
            {/*  <div ref={targetLawBasis}> */}
            {/*    <Container fluid> */}
            {/*      <Row> */}
            {/*        <Col> */}
            {/*          <Button */}
            {/*            onClick={() => actions.onSR('FILL_TARGET_FROM_FORECAST')} */}
            {/*            variant="success" */}
            {/*          > */}
            {/*            Заповнити мету з прогнозів */}
            {/*          </Button> */}
            {/*          <EditorControls.TextInput */}
            {/*            label={md.columns.budgetProgramPurpose.label} */}
            {/*            value={data[md.columns.budgetProgramPurpose.name]} */}
            {/*            onChange={(e, value) => { */}
            {/*              actions.onChange({ */}
            {/*                [md.columns.budgetProgramPurpose.name]: value, */}
            {/*              }); */}
            {/*            }} */}
            {/*            readOnly={readOnly} */}
            {/*          /> */}
            {/*        </Col> */}
            {/*        <Col className="d-flex flex-column justify-content-end"> */}
            {/*          <EditorControls.TextInput */}
            {/*            label={md.columns.disposerPurpose.label} */}
            {/*            value={data[md.columns.disposerPurpose.name]} */}
            {/*            onChange={(e, value) => { */}
            {/*              actions.onChange({ */}
            {/*                [md.columns.disposerPurpose.name]: value, */}
            {/*              }); */}
            {/*            }} */}
            {/*            readOnly={readOnly} */}
            {/*            rows={4} */}
            {/*          /> */}
            {/*        </Col> */}
            {/*        <Col> */}
            {/*          <Button */}
            {/*            onClick={() => actions.onSR('FILL_TARGET_BASIS_FORECAST')} */}
            {/*            variant="success" */}
            {/*          > */}
            {/*            Заповнити підстави з прогнозів */}
            {/*          </Button> */}
            {/*          <EditorControls.TextInput */}
            {/*            label={md.columns.basis.label} */}
            {/*            value={data[md.columns.basis.name]} */}
            {/*            onChange={(e, value) => { */}
            {/*              actions.onChange({ */}
            {/*                [md.columns.basis.name]: value, */}
            {/*              }); */}
            {/*            }} */}
            {/*            readOnly={readOnly} */}
            {/*          /> */}
            {/*        </Col> */}
            {/*      </Row> */}
            {/*    </Container> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
            {/* <Accordion.Collapse eventKey="indicatorsForTarget"> */}
            {/*  <div ref={indicatorsForTarget}> */}
            {/*    <IndicatorsForTargetTP data={data} actions={actions} readOnly={readOnly} /> */}
            {/*  </div> */}
            {/* </Accordion.Collapse> */}
          </Accordion>
        </Container>
      </Tab>
      <Tab title="Надходження" eventKey="RevenueTab" tabClassName="font-weight-bold">
        <Container fluid className="mx-1 my-4">
          <Accordion defaultActiveKey="kdb">
            <Container fluid>
              <Row>
                <Col>
                  <ContextAwareToggle eventKey="kdb" el={kdb}>
                    {md.tables.KDBForecast.label}
                  </ContextAwareToggle>
                </Col>
                <Col>
                  <ContextAwareToggle eventKey="kfb" el={kfb}>
                    {md.tables.KFBForecast.label}
                  </ContextAwareToggle>
                </Col>
                <Col>
                  <ContextAwareToggle eventKey="returnKEKV" el={returnTb}>
                    {md.tables.return.label}
                  </ContextAwareToggle>
                </Col>
              </Row>
            </Container>
            <Accordion.Collapse eventKey="kdb">
              <div ref={kdb}>
                <KDBForecastTP data={data} actions={actions} readOnly={readOnly} />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="kfb">
              <div ref={kfb}>
                <KFBForecastTP data={data} actions={actions} readOnly={readOnly} />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="returnKEKV">
              <div ref={returnTb}>
                <ReturnTP data={data} actions={actions} readOnly={readOnly} />
              </div>
            </Accordion.Collapse>
          </Accordion>
        </Container>
      </Tab>
      <Tab title="Заповнити за даними паспорту та звіту з виконання" eventKey="IndicatorsPassFactTab" tabClassName="font-weight-bold">
        <Container fluid className="mx-1 my-4">
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={`${`${md.columns.indicatorFact.label} (${year - 2})р.`}`}
                value={data[md.columns.indicatorFact.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.indicatorFact.name]: value,
                })}
                modelType="doc"
                modelName="indicatorFact"
                filter={FIFilter}
                readOnly={readOnly}
                canErase
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={`${`${md.columns.indicatorPassport.label} (${year - 1})р.`}`}
                value={data[md.columns.indicatorPassport.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.indicatorPassport.name]: value,
                })}
                modelType="doc"
                modelName="indicatorPassport"
                filter={FIFilter}
                readOnly={readOnly}
                canErase
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={`${`${md.columns.indicatorPassportUPD.label} (${year})р.`}`}
                value={data[md.columns.indicatorPassportUPD.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.indicatorPassportUPD.name]: value,
                })}
                modelType="doc"
                modelName="indicatorPassport"
                filter={FIFilter}
                readOnly={readOnly}
                canErase
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CommandPanelColor
                label="Заповнити"
                onClick={() => actions.onSR('FILL_PLAN_FACT_INDICATORS')}
              />
            </Col>
          </Row>
        </Container>
      </Tab>
      <Tab title="Дод.кошти(Форма 3)" eventKey="Form3Tab" tabClassName="font-weight-bold">
        <Container fluid className="mx-1 my-4">
          <Accordion defaultActiveKey="0">
            <Container fluid>
              <Row>
                <Col>
                  <ContextAwareToggle eventKey="0" el={extraMoney}>
                    {md.tables.extraMoney.label}
                  </ContextAwareToggle>
                </Col>
                <Col>
                  <ContextAwareToggle eventKey="1" el={extraIndicators}>
                    {md.tables.extraIndicators.label}
                  </ContextAwareToggle>
                </Col>
                <Col>
                  <ContextAwareToggle eventKey="2" el={consequences0}>
                    {md.columns.consequences0.label}
                  </ContextAwareToggle>
                </Col>
                { turnBuildObjOn && (
                  <Col>
                    <ContextAwareToggle eventKey="3" el={investExtraMoney}>
                      {md.tables.investExtraMoney.label}
                    </ContextAwareToggle>
                  </Col>
                )}
              </Row>
            </Container>
            <Accordion.Collapse eventKey="0">
              <div ref={extraMoney}>
                <ExtraMoneyTP data={data} actions={actions} readOnly={readOnly} />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div ref={extraIndicators}>
                <ExtraIndicatorsTP data={data} actions={actions} readOnly={readOnly} />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="2">
              <div ref={consequences0}>
                <Container fluid>
                  <Row>
                    <Col>
                      <EditorControls.TextInput
                        label={`Наслідки на ${year} рік у разі невиділення додаткових коштів  на ${year} рік та альтернативні заходи, зроблені для забезпечення виконання бюджетної програми`}
                        value={data[md.columns.consequences0.name]}
                        onChange={(e, value) => {
                          actions.onChange({
                            [md.columns.consequences0.name]: value,
                          });
                        }}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col>
                      <EditorControls.TextInput
                        label={`Наслідки на ${year + 1} - ${year + 2} рік у разі невиділення додаткових коштів  на ${year + 1} - ${year + 2} роки та альтернативні заходи, зроблені для забезпечення виконання бюджетної програми`}
                        value={data[md.columns.consequences1_3.name]}
                        onChange={(e, value) => {
                          actions.onChange({
                            [md.columns.consequences1_3.name]: value,
                          });
                        }}
                        readOnly={readOnly}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="3">
              <div ref={investExtraMoney}>
                <InvestExtraMoneyTP data={data} actions={actions} readOnly={readOnly} />
              </div>
            </Accordion.Collapse>
          </Accordion>
        </Container>
      </Tab>
      <Tab title="Заповнити за даними розпорядників" eventKey="LowerLevelTab" tabClassName="font-weight-bold">
        <Container fluid className="mx-1 my-4">
          <Row>
            <Col>
              <EditorControls.DateInput
                label={md.columns.startPeriod.label}
                value={data[md.columns.startPeriod.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.startPeriod.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.DateInput
                label={md.columns.endPeriod.label}
                value={data[md.columns.endPeriod.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.endPeriod.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            { !data[md.columns.docByKBP7.name] && (
              <Col className="d-flex align-items-end text-right">
                <EditorControls.CheckboxInput
                  controlId={`byKBP7-${data.id}`}
                  value={data[md.columns.byKBP7.name]}
                  onChange={(e, value) => actions.onChange({
                    [md.columns.byKBP7.name]: value,
                  })}
                  label={md.columns.byKBP7.label}
                  readOnly={readOnly}
                />
              </Col>
            )}
            <Col style={{
              justifySelf: 'center',
              alignSelf: 'center',
            }}
            >
              <CommandPanelColor
                label="Крок1. Отримати документи"
                onClick={() => actions.onSR('FILL_DOCS')}
              />
            </Col>
            <Col style={{
              justifySelf: 'center',
              alignSelf: 'center',
            }}
            >
              <CommandPanelColor
                label="Крок2. Заповнити з отриманих документів"
                onClick={() => actions.onSR('IN_DOC')}
              />
            </Col>
          </Row>
          <Row>
            <DocsTP data={data} actions={actions} readOnly={readOnly} />
          </Row>
        </Container>
      </Tab>
      <Tab title={md.columns.Note.label} eventKey="Note" tabClassName="font-weight-bold">
        <Container fluid>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.Note.label}
                value={data[md.columns.Note.name]}
                onChange={(e, value) => {
                  actions.onChange({
                    [md.columns.Note.name]: value,
                  });
                }}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </Container>
        {' '}

      </Tab>
    </Tabs>
  );
}

BudgetRequestTabs.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onSR: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};
export default BudgetRequestTabs;
