import React, { useEffect, useMemo } from 'react';
import {
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { dellComponentFromWindowsManager } from '../../../../actions/windowsManager';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/catalogs/resolutionSession';
import {
  StyledLabel, StyledButton,
} from '../../../../components/Form/styledForm';
import { emptyUid } from '../../../../constants/meta/common';
import meta from '../../../../constants/meta';

const Button = styled(StyledButton)`
    justify-content: center;
    height: 100%;
    width: 250px;
    cursor: pointer;
    background: ${(props) => props.background};
    &:hover{
    background: ${(props) => props.hover};
    }
`;

function ResolutionSession({ data, actions, permissions }) {
  const readOnly = !permissions.canChange;

  const navigate = useNavigate();

  const logikaId = useMemo(
    () => data[md.columns.docSendToLogica.name] && data[md.columns.docSendToLogica.name].id,
    [data],
  );

  const docId = useMemo(
    () => data[md.columns.docId.name],
    [data],
  );
  const dispatch = useDispatch();

  useEffect(
    () => {
      const modelType = 'cat';
      const modelName = 'resolutionSession';
      if (logikaId && logikaId !== emptyUid) {
        dispatch(dellComponentFromWindowsManager(`/${modelType}/${modelName}/${docId}/`));

        navigate({
          pathname: `${meta.doc.docTransferToLogica.frontend}/${logikaId}/`,
        });
      }
    },
    [dispatch, docId, navigate, logikaId],
  );

  return (
    <Tabs>
      <Tab title="Дані для текстової частини" eventKey="textData">
        <Container fluid>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.budget.label}
                value={data[md.columns.budget.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.budget.name]: value,
                })}
                modelType="cat"
                modelName="budget"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.FI.label}
                value={data[md.columns.FI.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.FI.name]: value,
                })}
                modelType="cat"
                modelName="csu"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`proj-${data.id}`}
                label={md.columns.proj.label}
                value={data[md.columns.proj.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.proj.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`isFirst-${data.id}`}
                label={md.columns.isFirst.label}
                value={data[md.columns.isFirst.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.isFirst.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={md.columns.name.label}
                value={data[md.columns.name.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.name.name]: value,
                })}
                maxLength={125}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>Типова форма рішення</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.DateInput
                label={md.columns.DATEDOC.label}
                value={data[md.columns.DATEDOC.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DATEDOC.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.StringInput
                label={md.columns.NUMDOC.label}
                value={data[md.columns.NUMDOC.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.NUMDOC.name]: value,
                })}
                maxLength={80}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>Про місцевий бюджет</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={md.columns.KOATUUNAIM.label}
                value={data[md.columns.KOATUUNAIM.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.KOATUUNAIM.name]: value,
                })}
                maxLength={100}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.YearInput
                label={md.columns.PY.label}
                value={data[md.columns.PY.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PY.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>рік</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={md.columns.KLB.label}
                value={data[md.columns.KLB.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.KLB.name]: value,
                })}
                maxLength={11}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label={`Керуючись Бюджетним кодексом України, Законом України "Про місцеве самоврядування", ${md.columns.NAME_ORGV.label}`}
                value={data[md.columns.NAME_ORGV.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.NAME_ORGV.name]: value,
                })}
                maxLength={100}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>рада вирішила:</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                1. Визначити
                {data[md.columns.PY.name]}
                {' '}
                рік
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>доходи місцевого бюджету у сумі</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1001.name]}
                precision={2}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1001.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень, у тому числі доходи загального фонду місцевого</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <StyledLabel>бюджету</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1002.name]}
                precision={2}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1002.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col xs={3}>
              <StyledLabel>
                гривень, у тому числі доходи спеціального фонду місцевого бюджету
              </StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1003.name]}
                precision={2}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1003.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col xs={1}>
              <StyledLabel>гривень</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>згідно з додатком 1 до цього рішення</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION1_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION1_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>видатки місцевого бюджету у сумі</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1004.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1004.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень, у тому числі видатки загального фонду</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <StyledLabel>місцевого бюджету </StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1005.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1005.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col xs={3}>
              <StyledLabel>гривень та видатки спеціального фонду місцевого бюджету </StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1006.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1006.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col xs={1}>
              <StyledLabel>гривень</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION1_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION1_2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>повернення кредитів до місцевого бюджету у сумі</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1007.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1007.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень, у тому числі повернення</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>кредитів до загального фонду місцевого бюджету</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1008.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1008.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень та повернення кредитів до</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>кредитів до спеціального фонду місцевого бюджету</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1009.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1009.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION1_3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION1_3.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>надання кредитів з місцевого бюджету у сумі</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1010.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1010.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень, у тому числі надання кредитів із</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>загального фонду місцевого бюджету</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1011.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1011.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                гривень та надання кредитів із спеціального фонду місцевого бюджету
              </StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1012.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1012.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION1_4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION1_4.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>профіцит за </StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.A1013.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1013.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>фондом місцевого бюджету у сумі</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1014.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1014.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень згідно з додатком 2 до цього рішення</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION1_5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION1_5.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>дефіцит за </StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.A1015.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1015.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>фондом місцевого бюджету у сумі</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1016.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1016.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень згідно з додатком 2 до цього рішення</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION1_6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION1_6.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                оборотний залишок бюджетних коштів місцевого бюджету у розмірі
              </StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1017.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1017.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень, що</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>становить</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1018.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1018.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>
                відсотків видатків загального фонду місцевого бюджету, визначених цим пунктом;
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION1_7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION1_7.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <StyledLabel>резервний фонд місцевого бюджету у розмірі</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1019.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1019.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень, що становить</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A1020.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A1020.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>відсотків</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                видатків загального фонду місцевого бюджету, визначених цим пунктом.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION1_8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION1_8.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                2. Затвердити бюджетні призначення головним розпорядникам коштів місцевого бюджету
                на
                {data[md.columns.PY.name]}
                {' '}
                рік
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                у розрізі відповідальних виконавців за бюджетними програмами згідно з додатками 3, 4
                до цього рішення.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION2_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                3. Затвердити на
                {data[md.columns.PY.name]}
                {' '}
                рік міжбюджетні трансферти згідно з додатком 5 до
                цього рішення.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION3_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION3_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                Зазначити положення щодо делегування місцевій державній адміністрації повноважень
                щодо розподілу та перерозподілу обсягів трансфертів з державного бюджету
                місцевим бюджетам у період між сесіями відповідної місцевої ради
                відповідно до частини сьомої статті 108 Бюджетного кодексу України
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION3_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION3_2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                Зазначити положення щодо затвердження розподілу між місцевими бюджетами додаткової
                дотації на здійснення переданих з державного бюджету видатків з утримання закладів
                освіти та охорони здоров&apos;я відповідно до визначеного обласною державною
                адміністрацією порядку з урахуванням частини п&apos;ятої статті 103 Бюджетного
                кодексу України.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION3_3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION3_3.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                4. Затвердити на
                {data[md.columns.PY.name]}
                {' '}
                рік розподіл коштів бюджету розвитку на здійснення
                заходів на
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                будівництво, реконструкцію і реставрацію об&apos;єктів виробничої,
                комунікаційної та соціальної інфраструктури
                за об&apos;єктами згідно з додатком 6 до цього рішення.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION4_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION4_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                5. Затвердити розподіл витрат місцевого бюджету на реалізацію місцевих/регіональних
                програм у сумі
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A5001.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A5001.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень згідно з додатком 7 до цього рішення.</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION5_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION5_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <StyledLabel>*/}
          {/*      6. Затвердити перелік кредитів (позик), що залучаються місцевою радою*/}
          {/*      до спеціального фонду місцевого бюджету у*/}
          {/*      {data[md.columns.PY.name]}*/}
          {/*      {' '}*/}
          {/*      році від міжнародних фінансових організацій для реалізації*/}
          {/*      інвестиційних проектів згідно з додатком 8 до цього рішення.*/}
          {/*    </StyledLabel>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <EditorControls.TextInput*/}
          {/*      value={data[md.columns.DESCRIPTION6_1.name]}*/}
          {/*      onChange={(e, value) => actions.onChange({*/}
          {/*        [md.columns.DESCRIPTION6_1.name]: value,*/}
          {/*      })}*/}
          {/*      readOnly={readOnly}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col>
              <StyledLabel>
                6. Установити, що у загальному фонді місцевого бюджету
                на
                {data[md.columns.PY.name]}
                {' '}
                рік
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION7_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION7_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                1) до доходів загального фонду місцевих бюджетів належать доходи, визначені
                статтями
              </StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ARTICLE7_1_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ARTICLE7_1_1.name]: value,
                })}
                maxLength={80}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                Бюджетного кодексу України, та трансферти, визначені статтями
              </StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ARTICLE7_1_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ARTICLE7_1_2.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>Бюджетного кодексу</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                України (крім субвенцій, визначених статтею частиною першою статті
                71 Бюджетного кодексу України) а також такі надходження відповідно
                до Закону України &quot;Про Державний бюджет України
                {data[md.columns.PY.name]}
                {' '}
                рік
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.RECEIPTS1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.RECEIPTS1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label="2) джерелами формування у частині фінансування є надходження, визначені пунктом"
                value={data[md.columns.POINT7_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.POINT7_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>частини</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.PART7_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PART7_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>статті</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ARTICLE7_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ARTICLE7_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>Бюджетного кодексу України;</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION7_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION7_2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.RECEIPTS2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.RECEIPTS2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>3) джерелами формування у частині кредитування є:</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION7_3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION7_3.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.RECEIPTS3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.RECEIPTS3.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                7. Установити, що джерелами формування спеціального фонду місцевого бюджету
                на
                {data[md.columns.PY.name]}
                {' '}
                рік
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>1) у частині доходів є надходження, визначені статтями</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ARTICLE8_1_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ARTICLE8_1_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>Бюдджетного кодексу України, а</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                також такі надходження відповідно до Закону України &quot;Про Державний бюджет
                України
                {data[md.columns.PY.name]}
                {' '}
                рік
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION8_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION8_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.RECEIPTS4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.RECEIPTS4.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>2) у частині фінансування є надходження, визначені пунктом</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.POINT8_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.POINT8_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>частини</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.PART8_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PART8_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>статті</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ARTICLE8_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ARTICLE8_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>Бюджетного кодексу України ;</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION8_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION8_2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.RECEIPTS5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.RECEIPTS5.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>3) у частині кредитування є надходження, визначені пунктом</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.POINT8_3_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.POINT8_3_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>частини</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.PART8_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PART8_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>статті</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ARTICLE8_3_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ARTICLE8_3.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>Бюджетного кодексу України ;</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION8_3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION8_3.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.RECEIPTS6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.RECEIPTS6.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                8. Установити, що у
                {data[md.columns.PY.name]}
                {' '}
                році кошти, отримані до спеціального фонду
                місцевого бюджету згідно з відповідними пунктами
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>частини</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.PART9_1_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PART9_1_1.name]: value,
                })}
                maxLength={80}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>статті</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ARTICLE9_1_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ARTICLE9_1_1.name]: value,
                })}
                maxLength={80}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                label="Бюджетного кодексу України, спрямовуються на реалізацію заходів, визначених
                частиною"
                value={data[md.columns.PART9_1_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PART9_1_2.name]: value,
                })}
                maxLength={80}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>статті</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ARTICLE9_1_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ARTICLE9_1_2.name]: value,
                })}
                maxLength={80}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                Бюджетного кодексу України, а кошти, отримані до спеціального фонду згідно
                з відповідними підпунктами абзацу
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.PARAGRAPH9_1_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PARAGRAPH9_1_1.name]: value,
                })}
                maxLength={80}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                пункту 8 цього рішення, спрямовуються відповідно на:
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DECISION9_1_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DECISION9_1_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>(за рахунок джерел, визначених підпунктом</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.SUBPARAGRAPH9_1_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.SUBPARAGRAPH9_1_2.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>абзацу</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.PARAGRAPH9_1_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PARAGRAPH9_1_2.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>пункту 8 цього рішення);</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DECISION9_1_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DECISION9_1_2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>(за рахунок джерел, визначених підпунктом</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.SUBPARAGRAPH9_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.SUBPARAGRAPH9_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>абзацу</StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.PARAGRAPH9_2_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.PARAGRAPH9_2_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>пункту 8 цього рішення);</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION9_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION9_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                9. Визначити  у
                {data[md.columns.PY.name]}
                {' '}
                граничний обсяг надання місцевих гарантій у сумі
              </StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A10003.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A10003.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                на 31 грудня
                {data[md.columns.PY.name]}
                {' '}
                року граничний обсяг місцевого боргу у сумі
              </StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A10001.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A10001.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень та граничний обсяг гарантованого місцевою радою боргу у сумі</StyledLabel>
            </Col>
            <Col>
              <EditorControls.NumberInput
                value={data[md.columns.A10002.name]}
                precision={2}
                maxLength={14}
                onChange={(e, value) => actions.onChange({
                  [md.columns.A10002.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>гривень</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                10. Зазначити про необхідність надання суб’єктами господарювання, щодо яких приймається рішення
                про надання кредитів (позик), залучених Автономною Республікою Крим, обласною радою, міською,
                селищною чи сільською територіальною громадою, або місцевих гарантій, майнового або іншого
                забезпечення виконання зобов’язань та сплати до місцевого бюджету плати за їх отримання, або
                про звільнення від такої необхідності
              </StyledLabel>
            </Col>
          {/*  <Col>*/}
          {/*    <EditorControls.NumberInput*/}
          {/*      value={data[md.columns.A10003.name]}*/}
          {/*      precision={2}*/}
          {/*      maxLength={14}*/}
          {/*      onChange={(e, value) => actions.onChange({*/}
          {/*        [md.columns.A10003.name]: value,*/}
          {/*      })}*/}
          {/*      readOnly={readOnly}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*  <Col>*/}
          {/*    <StyledLabel>гривень можуть надаватись:</StyledLabel>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <StyledLabel>*/}
          {/*      1) для забезпечення повного або часткового виконання боргових зобов&apos;язань*/}
          {/*      суб&apos;єктів господарювання - резидентів України, що належать до комунального*/}
          {/*      сектору економіки, розташовані на відповідній території та здійснюють на цій*/}
          {/*      території реалізацію інвестиційних проектів, метою яких є розвиток комунальної*/}
          {/*      інфраструктури або впровадження ресурсозберігаючих технологій;*/}
          {/*    </StyledLabel>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <EditorControls.TextInput*/}
          {/*      value={data[md.columns.DESCRIPTION11_1.name]}*/}
          {/*      onChange={(e, value) => actions.onChange({*/}
          {/*        [md.columns.DESCRIPTION11_1.name]: value,*/}
          {/*      })}*/}
          {/*      readOnly={readOnly}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <StyledLabel>*/}
          {/*      2) для реалізації інвестиційних проектів згідно з принципами,*/}
          {/*      умовами та процедурами, визначеними на підставі міжнародних договорів.*/}
          {/*    </StyledLabel>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <EditorControls.TextInput*/}
          {/*      value={data[md.columns.DESCRIPTION11_2.name]}*/}
          {/*      onChange={(e, value) => actions.onChange({*/}
          {/*        [md.columns.DESCRIPTION11_2.name]: value,*/}
          {/*      })}*/}
          {/*      readOnly={readOnly}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <StyledLabel>*/}
          {/*      Зазначити умови надання місцевих гарантій та повноваження на здійснення правочину*/}
          {/*      щодо надання місцевих гарантій та укладання договору про погашення заборгованості*/}
          {/*      суб&apos;єкта господарювання перед Автономною Республікою*/}
          {/*    </StyledLabel>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <EditorControls.TextInput*/}
          {/*      value={data[md.columns.DESCRIPTION11_3.name]}*/}
          {/*      onChange={(e, value) => actions.onChange({*/}
          {/*        [md.columns.DESCRIPTION11_3.name]: value,*/}
          {/*      })}*/}
          {/*      readOnly={readOnly}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <StyledLabel>*/}
          {/*      Зазначити майнове забезпечення суб&apos;єктів господарювання, його розмір та вид*/}
          {/*      у разі прийняття рішення про надання кредитів (позик), залучених Автономною*/}
          {/*      Республікою Крим чи територіальною громадою міста або під місцеві гарантії,*/}
          {/*      суб&apos;єктам господарювання, у віданні яких є майно комунальної власності.*/}
          {/*    </StyledLabel>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <EditorControls.TextInput*/}
          {/*      value={data[md.columns.DESCRIPTION11_4.name]}*/}
          {/*      onChange={(e, value) => actions.onChange({*/}
          {/*        [md.columns.DESCRIPTION11_4.name]: value,*/}
          {/*      })}*/}
          {/*      readOnly={readOnly}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <StyledLabel>*/}
          {/*      Зазначити повноваження щодо здійснення платежів, пов&apos;язаних з виконанням*/}
          {/*      гарантійних зобов&apos;язань Автономної Республіки Крим чи територіальної*/}
          {/*      громади міста, згідно з відповідними договорами.*/}
          {/*    </StyledLabel>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <EditorControls.TextInput*/}
          {/*      value={data[md.columns.DESCRIPTION11_5.name]}*/}
          {/*      onChange={(e, value) => actions.onChange({*/}
          {/*        [md.columns.DESCRIPTION11_5.name]: value,*/}
          {/*      })}*/}
          {/*      readOnly={readOnly}*/}
          {/*    />*/}
          {/*  </Col>*/}
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                11. Визначити на
                {data[md.columns.PY.name]}
                {' '}
                рік відповідно до статті 55 Бюджетного кодексу
                України захищеними видатками місцевого бюджету видатки загального фонду на:
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION12_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION12_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                12. Зазначити повноваження щодо здійснення в межах поточного бюджетного періоду на
                конкурсних засадах розміщення тимчасово вільних коштів місцевих бюджетів
                на депозитах або шляхом придбання цінних паперів, емітованих Автономною Республікою
                Крим, відповідною міською радою, з подальшим поверненням таких коштів до кінця
                поточного бюджетного періоду, а також шляхом придбання державних цінних паперів
                відповідно до статті 16 Бюджетного кодексу України
                {' '}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION13_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION13_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                13. Зазначити повноваження щодо здійснення позик на покриття тимчасових касових
                розривів місцевого бюджету, пов&apos;язаних із забезпеченням захищених видатків
                загального фонду, в межах поточного бюджетного періоду за рахунок коштів єдиного
                казначейського рахунку на договірних умовах без нарахування відсотків
                за користування цими коштами з обов&apos;язковим їх поверненням до кінця поточного
                бюджетного періоду у порядку, визначеному Кабінетом Міністрів України, відповідно
                до статей 43, 73 Бюджетного кодексу України.
                {' '}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION14_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION14_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                14. Зазначити положення щодо обслуговування бюджетних коштів у частині бюджету
                розвитку та власних надходжень бюджетних установ установами банків державного
                сектору у порядку, визначеному Кабінетом Міністрів України, відповідно до частини
                другої статті 78 Бюджетного кодексу України
                {' '}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION15_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION15_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                15. Зазначити положення щодо забезпечення головними розпорядниками коштів місцевого
                бюджету виконання норм Бюджетного кодексу України стосовно
                {' '}
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION16_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION16_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                1) затвердження паспортів бюджетних програм протягом 45 днів з дня набрання чинності
                цим рішенням
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION16_2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION16_2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                2) здійснення управління бюджетними коштами у межах встановлених їм бюджетних
                повноважень та оцінки ефективності бюджетних програм, забезпечуючи ефективне,
                результативне і цільове використання бюджетних коштів, організацію та координацію
                роботи розпорядників бюджетних коштів нижчого рівня та одержувачів бюджетних коштів
                у бюджетному процесі;
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION16_3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION16_3.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                3) здійснення контролю за своєчасним поверненням у повному обсязі до бюджету коштів,
                наданих за операціями з кредитування бюджету, а також кредитів (позик), отриманих
                Автономною Республікою Крим чи територіальною громадою міста, та коштів, наданих
                під місцеві гарантії;
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION16_4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION16_4.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                4) забезпечення доступності інформації про бюджет відповідно до законодавства,
                а саме: здійснення публічного представлення та публікації інформації про бюджет
                за бюджетними програмами та показниками, бюджетні призначення щодо яких визначені
                цим рішенням, відповідно до вимог та за формою, встановленими Міністерством фінансів
                України, до 15 березня
                {data[md.columns.PY.name]}
                {' '}
                року
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION16_5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION16_5.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                оприлюднення паспортів бюджетних програм у триденний строк з дня затвердження таких
                документів;
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION16_6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION16_6.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                5) взяття бюджетних зобов&apos;язань, довгострокових зобов&apos;язань
                за енергосервісом та здійснення витрат бюджету;
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION16_7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION16_7.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                6) забезпечення у повному обсязі проведення розрахунків за електричну та теплову
                енергію, водопостачання, водовідведення, природний газ та послуги зв&apos;язку,
                які споживаються бюджетними установами, та укладання договорів за кожним видом
                енергоносіїв у межах встановлених відповідним головним розпорядником бюджетних
                коштів обґрунтованих лімітів споживання тощо.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION16_8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION16_8.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                16. Зазначити положення щодо перерозподілу бюджетних призначень головного
                розпорядника бюджетних коштів.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION17.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION17.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                17. Зазначити положення щодо затвердження формули стосовно визначення обсягів
                міжбюджетних трансфертів згідно з додатком.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.ADDITION18_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADDITION18_1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                до цього рішення відповідно до пунктів 20, 20 1 розділу VI
                &quot;Прикінцеві та перехідні положення&quot; Бюджетного кодексу України .
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION18.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION18.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                18. Зазначити положення щодо здійснення з 01 січня
                {data[md.columns.PY.name]}
                {' '}
                року року відповідно
                до розмежування
                видатків між бюджетами, визначеного Бюджетним кодексом України, з бюджету
                об&apos;єднаної територіальної громади, утвореної згідно із Законом України
                &quot;Про добровільне об&apos;єднання територіальних громад&quot; та перспективним
                планом формування територій громад, а також визнаної Кабінетом Міністрів України
                спроможною, видатків на забезпечення діяльності бюджетних установ, визначених
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                у додатку до цього рішення, з урахуванням вимог Закону України &quot;Про
                добровільне об&apos;єднання територіальних громад&quot;.
              </StyledLabel>
            </Col>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.ADDITION19_1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADDITION19_1.name]: value,
                })}
                maxLength={20}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION19.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION19.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                19. Зазначити положення щодо набрання чинності цим рішенням.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION20.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION20.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                20. Зазначити положення, що додатки до цього рішення є його невід&apos;ємною
                частиною.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION21.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION21.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                21. Зазначити положення щодо оприлюднення цього рішення в десятиденний строк з дня
                його прийняття відповідно до частини четвертої статті 28 Бюджетного кодексу України.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION22.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION22.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                22. Зазначити інші положення, що регламентують процес виконання місцевого бюджету.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION23.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION23.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>
                24. Зазначити положення щодо покладення контролю за виконанням цього рішення.
              </StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                value={data[md.columns.DESCRIPTION24.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.DESCRIPTION24.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.NAMEOFFICE.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.NAMEOFFICE.name]: value,
                })}
                maxLength={80}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>керівник органу місцевого самоврядування</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.StringInput
                value={data[md.columns.HBOSS.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.HBOSS.name]: value,
                })}
                maxLength={100}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <StyledLabel>ініціали, прізвище</StyledLabel>
            </Col>
          </Row>
        </Container>
      </Tab>
      <Tab title="Додатки до рішення" eventKey="adds">
        <Container fluid>
          <Row>
            <Button
              onClick={() => {
                actions.onSR('CreateDispatchDoc');
              }}
              title="Надіслати до LOGICA"
            >
              Надіслати до LOGICA
            </Button>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.signVariant.label}
                value={data[md.columns.signVariant.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.signVariant.name]: value,
                })}
                filter={[
                  { fieldName: 'Владелец', value: data[md.columns.FI.name] }]}
                modelType="cat"
                modelName="signatureVariants"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledLabel>Підписи для відправки в Logica</StyledLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.boss.label}
                value={data[md.columns.boss.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.boss.name]: value,
                })}
                modelType="cat"
                modelName="users"
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <EditorControls.ItemPicker
                label={md.columns.fin.label}
                value={data[md.columns.fin.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.fin.name]: value,
                })}
                modelType="cat"
                modelName="users"
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`noADD1-${data.id}`}
                label={md.columns.noADD1.label}
                value={data[md.columns.noADD1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.noADD1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.ADD1.label}
                value={data[md.columns.ADD1.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADD1.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`noADD2-${data.id}`}
                label={md.columns.noADD2.label}
                value={data[md.columns.noADD2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.noADD2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.ADD2.label}
                value={data[md.columns.ADD2.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADD2.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`noADD3-${data.id}`}
                label={md.columns.noADD3.label}
                value={data[md.columns.noADD3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.noADD3.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.ADD3.label}
                value={data[md.columns.ADD3.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADD3.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`noADD4-${data.id}`}
                label={md.columns.noADD4.label}
                value={data[md.columns.noADD4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.noADD4.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.ADD4.label}
                value={data[md.columns.ADD4.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADD4.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`noADD5-${data.id}`}
                label={md.columns.noADD5.label}
                value={data[md.columns.noADD5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.noADD5.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.ADD5.label}
                value={data[md.columns.ADD5.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADD5.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`noADD6-${data.id}`}
                label={md.columns.noADD6.label}
                value={data[md.columns.noADD6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.noADD6.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.ADD6.label}
                value={data[md.columns.ADD6.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADD6.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`noADD7-${data.id}`}
                label={md.columns.noADD7.label}
                value={data[md.columns.noADD7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.noADD7.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.ADD7.label}
                value={data[md.columns.ADD7.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADD7.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.CheckboxInput
                controlId={`noADD8-${data.id}`}
                label={md.columns.noADD8.label}
                value={data[md.columns.noADD8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.noADD8.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorControls.TextInput
                label={md.columns.ADD8.label}
                value={data[md.columns.ADD8.name]}
                onChange={(e, value) => actions.onChange({
                  [md.columns.ADD8.name]: value,
                })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          {/* {[...Array(7).keys()].map((_, m) => ( */}
          {/*  <Row> */}
          {/*    <Col> */}
          {/*      <EditorControls.TextInput */}
          {/*        label={`Додаток ${m + 1}`} */}
          {/*        value={data[md.columns[`ADD${m + 1}`].name]} */}
          {/*        onChange={(e, value) => actions.onChange({ */}
          {/*          [md.columns[`ADD${m + 1}`].name]: value, */}
          {/*        })} */}
          {/*        readOnly={readOnly} */}
          {/*      /> */}
          {/*    </Col> */}
          {/*  </Row> */}
          {/* ))} */}
        </Container>
      </Tab>
    </Tabs>
  );
}

ResolutionSession.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ResolutionSession;
