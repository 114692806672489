import PropTypes from 'prop-types';
import { getErrorsPropTypes, getFieldsPropTypes } from '../../../../../basicEditor';
import { foreignPropType } from '../../../../../../constants/backend/propTypes';

export const dataPropTypes = {
  budget: foreignPropType,
  sum: PropTypes.number,
  sumT: PropTypes.number,
  sumRasp: PropTypes.number,
  sumWork: PropTypes.number,
  prWork: PropTypes.number,
  sumCom: PropTypes.number,
  prCom: PropTypes.number,
  sumMed: PropTypes.number,
  prMed: PropTypes.number,
  sumProd: PropTypes.number,
  prProd: PropTypes.number,
  sumP: PropTypes.number,
  prP: PropTypes.number,
  sumW: PropTypes.number,
  prW: PropTypes.number,
  sumOther: PropTypes.number,
  prOther: PropTypes.number,
  sumRaspSubv: PropTypes.number,
  sumUnused: PropTypes.number,
  prRaspAll: PropTypes.number,
  prRasp: PropTypes.number,
  sumSoc: PropTypes.number,
  prSoc: PropTypes.number,
  sumReabil: PropTypes.number,
  prReabil: PropTypes.number,
  sumTer: PropTypes.number,
  prTer: PropTypes.number,
  sumMob: PropTypes.number,
  prMob: PropTypes.number,
  sumSec: PropTypes.number,
  prSec: PropTypes.number,
  sumBuild: PropTypes.number,
  prBuild: PropTypes.number,
  sumFort: PropTypes.number,
  prFort: PropTypes.number,
  sumDebt: PropTypes.number,
  prDebt: PropTypes.number,
  sumGarant: PropTypes.number,
  prGarant: PropTypes.number,
  sumAdd: PropTypes.number,
  prAdd: PropTypes.number,
  sumZF: PropTypes.number,
  prZF: PropTypes.number,
  sumReturn: PropTypes.number,
};

export const errorsPropTypes = PropTypes.shape(getErrorsPropTypes(dataPropTypes));

export const fieldsPropTypes = PropTypes.shape(getFieldsPropTypes(dataPropTypes));
