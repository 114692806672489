import React, {
  useCallback, forwardRef, useState, useMemo, useRef, useEffect,
} from 'react';
import {
  Accordion,
  Alert, Container,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import styled from 'styled-components';
import { Loader } from '../../../../components/bootStrap';
// eslint-disable-next-line import/no-cycle
import PrintCommandPanel from './printCommandPanel';
import IconAlert from '../../../../components/blanks/common/IconAlert';
import { metaBackends } from '../../../../meta';
import { PRINT_SCALES, STD_PRINT_PREV_FONT_SIZE } from '../../../../constants/pageLayouts';

const PreviewPage = styled.div.attrs(({ fontSize }) => ({
  style: {
    fontSize: `${fontSize}px`,
  },
}))``;

const PrintFormContainer = forwardRef(({
  loading, err, settings, quickSettings, actions, pageLayout,
}, ref) => {
  const [currentScale, setCurrentScale] = useState('100%');
  const [settingsOpened, setSettinsOpened] = useState(false);
  const onToggleSettings = useCallback(
    () => setSettinsOpened((o) => !o),
    [],
  );
  const accActiveKey = settingsOpened ? 'settings' : 'result';
  const navigate = useNavigate();
  const clickHandler = useCallback(
    (e) => {
      if (e.target.tagName === 'A') {
        e.preventDefault();
        e.stopPropagation();
        const oldUrl = e.target.getAttribute('href') || '';
        const res = /^(\/api\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/)(\d+\/)$/.exec(oldUrl);
        if (res.length === 3) {
          const md = metaBackends[res[1]];
          if (md) {
            const url = `${md.frontendURL}${res[2]}`;
            navigate(url);
          } else {
            console.error(`Cannot resolve url ${oldUrl}!`);
          }
        }
      }
    },
    [navigate],
  );

  const oldFontSize = useRef(null);

  const fontSize = useMemo(
    () => {
      const cFS = PRINT_SCALES
        .filter((s) => s.label === currentScale)
        .reduce((R, r) => r.value, null);
      if (cFS) return cFS;
      if (pageLayout.value && ref.current && oldFontSize.current) {
        const prevCont = ref.current.parentNode;
        const cont = ref.current.parentNode.parentNode;
        const ratio = (cont.getBoundingClientRect().width - 24)
          / prevCont.getBoundingClientRect().width;
        return oldFontSize.current * ratio;
      }
      return STD_PRINT_PREV_FONT_SIZE;
    },
    [currentScale, pageLayout.value, ref],
  );

  useEffect(() => {
    if (pageLayout.value) setCurrentScale('100%');
  }, [pageLayout.value]);

  oldFontSize.current = fontSize;

  return (
    <Container fluid className="flex-fill overflow-hidden d-flex flex-column h-100">
      {loading && (
        <Loader text="Завантаження" />
      )}
      {err && (
        <IconAlert variant="danger">
          <Alert.Heading>Не вдалося сформувати друковану форму</Alert.Heading>
          {Array.isArray(err) ? (
            <ul className="list-unstyled">
              {err.map((er, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>{er}</li>
              ))}
            </ul>
          ) : err}
        </IconAlert>
      )}
      {quickSettings && (
        <div className="border rounded p-3">
          {quickSettings}
        </div>
      )}
      <div className="mt-2">
        <PrintCommandPanel
          onPrint={actions.onPrint}
          onGenerateReport={actions.onGenerate}
          onGenerateXLSX={actions.onGenerateXLSX}
          onGeneratePDF={actions.onGeneratePDF}
          onGenerateHTML={actions.onGenerateHTML}
          onToggleSettings={onToggleSettings}
          pageLayout={pageLayout}
          pageLayoutValues={pageLayout.field}
          settingsActive={settingsOpened}
          showSettings={!!settings}
          currentScale={currentScale}
          setCurrentScale={setCurrentScale}
        />
      </div>
      <Accordion activeKey={accActiveKey} className="flex-fill overflow-hidden">
        {/* Зробила так бо обрізався дропдаун в налаштуваннях */}
        {/* <Accordion.Collapse eventKey="result"> */}
        {/*  <div className="border rounded p-2"> */}
        {/*    <Result ref={ref} className="scrollbar rounded p-6" /> */}
        {/*  </div> */}
        {/* </Accordion.Collapse> */}
        <Accordion.Collapse eventKey="settings" className="h-100">
          <div className="border rounded p-2 bg-white scrollbar h-100 d-flex flex-column overflow-visible">
            {settings}
          </div>
        </Accordion.Collapse>
        <div className="border rounded p-2 scrollbar h-100 text-black bg-white">
          <PreviewPage className={classNames('preview a4', pageLayout.value.toLowerCase())} fontSize={fontSize}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-static-element-interactions */}
            <div ref={ref} className="p-6" onClick={clickHandler} />
          </PreviewPage>
        </div>
      </Accordion>
    </Container>
  );
});

PrintFormContainer.propTypes = {
  loading: PropTypes.bool,
  err: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  settings: PropTypes.element,
  quickSettings: PropTypes.element,
  actions: PropTypes.shape({
    onGenerate: PropTypes.func.isRequired,
    onGeneratePDF: PropTypes.func,
    onGenerateHTML: PropTypes.func,
    onGenerateXLSX: PropTypes.func,
    onPrint: PropTypes.func.isRequired,
  }).isRequired,
  pageLayout: PropTypes.shape({
    value: PropTypes.string,
    field: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        display_label: PropTypes.string,
      })),
    }),
    onToggle: PropTypes.func,
  }).isRequired,
};

PrintFormContainer.defaultProps = {
  loading: false,
  err: null,
  settings: null,
  quickSettings: null,
};

export default PrintFormContainer;
