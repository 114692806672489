import React, { useEffect, useMemo, useRef } from 'react';
import {
  Col, Container, Nav, Row, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { usePrintFormEditor } from '../../printForms/hook/usePrintFormEditor';
import { EditorControls, editorHooks } from '../../basicEditor/editorControls';
import PrintFormContainer from '../../printForms/_common/printformContainer';
import { useFilters } from '../../basicReport/hooks/filters';
import FiltersEditor from '../../basicReport/ReportSettingsEditor/filters';
import instance from '../../../meta/rep/allocationYear';
import ReportHeader from '../_common/reportHeader';

const URL = instance.backendURL;

function Rep() {
  const {
    loading, err, data, fields, actions, fieldErrors, resultRef,
    pageLayout,
  } = usePrintFormEditor({ backendURL: URL, repr: 'Річний розпис бюджету' });
  const bdateProps = editorHooks.useDateInputProps('bdate', data, fields, fieldErrors, actions.onChange);
  const edateProps = editorHooks.useDateInputProps('edate', data, fields, fieldErrors, actions.onChange);
  const signvariantProps = editorHooks.useItemInputProps('signvariant', data, fields, fieldErrors, actions.onChange);
  const reportTypeProps = editorHooks.useSelectorInputProps('report_type', data, fields, fieldErrors, actions.onChange);
  const moneyKindProps = editorHooks.useSelectorInputProps('money_kind', data, fields, fieldErrors, actions.onChange);
  const planKindProps = editorHooks.useSelectorInputProps('plan_kind', data, fields, fieldErrors, actions.onChange);
  const authorityProps = editorHooks.useItemInputProps('authority', data, fields, fieldErrors, actions.onChange);
  const inThousandsProps = editorHooks.useCheckboxInputProps('in_thousands', data, fields, fieldErrors, actions.onChange);
  const allKekvProps = editorHooks.useCheckboxInputProps('all_kekv', data, fields, fieldErrors, actions.onChange);
  const kekvProps = editorHooks.useCheckboxInputProps('kekv', data, fields, fieldErrors, actions.onChange);
  const groupKekvProps = editorHooks.useCheckboxInputProps('group_kekv', data, fields, fieldErrors, actions.onChange);
  const shortKekvProps = editorHooks.useCheckboxInputProps('short_kekv', data, fields, fieldErrors, actions.onChange);
  const vt4brProps = editorHooks.useCheckboxInputProps('vt4br', data, fields, fieldErrors, actions.onChange);

  const useKekv = useMemo(
    () => data.kekv,
    [data.kekv],
  );

  const reportData = useMemo(() => ({ options: {} }), []);
  const {
    filters, availableFilters, displayFilters, filtersHandlers, loadFilters,
  } = useFilters(reportData, data, 'report_meta');
  const { onChange } = actions;
  const filtersLoaded = useRef(false);
  useEffect(
    () => {
      if (filtersLoaded.current) onChange(() => ({ filters }));
    },
    [filters, onChange],
  );
  useEffect(
    () => {
      if (!filtersLoaded.current && data.filters) {
        loadFilters(data.filters);
        filtersLoaded.current = true;
      }
    },
    [data.filters, filters, filters.length, loadFilters],
  );
  return (
    <ReportHeader title={instance.name}>
      <PrintFormContainer
        loading={loading}
        err={err}
        actions={actions}
        ref={resultRef}
        pageLayout={pageLayout}
        quickSettings={(
          <Row>
            <Col md={3}>
              <EditorControls.DateInput {...bdateProps} />
            </Col>
            <Col md={3}>
              <EditorControls.DateInput {...edateProps} />
            </Col>
            <Col>
              <EditorControls.ItemPicker {...signvariantProps} />
            </Col>
          </Row>
      )}
        settings={(
          <TabContainer defaultActiveKey="general">
            <Nav variant="tabs" navbar={false}>
              <Nav.Item>
                <Nav.Link eventKey="general">Налаштування</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="filters">Фільтрування</Nav.Link>
              </Nav.Item>
            </Nav>
            <TabContent className="flex-fill overflow-hidden">
              <TabPane eventKey="general" className="h-100 scrollbar">
                <Container fluid>
                  <Row>
                    <Col md={3}>
                      <EditorControls.SelectorInput {...reportTypeProps} />
                    </Col>
                    <Col md={3}>
                      <EditorControls.SelectorInput {...planKindProps} />
                    </Col>
                    <Col md={3}>
                      <EditorControls.ItemPicker {...authorityProps} />
                      <EditorControls.SelectorInput {...moneyKindProps} required />
                    </Col>
                    <Col md={3} className="border-left">
                      <EditorControls.CheckboxInput {...kekvProps} />
                      <EditorControls.CheckboxInput {...groupKekvProps} disabled={!useKekv} />
                      <EditorControls.CheckboxInput {...shortKekvProps} disabled={!useKekv} />
                      <EditorControls.CheckboxInput {...allKekvProps} disabled={!useKekv} />
                      <hr />
                      <EditorControls.CheckboxInput {...inThousandsProps} />
                      <EditorControls.CheckboxInput {...vt4brProps} />
                    </Col>
                  </Row>
                </Container>
              </TabPane>
              <TabPane eventKey="filters" className="h-100">
                <DndProvider backend={HTML5Backend}>
                  <FiltersEditor
                    filters={displayFilters}
                    filtersHandlers={filtersHandlers}
                    availableFilters={availableFilters}
                  />
                </DndProvider>
              </TabPane>
            </TabContent>
          </TabContainer>
      )}
      />
    </ReportHeader>
  );
}

export default Rep;
