import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  additional: { label: 'Додатковий додаток', name: 'Допонительно', visible: true },
  solutionNumber: {
    label: 'Номер рішення',
    name: 'НомерРешения',
  },
  solutionDate: {
    label: 'Дата рішення',
    name: 'ДатаРешения',
  },
  forceDate: {
    label: 'Дата набрання чинності рішення',
    name: 'ДатаЧинності',
  },
  dateFrom: {
    label: 'Дата з якої застосовуються ставки та пільги',
    name: 'ДатаЗ',
  },
  nname: {
    label: 'Орган місцевого самоврядуваня, що прийняв рішення',
    name: 'КАТОТТГ',
  },
  nameAto: {
    label: 'Адміністративно-територіальні одиниці, для яких встановлено ставки та пільги',
    name: 'КАТОТТГ_АТО',
  },
};

/**
 * @const
 */
const tables = {
  TABL_111: {
    name: 'Табл111',
    label: 'Табл 111',
    columns: {
      code: { label: 'Код ', name: 'Код' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      name: { label: 'Найменування ', name: 'КласЗемли' },
      rateLegalWithImpact: { label: 'Оцінку проведено, юр.ос. ', name: 'СтавкаЮр' },
      rateNaturalWithImpact: { label: 'Оцінку проведено, фіз.ос. ', name: 'СтавкаФиз' },
      rateLegalWithoutImpact: { label: 'Оцінку не проведено, юр.ос. ', name: 'СтавкаЮрБезОценки' },
      rateNaturalWithoutImpact: { label: 'Оцінку не проведено, фіз.ос. ', name: 'СтавкаФизБезОценки' },
    },
  },
  TABL_112: {
    name: 'Табл112',
    label: 'Табл 112',
    columns: {
      code: { label: 'Код ', name: 'Код' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      name: { label: 'Найменування ', name: 'КласЗемли' },
      rateLegalWithImpact: { label: 'Оцінку проведено, юр.ос. ', name: 'СтавкаЮр' },
      rateNaturalWithImpact: { label: 'Оцінку проведено, фіз.ос. ', name: 'СтавкаФиз' },
      rateLegalWithoutImpact: { label: 'Оцінку не проведено, юр.ос. ', name: 'СтавкаЮрБезОценки' },
      rateNaturalWithoutImpact: { label: 'Оцінку не проведено, фіз.ос. ', name: 'СтавкаФизБезОценки' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги Додаток 11',
  name: 'taxes11',
  backendName: 'НалогиИЛьготыДод11',
  columns,
  frontend: 'doc/taxes11',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
