import React, {
  useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Container, Row, Tab,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { DocumentBody, Footer } from '../../../../components/Containers/docs';
import useEditor from '../../../newEditor/hook/editor';
import meta from '../../../../meta';
import { EditorControls, editorHooks } from '../../../basicEditor/editorControls';
import EditorContainer from '../../../newEditor/editorContainer';
import { DocCommandPanel } from '../../../newEditor/commandPanels';
import { HidableContainer } from '../../../../components/Styled/Misc';
import { LabelTab, TabContainer } from '../../../basicEditor/tpController/styles';
import LevelDownRows2 from './tp/healthCareExpensesLevelDownRow';
import ApprovedInfo from '../../common/approvedInfo';
import LoadLevelDownButton from './loadLeveldown';
import SendToLogicaButton from '../../common/components/SendToLogicaButton';
import useEditorParams from '../../../newEditor/hook/params';
import HealthCareExpensesRowSet from './tp/rowSet';

function Editor({
  onSave, onClose, id,
}) {
  const editorParams = useEditorParams();
  const {
    data, actions, fields, fieldErrors, nonFieldErrors, options, isNew, changed, permissions,
    // eslint-disable-next-line no-unused-vars
    loading, systemErrors, headerReadOnlyFields, readOnlyFields, printForms, headerProps,
  } = useEditor({
    backendURL: meta.doc.healthCareExpenses.backendURL,
    id,
    onCloseCallBack: onClose,
    onSaveCallBack: onSave,
    ...editorParams,
  });

  const yearProps = editorHooks.useNumberInputProps('year', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const dateProps = editorHooks.useDateInputProps('doc_date', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const signVariantProps = editorHooks.useItemInputProps('authority_sign_variant', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const monthProps = editorHooks.useSelectorInputProps('month', data, fields, fieldErrors, actions.onChange, !permissions.canChange, headerReadOnlyFields);
  const dataRef = useRef();
  dataRef.current = data;

  const onRowsChange = useCallback((...r) => {
    actions.onChange(...r);
  }, [actions]);

  const onLoadHandler = useCallback(
    (documents) => {
      actions.onDraft(
        dataRef.current,
        {
          load_leveldown_health_care_expenses_documents: {
            documents,
          },
        },
      );
    },
    [actions],
  );

  const onFillHandler = useCallback(
    // eslint-disable-next-line no-unused-vars
    (documents) => {
      actions.onDraft(
        dataRef.current,
        {
          fill: true,
        },
      );
    },
    [actions],
  );

  return (
    <EditorContainer
      isNew={isNew}
      name={options.name}
      repr={data.repr}
      isLoading={loading}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      onClearNonFieldErrors={actions.onClearNonFieldErrors}
      onClearErrors={actions.onClearErrs}
      onClose={actions.onClose}
      changed={changed}
      CommandPanel={(
        <DocCommandPanel
          permissions={permissions}
          actions={actions}
          changed={changed}
          executed={data.executed}
          printForms={printForms}
          backendURL={meta.doc.healthCareExpenses.backendURL}
          id={id}
          repr={data.repr}
        >
          <SendToLogicaButton
            url={meta.doc.healthCareExpenses.backendURL}
            documnetId={data.id}
            disabled={!data.executed || changed}
          />
        </DocCommandPanel>
      )}
    >
      <DocumentBody>
        <HidableContainer>
          <Container fluid className="p-0">
            <Row>
              <Col>
                <EditorControls.DateInput {...dateProps} />
              </Col>
              <Col sm={2}>
                <EditorControls.SelectorInput {...monthProps} />
              </Col>
              <Col sm={2}>
                <EditorControls.YearInput {...yearProps} />
              </Col>
              <Col>
                <EditorControls.ItemPicker {...signVariantProps} />
              </Col>
            </Row>
            <ApprovedInfo
              approved={data.approved}
              approvedText={data.invoice}
              type="approved"
              url={`${meta.doc.healthCareExpenses.backendURL}${data.id}/`}
            />
            <LoadLevelDownButton
              authority={data.authority}
              docDate={data.doc_date}
              year={data.year}
              disabled={!permissions.canChange}
              onLoadHandler={onLoadHandler}
              id={data.id}
            />
            <Button
              variant="success"
              onClick={onFillHandler}
              disabled={loading}
              className="ml-2"
            >
              <FontAwesomeIcon icon={faFileImport} className="mr-2" />
              Заповнити
            </Button>
          </Container>
        </HidableContainer>
        <LabelTab defaultActiveKey="rowset">
          {fields && fields.healthcareexpensesrow_set && (
            <Tab eventKey="rowset" title={fields.healthcareexpensesrow_set.label}>
              <TabContainer>
                <HealthCareExpensesRowSet
                  fields={fields}
                  data={data}
                  onChange={onRowsChange}
                  errors={fieldErrors}
                  readOnlyFields={readOnlyFields}
                />
              </TabContainer>
            </Tab>
          )}
          {fields && fields.healthcareexpensesleveldownrow_set && (
          <Tab eventKey="docs" title={fields.healthcareexpensesleveldownrow_set.label}>
            <TabContainer>
              <LevelDownRows2
                data={data}
                actions={actions}
                readOnly={!permissions.canChange}
              />
            </TabContainer>
          </Tab>
          )}
        </LabelTab>
      </DocumentBody>
      <Footer
        created={data.created_at}
        updated={data.updated_at}
        readOnly={!permissions.canChange}
        budget={data.budget}
        authority={data.authority}
        author={data.author}
        onChange={actions.onChange}
        id={id}
        headerProps={headerProps}
      />
    </EditorContainer>

  );
}

Editor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

Editor.defaultProps = {
  onClose: null,
  onSave: null,
};
export default Editor;
