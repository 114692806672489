import React from "react";
import ListerFactory from '../../../newLister';
import { CatTableListerFactory } from '../../../newLister/tableComponent';
import { modelType, modelName } from '../def';


const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CatTableListerFactory());

function Lister() {

  return (
       <StdLister
            filter={[
            { fieldName: 'флАктивный', value: true },
          ]}
        />
  );
}



export default Lister;
