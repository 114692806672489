import React, {
  useContext,
} from 'react';
import {
  Button, Col, Row, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line import/no-cycle
import {
  EditorControls,
  EditorDateInput, EditorStingInput,
} from '../../basicEditor/editorControls';
import md from '../../../meta/doc/transferToLogica';
import { WinManagerContext } from '../../../providers';
import PageHeader from '../../../components/blanks/common/PageHeader';
import FalconCloseButton from '../../../components/blanks/common/CloseButton';
import { Loader } from '../../../components/bootStrap';
import useExportChangesToLogica from './hook';
import IconAlert from '../../../components/blanks/common/IconAlert';

const types = [
  { value: 1, display_name: 'Рік' },
  { value: 2, display_name: 'Квартал' },
  { value: 3, display_name: 'Місяць' },
];

function ExportChangesToLogicaEditor() {
  const { dellComponentFromWindowsManager } = useContext(WinManagerContext);
  const {
    createTransferDoc, loading, err, setErr, type, setType, sufNumber, setSufNumber,
    signVariant, setSignVariant, isSuperuser, authority, setAuthority,
    beginDate, setBeginDate, endDate, setEndDate,
  } = useExportChangesToLogica();

  return (
    <>
      <PageHeader
        title={md.name}
        // description=""
        className="mb-3"
      >
        <div className="position-absolute" style={{ top: '.5rem', right: '1rem' }}>
          <FalconCloseButton
            onClick={() => {
              dellComponentFromWindowsManager();
            }}
            title="Закрити"
          />
        </div>
      </PageHeader>
      {loading && <Loader />}
      {err && (
      <IconAlert dismissible variant="danger" onClose={() => setErr(null)}>
        {err}
      </IconAlert>
      )}
      <Card>
        <Card.Header>
          Параметри для заповнення документу
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <EditorControls.RadioInput
                value={type}
                values={types}
                onChange={(e, v) => setType(v)}
                className="d-flex gap-2"
              />
            </Col>
            {type !== 1 && (
            <Col>
              <EditorStingInput
                value={sufNumber}
                label={`Номер ${type === 2 ? 'квартала' : 'місяця'}`}
                onChange={(e, v) => setSufNumber(v)}
                required
              />
            </Col>
            )}
          </Row>
          <Row>
            <Col>
              {!isSuperuser && (
              <div className="flex-fill fst-italic d-flex align-items-center pt-3">
                <FontAwesomeIcon
                  className="me-1"
                  icon={faMapMarkerAlt}
                />
                {authority && authority.repr}
              </div>
              )}
              {isSuperuser && (
              <EditorControls.ItemPicker
                value={authority}
                onChange={(e, v) => setAuthority(v)}
                modelType="cat"
                label="Фінансовий орган"
                modelName="disposers"
                backend="/api/references/refdisposer/"
                required
              />
              )}
            </Col>
            <Col>
              <EditorControls.ItemPicker
                value={signVariant}
                onChange={(e, v) => setSignVariant(v)}
                modelType="cat"
                label="Підпис"
                modelName="authoritysignvariant"
                backend="/api/references/refauthoritysignvariant/"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorDateInput
                controlId="begin_date"
                label="Відібрати документи за період з"
                required
                value={beginDate}
                onChange={(e, v) => setBeginDate(v)}
              />
            </Col>
            <Col>
              <EditorDateInput
                controlId="end_date"
                label="по"
                required
                value={endDate}
                onChange={(e, v) => setEndDate(v)}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="falcon-success"
            onClick={createTransferDoc}
            disabled={!authority || !signVariant || (type > 1 && !sufNumber)}
            title="Створити документ відправки в LOGICA"
          >
            <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
            Створити документ відправки в LOGICA
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
}

ExportChangesToLogicaEditor.propTypes = {
};

export default ExportChangesToLogicaEditor;
