import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { fieldsPropTypes } from './propTypes';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

function Header({
  fields, activeCol, highlights, onToggleHighlight,
}) {
  return (
    <TableHeaderRow noGutters className="px-4 fs--2">
      <Col
        sm={2}
        className="px-1  d-flex flex-column justify-content-center"
      >
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'budget'}
          highlighted={highlights.includes('budget')}
          onToggleHighlight={() => onToggleHighlight('budget')}
        >
          {fields.budget.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sum'}
          highlighted={highlights.includes('sum')}
          onToggleHighlight={() => onToggleHighlight('sum')}
        >
          {fields.sum.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumT'}
          highlighted={highlights.includes('sumT')}
          onToggleHighlight={() => onToggleHighlight('sumT')}
        >
          {fields.sumT.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumRasp'}
          highlighted={highlights.includes('sumRasp')}
          onToggleHighlight={() => onToggleHighlight('sumRasp')}
        >
          {fields.sumRasp.label}
        </TableHeaderCell>
      </Col>
      <Col sm={2}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumWork'}
          highlighted={highlights.includes('sumWork')}
          onToggleHighlight={() => onToggleHighlight('sumWork')}
        >
          {fields.sumWork.label}
          {' '}
          {fields.prWork.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumCom'}
          highlighted={highlights.includes('sumCom')}
          onToggleHighlight={() => onToggleHighlight('sumCom')}
        >
          {fields.sumCom.label}
          {' '}
          {fields.prCom.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumMed'}
          highlighted={highlights.includes('sumMed')}
          onToggleHighlight={() => onToggleHighlight('sumMed')}
        >
          {fields.sumMed.label}
          {' '}
          {fields.prMed.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumProd'}
          highlighted={highlights.includes('sumProd')}
          onToggleHighlight={() => onToggleHighlight('sumProd')}
        >
          {fields.sumProd.label}
          {' '}
          {fields.prProd.label}
        </TableHeaderCell>
      </Col>
      <Col sm={2}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumSoc'}
          highlighted={highlights.includes('sumSoc')}
          onToggleHighlight={() => onToggleHighlight('sumSoc')}
        >
          {fields.sumSoc.label}
          {' '}
          {fields.prSoc.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumReabil'}
          highlighted={highlights.includes('sumReabil')}
          onToggleHighlight={() => onToggleHighlight('sumReabil')}
        >
          {fields.sumReabil.label}
          {' '}
          {fields.prReabil.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumTer'}
          highlighted={highlights.includes('sumTer')}
          onToggleHighlight={() => onToggleHighlight('sumTer')}
        >
          {fields.sumTer.label}
          {' '}
          {fields.prTer.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumMob'}
          highlighted={highlights.includes('sumMob')}
          onToggleHighlight={() => onToggleHighlight('sumMob')}
        >
          {fields.sumMob.label}
          {' '}
          {fields.prMob.label}
        </TableHeaderCell>
      </Col>
      <Col sm={2}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumSec'}
          highlighted={highlights.includes('sumSec')}
          onToggleHighlight={() => onToggleHighlight('sumSec')}
        >
          {fields.sumSec.label}
          {' '}
          {fields.prSec.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumBuild'}
          highlighted={highlights.includes('sumBuild')}
          onToggleHighlight={() => onToggleHighlight('sumBuild')}
        >
          {fields.sumBuild.label}
          {' '}
          {fields.prBuild.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumFort'}
          highlighted={highlights.includes('sumFort')}
          onToggleHighlight={() => onToggleHighlight('sumFort')}
        >
          {fields.sumFort.label}
          {' '}
          {fields.prFort.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumDebt'}
          highlighted={highlights.includes('sumDebt')}
          onToggleHighlight={() => onToggleHighlight('sumDebt')}
        >
          {fields.sumDebt.label}
          {' '}
          {fields.prDebt.label}
        </TableHeaderCell>
      </Col>
      <Col sm={2}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumGarant'}
          highlighted={highlights.includes('sumGarant')}
          onToggleHighlight={() => onToggleHighlight('sumGarant')}
        >
          {fields.sumGarant.label}
          {' '}
          {fields.prGarant.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumOther'}
          highlighted={highlights.includes('sumOther')}
          onToggleHighlight={() => onToggleHighlight('sumOther')}
        >
          {fields.sumOther.label}
          {' '}
          {fields.prOther.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumRaspSubv'}
          highlighted={highlights.includes('sumRaspSubv')}
          onToggleHighlight={() => onToggleHighlight('sumRaspSubv')}
        >
          {fields.sumRaspSubv.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumReturn'}
          highlighted={highlights.includes('sumReturn')}
          onToggleHighlight={() => onToggleHighlight('sumReturn')}
        >
          {fields.sumReturn.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumUnused'}
          highlighted={highlights.includes('sumUnused')}
          onToggleHighlight={() => onToggleHighlight('sumUnused')}
        >
          {fields.sumUnused.label}
        </TableHeaderCell>
      </Col>
      <Col sm={2}>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'prRaspAll'}
          highlighted={highlights.includes('prRaspAll')}
          onToggleHighlight={() => onToggleHighlight('prRaspAll')}
        >
          {fields.prRaspAll.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'prRasp'}
          highlighted={highlights.includes('prRasp')}
          onToggleHighlight={() => onToggleHighlight('prRasp')}
        >
          {fields.prRasp.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumAdd'}
          highlighted={highlights.includes('sumAdd')}
          onToggleHighlight={() => onToggleHighlight('sumAdd')}
        >
          {fields.sumAdd.label}
          {' '}
          {fields.prAdd.label}
        </TableHeaderCell>
        <TableHeaderCell
          className="text-center text-xl-left"
          active={activeCol === 'sumZF'}
          highlighted={highlights.includes('sumZF')}
          onToggleHighlight={() => onToggleHighlight('sumZF')}
        >
          {fields.sumZF.label}
          {' '}
          {fields.prZF.label}
        </TableHeaderCell>
      </Col>
    </TableHeaderRow>
  );
}

Header.propTypes = {
  fields: fieldsPropTypes.isRequired,
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlight: PropTypes.func.isRequired,
};

Header.defaultProps = {
  activeCol: null,
};

export default Header;
