import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import api from '../../../api/req';
import { downloadFileFromJSON } from '../../../api/utils';
import AppContext from '../../../providers/CiatAppContext';

const useExportToDBF = ({ currentItem, backendURL }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [budget, setBudget] = useState(null); // budgets
  const [loadVariant, setLoadVariant] = useState(1); // load_variant
  const [type, setType] = useState(1); // variant
  const [authority, setAuthority] = useState(null); // authority
  const [beginDate, setBeginDate] = useState(); // begin_date
  const [endDate, setEndDate] = useState(); // end_date
  const [docs, setDocs] = useState([]); // docs
  const [oneFile, setOneFile] = useState(false); // one_file
  const [noKDB, setNoKDB] = useState(true); // no_kdb
  const [natForm, setNatForm] = useState(false); // nat_form
  // const [asReference, setAsReference] = useState(false); // as_reference
  const [filenameZMINV, setFilenameZMINV] = useState(false); // filename_ZMINV
  const [assign, setAssign] = useState(2); // assign
  const [noYearSummIfZero, setNoYearSummIfZero] = useState(false); // no_year_summ_if_zero
  const [nom, setNom] = useState(''); // nom
  const [nr, setNr] = useState(0); // nr
  const [onlyYear, setOnlyYear] = useState(false); // only_year
  const [codeInName, setCodeInName] = useState(1); // code_in_name
  const [byMainDisposer, setByMainDisposer] = useState(false); // by_main_disposer
  const [noYearSumm, setNoYearSumm] = useState(false); // no_year_summ
  const [addF6, setAddF6] = useState(true); // add_f6
  const [addF7, setAddF7] = useState(false); // add_f7
  const [noArchive, setNoArchive] = useState(false); // no_archive
  const [isFO, setIsFO] = useState(true);
  const [activeDoc, setActiveDoc] = useState(null);
  const [fond2Or3, setFond2Or3] = useState(false);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const getInitial = useCallback((v) => {
    const loader = async () => {
      const r = await api.get(`/api/authorityplans/export/get_initials/?documents=${v[0]}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setIsFO(rData.findep);
        setAuthority(rData.authority);
        setBudget(rData.budget);
        setBeginDate(rData.begin_date);
        setEndDate(rData.end_date);
        setFond2Or3(rData.fond_3_or_2);
        setDocs(rData.docs.map((d) => ({
          id: d.id,
          repr: d.repr,
          use: true,
        })));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const getDocList = useCallback(
    () => {
      const loader = async () => {
        const params = `authority=${authority.id}&begin_date=${beginDate}
      &end_date=${endDate}&executed=${true}`;
        const r = await api.get(`${backendURL}?${params}`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      onLoadStart();
      loader()
        .then((rData) => {
          const newDocs = [...rData.results].map((doc) => ({
            id: doc.id,
            repr: doc.repr,
            use: true,
          }));
          setDocs(newDocs);
        })
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    },
    [auth, authority, backendURL, beginDate, endDate, onError, onLoadEnd, onLoadStart],
  );

  const onToggleDoc = useCallback(
    (docId) => setDocs(docs.map((d) => ({ ...d, use: d.id === docId ? !d.use : d.use }))),
    [docs],
  );

  useEffect(
    () => {
      getInitial(currentItem);
    },
    [currentItem, getInitial],
  );

  const selectedDocs = useMemo(
    () => docs.filter((d) => d.use)
      .map((d) => d.id),
    [docs],
  );

  const handleSubmit = useCallback(
    () => {
      const loader = async () => {
        const params = {
          budgets: budget.id,
          authority: authority.id,
          load_variant: loadVariant,
          variant: type,
          one_file: oneFile,
          no_kdb: noKDB,
          nat_form: natForm,
          // as_reference: asReference,
          filename_ZMINV: filenameZMINV,
          assign,
          no_year_summ_if_zero: noYearSummIfZero,
          nom,
          nr,
          only_year: onlyYear,
          code_in_name: codeInName,
          by_main_disposer: byMainDisposer,
          no_year_summ: noYearSumm,
          add_f6: addF6,
          add_f7: addF7,
          no_archive: noArchive,
          docs: selectedDocs.length ? selectedDocs : currentItem,
        };
        const r = await api.get('/api/authorityplans/export/', auth, params);
        if (!r.ok) {
          if (r.status === 400) {
            const d = await r.json();
            const message = Object.keys(d).reduce((R, k) => `${R}${k}: ${d[k].join(', ')}, `, '');
            throw new Error(message);
          }
          throw new Error(`${r.status} ${r.statusText}`);
        }
        return r.json();
      };
      onLoadStart();
      loader()
        .then((r) => {
          if (noArchive) {
            const files = r.file.map((f) => downloadFileFromJSON(f.file, f.filename));
            return files;
          }
          const filename = 'Arhive.zip';
          return downloadFileFromJSON(r.file, filename);
        })
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    },
    [addF6, addF7, assign, auth, authority, budget, byMainDisposer,
      codeInName, currentItem, filenameZMINV, loadVariant, natForm, noArchive, noKDB, noYearSumm,
      noYearSummIfZero, nom, nr, onError, onLoadEnd, onLoadStart, oneFile, onlyYear, selectedDocs,
      type],
  );
  return {
    loading,
    err,
    setErr,
    isFO,
    type,
    setType,
    loadVariant,
    setLoadVariant,
    noArchive,
    setNoArchive,
    oneFile,
    setOneFile,
    addF6,
    setAddF6,
    addF7,
    setAddF7,
    noKDB,
    setNoKDB,
    assign,
    setAssign,
    codeInName,
    setCodeInName,
    natForm,
    setNatForm,
    fond2Or3,
    noYearSummIfZero,
    setNoYearSummIfZero,
    onlyYear,
    setOnlyYear,
    noYearSumm,
    setNoYearSumm,
    byMainDisposer,
    setByMainDisposer,
    filenameZMINV,
    setFilenameZMINV,
    nom,
    setNom,
    nr,
    setNr,
    beginDate,
    setBeginDate,
    endDate,
    setEndDate,
    docs,
    setDocs,
    getDocList,
    handleSubmit,
    setActiveDoc,
    onToggleDoc,
    activeDoc,
  };
};

export default useExportToDBF;
