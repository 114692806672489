import { faAddressCard, faPortrait } from '@fortawesome/free-solid-svg-icons';

// eslint-disable-next-line import/prefer-default-export
export const PORTRAIT = 'PORTRAIT';
export const LANDSCAPE = 'LANDSCAPE';

export const pageLayouts = {
  [LANDSCAPE]: { label: 'ландшафтна', icon: faAddressCard },
  [PORTRAIT]: { label: 'портретна', icon: faPortrait },
};

export const STD_PRINT_PREV_FONT_SIZE = 16;
export const PRINT_SCALES = [
  { label: '50%', value: STD_PRINT_PREV_FONT_SIZE / 2 },
  { label: '100%', value: STD_PRINT_PREV_FONT_SIZE },
  { label: '150%', value: STD_PRINT_PREV_FONT_SIZE * 1.5 },
  { label: '200%', value: STD_PRINT_PREV_FONT_SIZE * 2 },
  { label: 'За шириною екрану', value: null },
];
