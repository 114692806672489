import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  refYoutube: {
    label: 'Посилання на навчальне відео youtube',
    name: 'refYoutube',
    visible: false,
    value: '',
  },
  author: { label: 'Автор', name: 'Автор', visible: true },
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  reportMonth: { label: 'Місяць за який подається звітність', name: 'МесяцОтчетности', visible: true },
  year: { label: 'Рiк', name: 'Год', visible: true },
  form2022: { label: 'Форма 2022', name: 'форма2022', visible: false },
  apprByRegion: { label: 'Затверджено областю', name: 'флУтвержденоОбл', visible: true },
  regionInvoice: { label: 'Квитанція(область)', name: 'КвитанцияОбл', visible: false },
};

/**
 * @const
 */
const tables = {
  form2: {
    name: 'Форма2',
    label: 'Додаток 4',
    columns: {
      budget: { label: 'Назва  бюджету', name: 'Бюджет' },
      sum: { label: 'Вільні залишки станом на початок року (з урахуванням залишків субвенцій з держ та місц бюджетів)', name: 'Сумма' },
      sumT: { label: 'у тому числі: залишки коштів за субвенціями з держ та місцбюджетів', name: 'СуммаВТЧ' },
      sumRasp: { label: 'Розподілено вільні залишки на звітну дату (без урахування залишків субв)', name: 'СуммаРаспределено' },
      sumWork: { label: 'на оплату праці працівників бюджетних установ та нарахування на заробітну плату (КЕКВ 2110+2120, 2282(2110+2120)', name: 'СуммаОплатаТруда' },
      prWork: { label: 'питома вага, %', name: 'ПроцентОплатаТруда' },
      sumCom: { label: 'на оплату комунальних послуг та енергоносіїв (КЕКВ 2270, 2282(2270)', name: 'СуммаКомуналка' },
      prCom: { label: 'питома вага, %', name: 'ПроцентКомуналка' },
      sumMed: { label: 'на медикаменти та перев\'язувальні матеріали (КЕКВ 2220, 2282(2220)', name: 'СуммаМедикаменты' },
      prMed: { label: 'питома вага, %', name: 'ПроцентМедикаменты' },
      sumProd: { label: 'на продукти харчування (КЕКВ 2230, 2282(2230)', name: 'СуммаПродукты' },
      prProd: { label: 'питома вага, %', name: 'ПроцентПродукты' },
      sumOther: { label: 'на інші видатки', name: 'СуммаДругие' },
      prOther: { label: 'питома вага, %', name: 'ПроцентДругие' },
      prRasp: { label: 'Відсоток розподілених коштів, без урахування розподілених залишків коштів субв.', name: 'ПроцентРаспределено' },
      sumUnused: { label: 'Нерозподілені вільні залишки на звітну дату', name: 'СуммаНеРаспределено' },
      prRaspAll: { label: 'Відсоток розподілених коштів всього', name: 'ПроцентРаспределеноВсего' },
      sumRaspSubv: {
        label: 'Розподілено вільні залишки коштів за субв. з держ. та місц. бюдж.',
        name: 'СуммаРаспределеноСубв',
      },
      sumSoc: { label: 'на соціальне забезпечення', name: 'СуммаСоц' },
      prSoc: { label: 'питома вага, %', name: 'ПроцентСоц' },
      sumReabil: {
        label: 'створення умов для лікування, відновлення та реабілітації осіб, які постраждали внаслідок збройної агресії РФ проти України',
        name: 'СуммаРеабилит',
      },
      prReabil: { label: 'питома вага, %', name: 'ПроцентРеабилит' },
      sumTer: {
        label: 'заходи та роботи з територіальної оборони',
        name: 'СуммаТерОборона',
      },
      prTer: { label: 'питома вага, %', name: 'ПроцентТерОборона' },
      sumMob: {
        label: 'заходи та роботи з мобілізаційної підготовки',
        name: 'СуммаМобилизация',
      },
      prMob: { label: 'питома вага, %', name: 'ПроцентМобилизация' },
      sumSec: {
        label: 'підтримку сил безпеки і оборони',
        name: 'СуммаБезопасность',
      },
      prSec: { label: 'питома вага, %', name: 'ПроцентБезопасность' },
      sumBuild: {
        label: 'проектування, будівництво, ремонт захисних споруд (сховищ, '
          + 'протирадіаційних укриттів), споруд подвійного призначення та приміщень, '
          + 'які плануються до використання для укриття',
        name: 'СуммаСтроительство',
      },
      prBuild: { label: 'питома вага, %', name: 'ПроцентСтроительство' },
      sumFort: {
        label: 'будівництво фортифікаційних споруд',
        name: 'СуммаФортификац',
      },
      prFort: { label: 'питома вага, %', name: 'ПроцентФортификац' },
      sumDebt: {
        label: 'обслуговування та погашення місцевого боргу',
        name: 'СуммаОбслуживаниеДолга',
      },
      prDebt: { label: 'питома вага, %', name: 'ПроцентОбслуживаниеДолга' },
      sumGarant: {
        label: 'виконання гарантійних зобов’язань АРК, обласної ради чи міської ТГ',
        name: 'СуммаГарантии',
      },
      prGarant: { label: 'питома вага, %', name: 'ПроцентГарантии' },
      sumReturn: {
        label: 'Повернуто невикористані залишки субв. до бюджету, з якого її було надано',
        name: 'СуммаВозвращено',
      },
      sumAdd: {
        label: 'Довідкова інформація:'
          + 'здійснено видатки за рахунок коштів, що передаються із ЗФ '
          + 'бюджету до БР (код 602400)',
        name: 'СуммаДополнительноБР',
      },
      prAdd: { label: 'питома вага, %', name: 'ПроцентДополнительноБР' },
      sumZF: {
        label: 'Довідкова інформація:'
          + 'здійснено видатки пов\'язазні зі збройною агресією РФ проти України (з урахуванням коштів переданих '
          + 'із ЗФ до БР)',
        name: 'СуммаДополнительноПереданоИзЗФ',
      },
      prZF: { label: 'питома вага, %', name: 'ПроцентДополнительноПереданоИзЗФ' },
    },
  },
  docs: {
    name: 'Доки',
    label: 'Документи',
    columns: {
      appr: { label: 'Завантажити', name: 'ДокументУтвержден' },
      doc: { label: 'Документ', name: 'Док' },
      note: { label: 'Примітка', name: 'Примечание' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Інформація щодо стану розподілу вільних залишків. Додаток 4. 2024 р.',
  name: 'freeRem4Add2024',
  backendName: 'СвободныеОстатки4Дод2024',
  columns,
  frontend: 'doc/freeRem4Add2024',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'FI',
      'author',
      'apprByRegion',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
