import React, { useMemo } from 'react';
import {
  Button, Col, Row, Container, ButtonGroup, Accordion, Badge,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import { Loader } from '../../../components/bootStrap';
// eslint-disable-next-line import/no-cycle
import {
  EditorCheckboxInput,
  EditorDateInput, EditorItemPicker, EditorStingInput,
} from '../../basicEditor/editorControls';
import DocList from '../../instances/individual/doc/allocationplanchanges/docs';
import { CPButton } from '../../../components/bootStrap/buttons/styles';
import useExportFinancialsToDBF from './hook';
import IconAlert from '../../../components/blanks/common/IconAlert';

const variants = [
  { value: 0, display_name: 'Розпорядження' },
  { value: 1, display_name: 'Розподіл' },
  { value: 2, display_name: 'Передача коштів' },
];

function ExportFinancialsToDBFEditor({ currentItem, backendURL }) {
  const {
    loading, err, setErr, handleSubmit,
    variant, noArchive, setNoArchive, dbf373Long, setDbf373Long, dbf3732, setDbf3732,
    br, setBr, winCode, setWinCode, bigNote, setBigNote, useSk, setUseSk, collapseRows,
    setCollapseRows, collapseWithoutNote, setCollapseWithoutNote,
    shortDisposerName, setShortDisposerName, budgetCodeAsKoatuu, setBudgetCodeAsKoatuu, dbfDate,
    setDbfDate, addDbf, setAddDbf, upperCaseFilename, setUpperCaseFilename,
    underscoreFilename, setUnderscoreFilename, accountFilename, setAccountFilename,
    numberInFilename, setNumberInFilename, buhRank, setBuhRank,
    buhSign, setBuhSign, directorRank, setDirectorRank, directorSign, setDirectorSign, authority,
    setAuthority, authorityReceiver, setAuthorityReceiver,
    nob, setNob, nazb, setNazb, nra, setNra, beginDate, setBeginDate, endDate,
    setEndDate, docs, setDocs, getDocList, setActiveDoc, onToggleDoc, activeDoc,
  } = useExportFinancialsToDBF({ currentItem, backendURL });
  const dVariant = useMemo(
    () => variants
      .filter((v) => v.value === variant)
      .reduce((R, v) => v.display_name, ''),
    [variant],
  );
  return (
    <Container fluid>
      {loading && <Loader />}
      {err && (
        <IconAlert dismissible variant="danger" onClose={() => setErr(null)}>
          {err}
        </IconAlert>
      )}
      <div className="d-flex align-items-baseline justify-content-between mb-2">
        <Button onClick={handleSubmit}>
          <FontAwesomeIcon
            icon={faFileExport}
            className="me-2"
            bounce
          />
          Вивантажити DBF
        </Button>
        <Badge bg="info">
          {dVariant}
        </Badge>
      </div>
      <Accordion>
        <Accordion.Item eventKey="file">
          <Accordion.Header>Назва та склад файлу</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={4}>
                <h5 className="text-primary">
                  Назва файлу та каталогу
                </h5>
                <EditorCheckboxInput
                  controlId="noArchive"
                  label="Не архівувати файли"
                  value={noArchive}
                  onChange={(e, v) => setNoArchive(v)}
                />
                <EditorCheckboxInput
                  controlId="winCode"
                  label="Кодіровка Windows"
                  value={winCode}
                  onChange={(e, v) => setWinCode(v)}
                />
                <EditorCheckboxInput
                  controlId="addDbf"
                  label="Проставляти у файлі розширення &quot;DBF&quot;"
                  value={addDbf}
                  onChange={(e, v) => setAddDbf(v)}
                />
                {variant === 1 && (
                  <>
                    <EditorCheckboxInput
                      controlId="upperCaseFilename"
                      label="Великі букви для розподілу"
                      value={upperCaseFilename}
                      onChange={(e, v) => setUpperCaseFilename(v)}
                    />
                    <EditorCheckboxInput
                      controlId="underscoreFilename"
                      label="Символ &quot;_&quot; замість &quot;.&quot; в імені файла"
                      value={underscoreFilename}
                      onChange={(e, v) => setUnderscoreFilename(v)}
                    />
                  </>
                )}
                <EditorCheckboxInput
                  controlId="accountFilename"
                  label="Рахунок в імені папки"
                  value={accountFilename}
                  onChange={(e, v) => setAccountFilename(v)}
                />
                <EditorCheckboxInput
                  controlId="numberInFilename"
                  label="Вказувати номер документу в розширенні файлу"
                  value={numberInFilename}
                  onChange={(e, v) => setNumberInFilename(v)}
                />

              </Col>
              <Col>
                <h5 className="text-primary">
                  Склад файлу
                </h5>
                <Row>
                  <Col>
                    <EditorCheckboxInput
                      controlId="dbf373Long"
                      label="Формат файла з подовженими полями для системи Е-КАЗНА"
                      value={dbf373Long}
                      onChange={(e, v) => setDbf373Long(v)}
                    />
                    <EditorCheckboxInput
                      controlId="dbf3732"
                      label="Передача коштів із ЗФ до СФ за наказом 373"
                      value={dbf3732}
                      onChange={(e, v) => setDbf3732(v)}
                    />
                    <EditorCheckboxInput
                      controlId="br"
                      label="Розпорядження (розподіл)  про виділення коштів спеціального фонду місцевих бюджетів,
                             які обслуговуються в установах банків (Дод.№21)"
                      value={br}
                      onChange={(e, v) => setBr(v)}
                    />
                    {variant === 1 && (
                      <>
                        <EditorCheckboxInput
                          controlId="bigNote"
                          label="Збільшене число символів у примітці (160 знаків)"
                          value={bigNote}
                          onChange={(e, v) => setBigNote(v)}
                        />
                        <EditorCheckboxInput
                          controlId="useSk"
                          // Використовувати наступні значення для визначення типу фонду ,
                          // поле  SK  в   розподілі
                          label="SK: 1 - загальний фонд, 2 - плата за послуги бюджетних установ, 3 - інші джерела власних надходжень, 7 - інші кошти спеціального фонду"
                          value={useSk}
                          onChange={(e, v) => setUseSk(v)}
                        />
                      </>
                    )}
                  </Col>
                  <Col>
                    {(variant === 0 || variant === 1) && (
                      <EditorCheckboxInput
                        controlId="collapseRows"
                        label="Згортати за КБП7 та КЕКВ"
                        value={variant === 2 ? false : collapseRows}
                        onChange={(e, v) => setCollapseRows(v)}
                        disabled={variant === 2}
                      />
                    )}
                    <EditorCheckboxInput
                      controlId="collapseWithoutNote"
                      label="Не враховувати примітку при згортанні"
                      value={collapseWithoutNote}
                      onChange={(e, v) => setCollapseWithoutNote(v)}
                    />
                    <EditorCheckboxInput
                      controlId="shortDisposerName"
                      label="Скорочена назва установ"
                      value={shortDisposerName}
                      onChange={(e, v) => setShortDisposerName(v)}
                    />
                    <EditorCheckboxInput
                      controlId="budgetCodeAsKoatuu"
                      label="Код бюджету за КОАТУУ"
                      value={budgetCodeAsKoatuu}
                      onChange={(e, v) => setBudgetCodeAsKoatuu(v)}
                      description="поле BUDGET як Код бюджету в файлі ДБФ"
                    />
                    <EditorDateInput
                      controlId="dbf_date"
                      label="Дата файлу"
                      value={dbfDate}
                      onChange={(e, v) => setDbfDate(v)}
                    />
                  </Col>
                </Row>

              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="executive">
          <Accordion.Header>Відповідальні особи та ін.</Accordion.Header>
          <Accordion.Body>
            <div>
              <p className="text-muted">
                Додаткова інформація для запису у файл (заповнюється виключно
                за потребою користувача, у випадку необхідності запису у файл нестандартних значень)
              </p>
              <Row>
                <Col>
                  <EditorStingInput
                    value={buhRank}
                    label="Посада фінансиста"
                    onChange={(e, v) => setBuhRank(v)}
                    required
                  />
                </Col>
                <Col>
                  <EditorItemPicker
                    value={buhSign}
                    onChange={(e, v) => {
                      setBuhSign(v);
                      setBuhRank(v.rank);
                    }}
                    backend="/api/references/refexecutive/"
                    modelName="executive"
                    label="Фінансист(з фін. органу)"
                    modelType="cat"
                  />
                </Col>
                <Col>
                  <EditorStingInput
                    value={directorRank}
                    label="Посада керівника"
                    required
                    onChange={(e, v) => setDirectorRank(v)}
                  />
                </Col>
                <Col>
                  <EditorItemPicker
                    value={directorSign}
                    onChange={(e, v) => {
                      setDirectorSign(v);
                      setDirectorRank(v.rank);
                    }}
                    backend="/api/references/refexecutive/"
                    modelName="executive"
                    label="Керівник(з фін. органу)"
                    modelType="cat"
                  />
                </Col>
                <Col>
                  <EditorItemPicker
                    value={authority}
                    onChange={(e, v) => setAuthority(v)}
                    backend="/api/references/refdisposer/"
                    modelName="disposers"
                    label="Установа що надає фінансування"
                    modelType="cat"
                  />
                </Col>
                <Col>
                  <EditorItemPicker
                    value={authorityReceiver}
                    onChange={(e, v) => setAuthorityReceiver(v)}
                    backend="/api/references/refdisposer/"
                    modelName="disposers"
                    label="Установа отримувач коштів"
                    modelType="cat"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorItemPicker
                    value={nob}
                    onChange={(e, v) => setNob(v)}
                    backend="/api/references/reftreasurebank/"
                    modelName="banks"
                    label="Назва гол упр ДКСУ (поле NOB)"
                    modelType="cat"
                  />
                </Col>
                <Col>
                  <EditorItemPicker
                    value={nazb}
                    onChange={(e, v) => setNazb(v)}
                    backend="/api/references/reftreasurebank/"
                    modelName="banks"
                    label="Назва респ  упр ДКСУ (поле  NAZB)"
                    modelType="cat"
                  />
                </Col>
                <Col>
                  <EditorItemPicker
                    value={nra}
                    onChange={(e, v) => setNra(v)}
                    backend="/api/references/reftreasurebank/"
                    modelName="banks"
                    label="Назва рай упр ДКСУ (поле NRA)"
                    modelType="cat"
                  />
                </Col>
              </Row>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="docs">
          <Accordion.Header>Документи що вивантажуються</Accordion.Header>
          <Accordion.Body>
            <div>
              <div className="d-flex mb-2 align-items-baseline gap-3">
                <EditorDateInput
                  controlId="begin_date"
                  label="Відібрати документи за період з"
                  required
                  value={beginDate}
                  onChange={(e, v) => setBeginDate(v)}
                />
                <EditorDateInput
                  controlId="end_date"
                  label="по"
                  required
                  value={endDate}
                  onChange={(e, v) => setEndDate(v)}
                />
                <Button
                  variant="success"
                  onClick={() => getDocList()}
                  size="sma"
                  className="ms-2"
                >
                  Відібрати документи
                </Button>
              </div>
              <Row>
                <Col>
                  <div className="w-100 rounded border">
                    <ButtonGroup>
                      <CPButton
                        onClick={() => setDocs(docs.map((d) => ({ ...d, use: true })))}
                        title="Обрати всі"
                        icon={faCheckSquare}
                      />
                      <CPButton
                        onClick={() => setDocs(docs.map((d) => ({ ...d, use: false })))}
                        title="Зняти відмітки"
                        icon={faSquare}
                      />
                    </ButtonGroup>
                  </div>
                  <DocList
                    docs={docs}
                    onSelectDoc={setActiveDoc}
                    onToggleDoc={onToggleDoc}
                    activeDoc={activeDoc}
                  />
                </Col>
              </Row>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}

ExportFinancialsToDBFEditor.propTypes = {
  currentItem: PropTypes.arrayOf(PropTypes.string).isRequired,
  backendURL:
  PropTypes.string.isRequired,
};

export default ExportFinancialsToDBFEditor;
