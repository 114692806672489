/* eslint-disable react/no-array-index-key,no-unused-vars */
import { Accordion, Card } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useTablePart from '../../../../../newEditor/hook/tablepart';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import Header from './header';
import { dataPropTypes, fieldsPropTypes } from './propTypes';
import { errorsPropTypes } from '../../../../allocationplan/editor/tp/planningRows/propTypes';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import TPCommandPanel from './tablepartCP';
import HealthCareExpensesRow from './healthCareExpensesRow';

function HealthCareExpensesRowSet({
  onChange, data, fields, errors, readOnlyFields, readOnly,
}) {
  const {
    tableData, tableActions, pinHeader, tableContainerRef, tableReadOnlyFields,
    tableErrors, tableFields, activeCol, activeRow, highlights,
  } = useTablePart({
    data, onChange, tableName: 'healthcareexpensesrow_set', readOnlyFields, errors, fields,
  });
  const { authority } = data;
  const [eventKey, setEventKey] = useState();
  useEffect((e) => {
    if (tableData.length) {
      setEventKey(tableData[0].which_kekv);
    } else if (tableFields?.which_kekv?.choices.length) {
      setEventKey(tableFields.which_kekv.choices[0].value);
    }
  }, [tableData, tableFields]);
  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPCommandPanel
          pinHeader={pinHeader}
          tableActions={tableActions}
          readOnly={readOnly}
          activeRow={activeRow}
          kekv={eventKey}
        />
        <Header
          fields={tableFields}
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlight={tableActions.onToggleHighlightColumn}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        <Accordion
          activeKey={eventKey}
          onSelect={(value) => setEventKey(value)}
        >
          {tableFields.which_kekv?.choices.map((kekv, i) => (
            <Accordion.Item eventKey={kekv.value}>
              <Accordion.Header>{kekv.display_name}</Accordion.Header>
              <Accordion.Body>
                {tableData && tableData.filter((v) => (v.which_kekv === kekv.value))
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      rowIndex={index}
                      active={activeRow === index}
                      activeCol={activeCol}
                    >
                      <HealthCareExpensesRow
                        rowIndex={index}
                        row={row}
                        onChange={tableActions.onCellChange}
                        readOnly={readOnly}
                        highlights={highlights}
                        fields={tableFields}
                        errors={tableErrors[index]}
                        readOnlyFields={tableReadOnlyFields}
                        authority={authority}
                        kekv={kekv.value}
                      />
                    </TableRow>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <Placeholder onFocus={() => tableActions.onAddRow()} />
      </Card.Body>
    </Card>
  );
}

HealthCareExpensesRowSet.propTypes = {
  data: PropTypes.shape({
    merezharow_set: PropTypes.arrayOf(PropTypes.shape(dataPropTypes)),
    authority: PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.number,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    merezharow_set: fieldsPropTypes,
  }).isRequired,
  errors: PropTypes.shape({
    merezharow_set: PropTypes.arrayOf(errorsPropTypes),
  }),
  readOnlyFields: PropTypes.shape({
    merezharow_set: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
};
HealthCareExpensesRowSet.defaultProps = {
  errors: null,
  readOnlyFields: [],
  readOnly: false,
};
export default HealthCareExpensesRowSet;
