import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  additional: { label: 'Додатковий додаток', name: 'Допонительно', visible: true },
  solutionNumber: {
    label: 'Номер рішення',
    name: 'НомерРешения',
  },
  solutionDate: {
    label: 'Дата рішення',
    name: 'ДатаРешения',
  },
  forceDate: {
    label: 'Дата набрання чинності рішення',
    name: 'ДатаЧинності',
  },
  dateFrom: {
    label: 'Дата з якої застосовуються ставки та пільги',
    name: 'ДатаЗ',
  },
  nname: {
    label: 'Орган місцевого самоврядуваня, що прийняв рішення',
    name: 'КАТОТТГ',
  },
  nameAto: {
    label: 'Адміністративно-територіальні одиниці, для яких встановлено ставки та пільги',
    name: 'КАТОТТГ_АТО',
  },
};

/**
 * @const
 */
const tables = {
  TABL_71: {
    name: 'Табл71',
    label: 'Табл 71',
    columns: {
      number: { label: 'Номер з/п 1.n', name: 'Номер' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      category: { label: 'Категорія ставок 1.n', name: 'Категория' },
      amount: { label: 'Розмір ставок (в\'їздний туризм) 1.n', name: 'Размер' },
    },
  },
  TABL_72: {
    name: 'Табл72',
    label: 'Табл 72',
    columns: {
      privilegeNumber: { label: 'Номер пільги 2.1.n', name: 'НомерЛьготы' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      privilegeName: { label: 'Категорія пільг 2.1.n', name: 'НаимЛьготы' },
      privilegeAmount: { label: 'Розмір пільги 2.1.n', name: 'РазмерЛьготы' },
    },
  },
  TABL_73: {
    name: 'Табл73',
    label: 'Табл 73',
    columns: {
      privilegeNumber: { label: 'Номер пільги 2.2.n', name: 'НомерЛьготы' },
      codeDod: { label: 'Додатковий код (за наявності) ', name: 'КодДод' },
      privilegeName: { label: 'Категорія пільг 2.2.n', name: 'НаимЛьготы' },
      privilegeAmount: { label: 'Розмір пільги 2.2.n', name: 'РазмерЛьготы' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги Додаток 7 ',
  name: 'taxes5',
  backendName: 'НалогиИЛьготыДод5',
  columns,
  frontend: 'doc/taxes5',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
