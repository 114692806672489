import React, { useEffect, useMemo, useReducer } from 'react';
import AppContext from './context/Context';
import { getColor, getItemFromStore } from './helpers/utils';
// import { Chart as ChartJS, registerables } from 'chart.js';
import { settings } from './config';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import { childrenPropType } from '../../constants/backend/propTypes';
import useBrowserName from '../../utils/browserName';

// ChartJS.register(...registerables);

function Main({ children }) {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed,
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch,
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
        ].includes(key),
      },
    });
  };

  const ctxValue = useMemo(
    () => ({
      config, setConfig, configDispatch,
    }),
    [config],
  );
  const { isFirefox } = useBrowserName();
  useEffect(() => {
    if (isFirefox) document.body.classList.add('firefox');
    else document.body.classList.remove('firefox');
  }, [isFirefox]);

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light'),
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={ctxValue}>
      {children}
    </AppContext.Provider>
  );
}

Main.propTypes = {
  children: childrenPropType.isRequired,
};

export default Main;
