import base from './base';
import { periodTypes } from '../common';

/**
 * @const
 */
const columns = {
  ...base.columns,
  budget: { label: 'Бюджет', name: 'Бюджет', visible: true },
  author: { label: 'Автор', name: 'Автор', visible: true },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  tablePeriod: { label: 'Період табличної частини', name: 'ПериодТЧ', visible: true },
  FI: { label: 'Фінансовий орган', name: 'ФинансовыйОрган', visible: true },
  additional: { label: 'Додатковий додаток', name: 'Допонительно', visible: true },
  solutionNumber: {
    label: 'Номер рішення',
    name: 'НомерРешения',
  },
  solutionDate: {
    label: 'Дата рішення',
    name: 'ДатаРешения',
  },
  forceDate: {
    label: 'Дата набрання чинності рішення',
    name: 'ДатаЧинності',
  },
  dateFrom: {
    label: 'Дата з якої застосовуються ставки та пільги',
    name: 'ДатаЗ',
  },
  nname: {
    label: 'Орган місцевого самоврядуваня, що прийняв рішення',
    name: 'КАТОТТГ',
  },
  nameAto: {
    label: 'Адміністративно-територіальні одиниці, для яких встановлено ставки та пільги',
    name: 'КАТОТТГ_АТО',
  },
};

/**
 * @const
 */
const tables = {
  TABL_101: {
    name: 'Табл101',
    label: 'Табл 101',
    columns: {
      code: { label: 'Код виду діяльності', name: 'Код' },
      name: { label: 'Назва виду діяльності', name: 'Наим' },
      rate: { label: 'Ставка єдиного податку', name: 'Ставка' },
      period: { label: 'Період застосування ставки', name: 'Период' },
    },
  },
};

/**
 * @const
 */
const definition = {
  label: 'Ставки та пільги Додаток 10 ',
  name: 'taxes8',
  backendName: 'НалогиИЛьготыДод8',
  columns,
  frontend: 'doc/taxes8',
  listColumns: [
    ...new Set([
      ...base.listColumns,
      'budget',
      'author',
      'note',
    ]),
  ],
  defaultOrder: [...base.defaultOrder],
  tables,
  hierarchy: false,
  defaultPeriodType: periodTypes.year,
  useListLazyLoad: true,
};

export default definition;
