import React, {
  memo, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { dataPropTypes, fieldsPropTypes, errorsPropTypes } from './propTypes';
import {
  tableHooks,
  TableControls,
} from '../../../../../basicEditor/tableControls';
import TabelRowCell from '../../../../../newEditor/tablePart/tabkeRowCell';
import {
  PrependAccount, PrependKBP7, PrependKEKV, PrependKFB, PrependKVK,
} from '../../../../styles/klassifiers';

function TRow({
  rowIndex,
  onChange,
  row,
  fields,
  errors,
  highlights,
  readOnly,
  readOnlyFields,
  disposerInHeader,
  disposerAccHeaderFilter,
  authorityId,
  fondId,
  isRefundByMonth,
  isRefund,
  isTransferCfSf,
  isTransfer,
  useFinProposal,
}) {
  const treasureDateProps = tableHooks.useDateInputProps('treasure_date', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7Props = tableHooks.useItemInputProps('kbp7_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kbp7Filter = useMemo(() => ({ owner: authorityId }), [authorityId]);
  const kekvProps = tableHooks.useItemInputProps('kekv_item', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kekvFilter = useMemo(() => ({ owner: authorityId }), [authorityId]);
  const disposerProps = tableHooks.useItemInputProps('disposer', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerFilter = useMemo(() => ({ parent: authorityId }), [authorityId]);
  const disposerAccProps = tableHooks.useItemInputProps('disposer_acc', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const disposerAccFilter = useMemo(
    () => (disposerInHeader ? disposerAccHeaderFilter : {
      disposer: row.disposer ? row.disposer.id : null,
      fond: fondId,
    }),
    [disposerAccHeaderFilter, disposerInHeader, fondId, row.disposer],
  );
  const finCommitmentAmountProps = tableHooks.useNumberInputProps('fin_commitment_amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const balanceProps = tableHooks.useNumberInputProps('balance', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const amountProps = tableHooks.useNumberInputProps('amount', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const remarkTemplateProps = tableHooks.useItemInputProps('remark_template', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const remarkNoteProps = tableHooks.useStringInputProps('remark_note', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const finrequestProps = tableHooks.useItemInputProps('finrequest', row, fields, errors, onChange, rowIndex, true, readOnlyFields);
  const finproposalProps = tableHooks.useItemInputProps('finproposal', row, fields, errors, onChange, rowIndex, true, readOnlyFields);
  const kfbCreditProps = tableHooks.useItemInputProps('kfb_credit', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const kfbDebtProps = tableHooks.useItemInputProps('kfb_debt', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const acc3261Props = tableHooks.useItemInputProps('acc3261', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const acc3262Props = tableHooks.useItemInputProps('acc3262', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const returnMonthProps = tableHooks.useSelectorInputProps('return_month', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);
  const returnFundingProps = tableHooks.useCheckboxInputProps('return_funding', row, fields, errors, onChange, rowIndex, readOnly, readOnlyFields);

  const disposerAccDisabled = Object.keys(disposerAccFilter)
    .reduce((R, k) => R || !disposerAccFilter[k], false);

  const amountErrors = useMemo(
    () => {
      if (row.amount > row.balance && !isTransfer && !isRefund
      && !(isRefundByMonth && row.return_funding)) {
        return [
          ...(errors.amount || []),
          'Сума фінансування перевищує залишок',
        ];
      }
      return errors.amount;
    },
    [errors.amount, isRefund, isRefundByMonth, isTransfer,
      row.amount, row.balance, row.return_funding],
  );

  const refundClass = useMemo(() => (
    isRefund || (isRefundByMonth && row.return_funding) ? 'text-danger' : ''
  ), [isRefund, isRefundByMonth, row.return_funding]);

  return (
    <Col>
      <Row>
        {isRefundByMonth && (
        <Col>
          <Row>
            <Col sm={2}>
              <TabelRowCell column="return_funding" highlighted={highlights.includes('return_funding')}>
                <TableControls.CheckboxInput
                  {...returnFundingProps}
                />
              </TabelRowCell>
            </Col>
            <Col>
              <TabelRowCell column="return_month" highlighted={highlights.includes('return_month')}>
                <TableControls.SelectorInput
                  {...returnMonthProps}
                />
              </TabelRowCell>
            </Col>
          </Row>
        </Col>
        )}
        {isTransferCfSf && (
        <Col>
          <TabelRowCell column="kfb_debt" highlighted={highlights.includes('kfb_debt')}>
            <TableControls.ItemPicker
              {...kfbDebtProps}
              prepend={(
                <PrependKFB>
                  {row.kfb_debt && row.kfb_debt.repr}
                </PrependKFB>
              )}
            />
          </TabelRowCell>
          <TabelRowCell column="kfb_credit" highlighted={highlights.includes('kfb_credit')}>
            <TableControls.ItemPicker
              {...kfbCreditProps}
              prepend={(
                <PrependKFB>
                  {row.kfb_credit && row.kfb_credit.repr}
                </PrependKFB>
              )}
            />
          </TabelRowCell>
          <TabelRowCell column="acc3261" highlighted={highlights.includes('acc3261')}>
            <TableControls.ItemPicker
              className="flex-nowrap"
              {...acc3261Props}
              filter={disposerAccFilter}
              prepend={(
                <PrependAccount>
                  {row.acc3261 && row.acc3261.code}
                </PrependAccount>
              )}
            />
          </TabelRowCell>
          <TabelRowCell column="acc3262" highlighted={highlights.includes('acc3262')}>
            <TableControls.ItemPicker
              className="flex-nowrap"
              {...acc3262Props}
              filter={disposerAccFilter}
              prepend={(
                <PrependAccount>
                  {row.acc3262 && row.acc3262.code}
                </PrependAccount>
              )}
            />
          </TabelRowCell>
        </Col>
        )}
        <Col lg={3} className="px-0">
          {!disposerInHeader && (
            <TabelRowCell column="disposer" highlighted={highlights.includes('disposer')}>
              <TableControls.ItemPicker
                {...disposerProps}
                filter={disposerFilter}
                noHierarchy
                prepend={(
                  <PrependKVK>
                    {row.kvk && row.kvk.repr}
                  </PrependKVK>
                )}
              />
            </TabelRowCell>
          )}
          <TabelRowCell column="kbp7_item" highlighted={highlights.includes('kbp7_item')}>
            <TableControls.ItemPicker
              {...kbp7Props}
              filter={kbp7Filter}
              prepend={(
                <PrependKBP7>
                  {row.kbp7 && row.kbp7.repr}
                </PrependKBP7>
                      )}
            />
          </TabelRowCell>
          <TabelRowCell column="kekv_item" highlighted={highlights.includes('kekv_item')}>
            <TableControls.ItemPicker
              {...kekvProps}
              filter={kekvFilter}
              prepend={(
                <PrependKEKV>
                  {row.kekv && row.kekv.repr}
                </PrependKEKV>
                      )}
            />
          </TabelRowCell>
        </Col>
        <Col lg={3} className="px-0">
          <TabelRowCell column="disposer_acc" highlighted={highlights.includes('disposer_acc')}>
            <TableControls.ItemPicker
              className="flex-nowrap"
              {...disposerAccProps}
              filter={disposerAccFilter}
              disabled={disposerAccDisabled}
              prepend={(
                <PrependAccount>
                  {row.disposer_acc && row.disposer_acc.code}
                </PrependAccount>
                      )}
            />
          </TabelRowCell>
          <TabelRowCell column="treasure_date" highlighted={highlights.includes('treasure_date')}>
            <TableControls.DateInput {...treasureDateProps} />
          </TabelRowCell>
        </Col>
        <Col lg={2} className="px-0">
          <TabelRowCell column="fin_commitment_amount" highlighted={highlights.includes('fin_commitment_amount')}>
            <TableControls.NumberInput
              {...finCommitmentAmountProps}
              disabled
            />
          </TabelRowCell>
          <TabelRowCell column="balance" highlighted={highlights.includes('balance')}>
            <TableControls.NumberInput
              {...balanceProps}
              disabled
            />
          </TabelRowCell>
          <TabelRowCell column="amount" highlighted={highlights.includes('amount')}>
            <TableControls.NumberInput
              {...amountProps}
              className={refundClass}
              errors={amountErrors}
            />
          </TabelRowCell>
        </Col>
        <Col className="px-0">
          {useFinProposal && (
          <TabelRowCell column="finproposal" highlighted={highlights.includes('finproposal')}>
            <TableControls.ItemPicker {...finproposalProps} />
          </TabelRowCell>
          )}
          {!useFinProposal && (
            <TabelRowCell column="finrequest" highlighted={highlights.includes('finrequest')}>
              <TableControls.ItemPicker {...finrequestProps} />
            </TabelRowCell>
          )}
          <TabelRowCell column="remark_template" highlighted={highlights.includes('remark_template')}>
            <TableControls.ItemPicker {...remarkTemplateProps} />
          </TabelRowCell>
          <TabelRowCell column="remark_note" highlighted={highlights.includes('remark_note')}>
            <TableControls.TextInput {...remarkNoteProps} />
          </TabelRowCell>
        </Col>
      </Row>
    </Col>
  );
}

TRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape(dataPropTypes).isRequired,
  fields: fieldsPropTypes.isRequired,
  errors: errorsPropTypes,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  disposerInHeader: PropTypes.bool,
  authorityId: PropTypes.number,
  fondId: PropTypes.number,
  showKDBKFB: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  isRefundByMonth: PropTypes.bool.isRequired,
  isRefund: PropTypes.bool.isRequired,
  isTransferCfSf: PropTypes.bool,
  isTransfer: PropTypes.bool,
  useFinProposal: PropTypes.bool.isRequired,
  disposerAccHeaderFilter: PropTypes.shape({ disposer: PropTypes.number }).isRequired,
};

TRow.defaultProps = {
  errors: {},
  disposerInHeader: false,
  authorityId: null,
  fondId: null,
  showKDBKFB: false,
  readOnly: false,
  readOnlyFields: [],
  isTransferCfSf: false,
  isTransfer: false,
};

export default memo(TRow);
