import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import AppContext from '../../../providers/CiatAppContext';
import useServices from '../hooks';
import api from '../../../api/req';
import { downloadFileFromJSON } from '../../../api/utils';

const useExportFinancialsToDBF = ({ currentItem, backendURL }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState();
  const [beginDate, setBeginDate] = useState(); // begin_date
  const [endDate, setEndDate] = useState(); // end_date
  const [docs, setDocs] = useState([]); // docs
  // const [fonds, setFonds] = useState([]); // docs
  const [activeDoc, setActiveDoc] = useState(null);
  const [noArchive, setNoArchive] = useState(false); // no_archive
  // const [kind, setKind] = useState(0);
  const [variant, setVariant] = useState(0);
  const [dbf373Long, setDbf373Long] = useState(false);
  const [dbf3732, setDbf3732] = useState(false);
  // поки приберу це, є підозра що вона не потрібна
  // const [asPaymentOrder, setAsPaymentOrder] = useState(false); // just for kind = 2
  const [winCode, setWinCode] = useState(false);
  const [bigNote, setBigNote] = useState(false);
  const [collapseRows, setCollapseRows] = useState(false);
  const [collapseWithoutNote, setCollapseWithoutNote] = useState(false);
  const [addDbf, setAddDbf] = useState(false);
  const [dbfDate, setDbfDate] = useState(new Date()); //
  const [upperCaseFilename, setUpperCaseFilename] = useState(false);
  const [underscoreFilename, setUnderscoreFilename] = useState(false);
  const [accountFilename, setAccountFilename] = useState(false);
  const [numberInFilename, setNumberInFilename] = useState(false);
  const [shortDisposerName, setShortDisposerName] = useState(false);
  const [budgetCodeAsKoatuu, setBudgetCodeAsKoatuu] = useState(false);
  const [buhSign, setBuhSign] = useState();
  const [directorSign, setDirectorSign] = useState();
  const [buhRank, setBuhRank] = useState('');
  const [directorRank, setDirectorRank] = useState('');
  const [authority, setAuthority] = useState();
  const [authorityReceiver, setAuthorityReceiver] = useState();
  const [nob, setNob] = useState();
  const [nazb, setNazb] = useState();
  const [nra, setNra] = useState();
  const [br, setBr] = useState(false);
  const [foId, setFoId] = useState(false);
  const [useSk, setUseSk] = useState(false);

  const { settings, onSetSettings } = useServices(backendURL, variant);
  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  // const selectedFonds = useMemo(
  //   () => fonds.filter((d) => d.use)
  //     .map((d) => d.id),
  //   [fonds],
  // );
  const selectedDocs = useMemo(
    () => docs.filter((d) => d.use)
      .map((d) => d.id),
    [docs],
  );

  const params = useMemo(
    () => {
      const p = {
        no_archive: noArchive,
        docs: selectedDocs.length ? selectedDocs : currentItem,
        begin_date: beginDate,
        end_date: endDate,
        active_doc: activeDoc,
        variant,
        dbf_373_long: dbf373Long,
        dbf_373_2: dbf3732,
        // as_payment_order: asPaymentOrder,
        win_code: winCode,
        big_note: bigNote,
        collapse_rows: collapseRows,
        collapse_without_note: collapseWithoutNote,
        add_dbf: addDbf,
        dbf_date: dbfDate,
        buh_rank: buhRank,
        director_rank: directorRank,
        upper_case_filename: upperCaseFilename,
        underscore_filename: underscoreFilename,
        account_filename: accountFilename,
        number_in_filename: numberInFilename,
        short_disposer_name: shortDisposerName,
        budget_code_as_koatuu: budgetCodeAsKoatuu,
        br,
        use_sk: useSk,
      };
      const items = {
        buh_sign: buhSign,
        director_sign: directorSign,
        authority,
        authority_receiver: authorityReceiver,
        nob,
        nazb,
        nra,
      };
      Object.keys(items).forEach((key) => {
        if (items[key] !== null && items[key] !== undefined) {
          p[key] = items[key].id;
        }
      });
      return p;
    },
    [accountFilename, activeDoc, addDbf, authority, authorityReceiver, beginDate, bigNote, br,
      budgetCodeAsKoatuu, buhRank, buhSign, collapseRows, collapseWithoutNote, currentItem, dbf3732,
      dbf373Long, dbfDate, directorRank, directorSign, endDate, nazb, noArchive, nob, nra,
      numberInFilename, selectedDocs, shortDisposerName,
      underscoreFilename, upperCaseFilename, useSk, variant, winCode],
  );
  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const getInitial = useCallback((v) => {
    const loader = async () => {
      const r = await api.get(`/api/financials/export/get_initials/?documents=${v[0]}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setBeginDate(rData.begin_date);
        setEndDate(rData.end_date);
        setDbfDate(rData.dbf_date);
        setVariant(rData.variant);
        setBuhSign(rData.buh_sign);
        setBuhRank(rData.buh_rank);
        setFoId(rData.fo_id);
        setDirectorSign(rData.director_sign);
        setDirectorRank(rData.director_rank);
        setDocs(rData.docs.map((d) => ({
          id: d.id,
          repr: d.repr,
          use: true,
        })));
        // setFonds(rData.fonds.map((f) => ({
        //   id: f.id,
        //   repr: f.repr,
        //   use: f.use,
        // })));
        setDbf3732(rData.dbf_373_2);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const getDocList = useCallback(
    () => {
      const loader = async () => {
        const listParams = `authority=${foId}&begin_date=${beginDate}
      &end_date=${endDate}&executed=${true}`;
        const r = await api.get(`${backendURL}?${listParams}`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      onLoadStart();
      loader()
        .then((rData) => {
          const newDocs = [...rData.results].map((doc) => ({
            id: doc.id,
            repr: doc.repr,
            use: true,
          }));
          setDocs(newDocs);
        })
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    },
    [auth, backendURL, beginDate, endDate, foId, onError, onLoadEnd, onLoadStart],
  );

  const onToggleDoc = useCallback(
    (docId) => setDocs(docs.map((d) => ({ ...d, use: d.id === docId ? !d.use : d.use }))),
    [docs],
  );
  const fillSettings = useCallback(
    () => {
      if (settings && Object.keys(settings).length > 0) {
        setUpperCaseFilename(settings.upper_case_filename);
        setBudgetCodeAsKoatuu(settings.budget_code_as_koatuu);
        setDbf373Long(settings.dbf_373_long);
        setDbf3732(settings.dbf_373_2);
        setWinCode(settings.win_code);
        setBigNote(settings.big_note);
        setCollapseRows(settings.collapse_rows);
        setCollapseWithoutNote(settings.collapse_without_note);
        setAddDbf(settings.add_dbf);
        setUpperCaseFilename(settings.upper_case_filename);
        setUnderscoreFilename(settings.underscore_filename);
        setAccountFilename(settings.account_filename);
        setNumberInFilename(settings.number_in_filename);
        setShortDisposerName(settings.short_disposer_name);
        // setBuhRank(settings.buh_rank);
        // setDirectorRank(settings.director_rank);
        setAuthority(settings.authority);
        setAuthorityReceiver(settings.authority_receiver);
        setNob(settings.nob);
        setNazb(settings.nazb);
        setNra(settings.nra);
        setBr(settings.br);
        setUseSk(settings.use_sk);
      }
    },
    [settings],
  );

  // const onToggleFond = useCallback(
  //   (fondId) => setFonds(fonds.map((d) => ({ ...d, use: d.id === fondId ? !d.use : d.use }))),
  //   [fonds],
  // );

  useEffect(
    () => {
      getInitial(currentItem);
      fillSettings();
    },
    [currentItem, fillSettings, getInitial],
  );
  useEffect(
    () => { fillSettings(); },
    [fillSettings, settings],
  );

  const handleSubmit = useCallback(
    () => {
      const loader = async () => {
        const r = await api.get('/api/financials/export/', auth, params);
        if (!r.ok) {
          if (r.status === 400) {
            const d = await r.json();
            const message = Object.keys(d).reduce((R, k) => `${R}${k}: ${d[k].join(', ')}, `, '');
            throw new Error(message);
          }
          throw new Error(`${r.status} ${r.statusText}`);
        }
        return r.json();
      };
      onLoadStart();
      loader()
        .then((r) => {
          onSetSettings({
            ...params,
            authority,
            authority_receiver: authorityReceiver,
            nob,
            nazb,
            nra,
          });
          if (noArchive) {
            const files = r.file.map((f) => downloadFileFromJSON(f.file, f.filename));
            return files;
          }
          const filename = 'Arhive.zip';
          return downloadFileFromJSON(r.file, filename);
        })
        .catch((e) => onError(e.message))
        .finally(() => {
          onLoadEnd();
        });
    },
    [onLoadStart, auth, params, onSetSettings, authority, authorityReceiver,
      nob, nazb, nra, noArchive, onError, onLoadEnd],
  );
  return {
    loading,
    err,
    setErr,
    handleSubmit,
    variant,
    noArchive,
    setNoArchive,
    dbf373Long,
    setDbf373Long,
    dbf3732,
    setDbf3732,
    br,
    setBr,
    winCode,
    setWinCode,
    bigNote,
    setBigNote,
    useSk,
    setUseSk,
    collapseRows,
    setCollapseRows,
    collapseWithoutNote,
    setCollapseWithoutNote,
    shortDisposerName,
    setShortDisposerName,
    budgetCodeAsKoatuu,
    setBudgetCodeAsKoatuu,
    dbfDate,
    setDbfDate,
    addDbf,
    setAddDbf,
    upperCaseFilename,
    setUpperCaseFilename,
    underscoreFilename,
    setUnderscoreFilename,
    accountFilename,
    setAccountFilename,
    numberInFilename,
    setNumberInFilename,
    buhRank,
    setBuhRank,
    buhSign,
    setBuhSign,
    directorRank,
    setDirectorRank,
    directorSign,
    setDirectorSign,
    authority,
    setAuthority,
    authorityReceiver,
    setAuthorityReceiver,
    nob,
    setNob,
    nazb,
    setNazb,
    nra,
    setNra,
    beginDate,
    setBeginDate,
    endDate,
    setEndDate,
    docs,
    setDocs,
    getDocList,
    setActiveDoc,
    onToggleDoc,
    activeDoc,
  };
};

export default useExportFinancialsToDBF;
