import React from 'react';
import {
  Button, Col, Row, Container, ButtonGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import md from '../../../meta/doc/allocationPlanChanges';
// eslint-disable-next-line import/no-cycle
import {
  EditorCheckboxInput, EditorControls,
  EditorDateInput,
  EditorStingInput,
} from '../../basicEditor/editorControls';
import DocList from '../../instances/individual/doc/allocationplanchanges/docs';
import { CPButton } from '../../../components/bootStrap/buttons/styles';
import useExportToDBF from './hook';
import IconAlert from '../../../components/blanks/common/IconAlert';
import { Loader } from '../../../components/bootStrap';

const types = [
  { value: 1, display_name: 'Рік' },
  // { value: 2, display_name: 'Квартал' },
  { value: 3, display_name: 'Місяць' },
];

const assignTypes = [
  { value: 2, display_name: 'Асигнування' },
  { value: 3, display_name: 'Надання кредитів' },
  { value: 4, display_name: 'Повернення кредитів' },
];

const codeInNameTypes = [
  { value: 0, display_name: 'Немає' },
  { value: 1, display_name: 'Гол.розпорядник' },
  { value: 2, display_name: 'Установа' },
];

const loadVariantTypes = [
  { value: 1, display_name: 'Видатки' },
  { value: 2, display_name: 'Джерела фінансування' },
];

function ExportChangesToDBFEditor({ currentItem, backendURL }) {
  const {
    loading, err, setErr, isFO,
    type, setType, loadVariant, setLoadVariant, noArchive, setNoArchive,
    oneFile, setOneFile, addF6, setAddF6, addF7, setAddF7, noKDB, setNoKDB,
    assign, setAssign, codeInName, setCodeInName, natForm, setNatForm, fond2Or3,
    noYearSummIfZero, setNoYearSummIfZero,
    onlyYear, setOnlyYear, noYearSumm, setNoYearSumm, byMainDisposer, setByMainDisposer,
    filenameZMINV, setFilenameZMINV,
    nom, setNom, nr, setNr, beginDate, setBeginDate, endDate, setEndDate,
    docs, setDocs, getDocList, handleSubmit, setActiveDoc, onToggleDoc, activeDoc,
  } = useExportToDBF({ currentItem, backendURL });
  return (
    <Container fluid>
      {loading && <Loader />}
      {err && (
        <IconAlert dismissible variant="danger" onClose={() => setErr(null)}>
          {err}
        </IconAlert>
      )}
      {isFO ? (
        <>
          <Row>
            <Col>
              <EditorControls.RadioInput
                value={type}
                values={types}
                onChange={(e, v) => setType(v)}
                className="d-flex gap-2"
              />
            </Col>
            <Col>
              {backendURL === md.backendURL && (
                <EditorControls.RadioInput
                  label="Варіант вивантаження"
                  value={loadVariant}
                  values={loadVariantTypes}
                  onChange={(e, v) => setLoadVariant(v)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <EditorCheckboxInput
                controlId="noArchive"
                label="Не архівувати файли"
                value={noArchive}
                onChange={(e, v) => setNoArchive(v)}
              />
            </Col>
            <Col>
              <EditorCheckboxInput
                controlId="oneFile"
                label="Вивантажити в один файл"
                value={oneFile}
                onChange={(e, v) => setOneFile(v)}
              />
            </Col>
            <Col>
              <EditorCheckboxInput
                controlId="addF6"
                label="Записувати спецфонд інші кошти без бюджету розвитку окремо"
                value={addF6}
                onChange={(e, v) => setAddF6(v)}
              />
            </Col>
            <Col>
              <EditorCheckboxInput
                controlId="addF7"
                label="Завжди дописувати підсумки по інших коштах спецфонду"
                value={addF7}
                onChange={(e, v) => setAddF7(v)}
              />
            </Col>
            <Col>
              {backendURL === md.backendURL && (
                <EditorCheckboxInput
                  controlId="noKDB"
                  label="Не заповнювати КДБ"
                  value={noKDB}
                  onChange={(e, v) => setNoKDB(v)}
                />
              )}
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <Row>
              <Col>
                <EditorControls.RadioInput
                  label="Вид даних що вивантажуються"
                  value={assign}
                  values={assignTypes}
                  onChange={(e, v) => setAssign(v)}
                />
              </Col>
              <Col>
                <EditorControls.RadioInput
                  label="Код розпорядника в імені файла "
                  value={codeInName}
                  values={codeInNameTypes}
                  onChange={(e, v) => setCodeInName(v)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <EditorCheckboxInput
                      controlId="natForm"
                      label="Назва файлу довідки про надходження у натуральній формі  DNF_"
                      description="Формується для 2 та 3 фондів"
                      value={natForm}
                      disabled={!fond2Or3}
                      onChange={(e, v) => setNatForm(v)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <EditorCheckboxInput
                      controlId="noYearSummIfZero"
                      label="Не записувати дані по яким річні суми дорівнюють '0'"
                      value={noYearSummIfZero}
                      onChange={(e, v) => setNoYearSummIfZero(v)}
                    />
                  </Col>
                  <Col>
                    <EditorCheckboxInput
                      controlId="onlyYear"
                      label="Тільки річні суми"
                      value={onlyYear}
                      onChange={(e, v) => setOnlyYear(v)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <EditorCheckboxInput
                  controlId="noYearSumm"
                  label="Не вивантажувати річні суми"
                  value={noYearSumm}
                  onChange={(e, v) => setNoYearSumm(v)}
                />
              </Col>
              <Col>
                <EditorCheckboxInput
                  controlId="byMainDisposer"
                  label="Записувати у файл дані по головному розпоряднику, а не по установі"
                  value={byMainDisposer}
                  onChange={(e, v) => setByMainDisposer(v)}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              {/* TODO якщо ніхто не звернеться по цю галку прибрати її всюди,
                  таке як вона не треба */}
              {/* <Col> */}
              {/*  <EditorCheckboxInput */}
              {/*    controlId="asReference" */}
              {/*    label="Вивантажувати планові документи у форматі документів для змін" */}
              {/*    disabled={natForm} */}
              {/*    value={asReference} */}
              {/*    onChange={(e, v) => setAsReference(v)} */}
              {/*  /> */}
              {/* </Col> */}
              <Col>
                <EditorCheckboxInput
                  controlId="filenameZMINV"
                  label="Назва файлу змін  ZMINV_"
                  value={filenameZMINV}
                  description="Формується для формату документів для змін"
                  onChange={(e, v) => setFilenameZMINV(v)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditorStingInput
                  controlId="nom"
                  maxLength={10}
                  label="Значення для поля  NOM тобто номеру реєстру"
                  value={nom}
                  description="Формується для формату документів для змін, якщо
                  не вказати  значення  тоді  буде присвоєно номер довідки"
                  onChange={(e, v) => setNom(v)}
                />
              </Col>
              <Col>
                <EditorStingInput
                  controlId="nr"
                  maxLength={4}
                  label="Номер реєстру (при вивантаженні змін)"
                  value={nr}
                  description="Формується для формату документів для змін"
                  onChange={(e, v) => setNr(v)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <EditorDateInput
            controlId="begin_date"
            label="Відібрати документи за період з"
            required
            value={beginDate}
            onChange={(e, v) => setBeginDate(v)}
          />
        </Col>
        <Col>
          <EditorDateInput
            controlId="end_date"
            label="по"
            required
            value={endDate}
            onChange={(e, v) => setEndDate(v)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonGroup>
            <CPButton
              onClick={() => setDocs(docs.map((d) => ({ ...d, use: true })))}
              title="Обрати всі"
              icon={faCheckSquare}
            />
            <CPButton
              onClick={() => setDocs(docs.map((d) => ({ ...d, use: false })))}
              title="Зняти відмітки"
              icon={faSquare}
            />
          </ButtonGroup>
          <Button
            variant="success"
            onClick={() => getDocList()}
          >
            Відібрати документи
          </Button>
          <Button onClick={handleSubmit}>
            <FontAwesomeIcon
              icon={faFileExport}
              className="mr-2"
            />
            Вивантажити
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <DocList
            docs={docs}
            onSelectDoc={setActiveDoc}
            onToggleDoc={onToggleDoc}
            activeDoc={activeDoc}
          />
        </Col>
      </Row>
    </Container>
  );
}

ExportChangesToDBFEditor.propTypes = {
  currentItem: PropTypes.arrayOf(PropTypes.string).isRequired,
  backendURL: PropTypes.string.isRequired,
};

export default ExportChangesToDBFEditor;
