const frontendURL = '/doc/countExpensesOnEducation/';
const backendURL = '/api/merezha/calculatedexpensesforwagesheader/';
const name = 'Розрахункова потреба у видатках на оплату праці з нарахуваннями працівників закладів загальної середньої освіти ';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
