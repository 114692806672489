import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile, faCheck, faTimes, faSmile, faThumbsUp, faThumbsDown, faMeh,
} from '@fortawesome/free-solid-svg-icons';
import HeaderCell from './headerCell';

const StyledTd = styled.td.attrs((props) => ({
  style: {
    color: props.color,
    fontWeight: props.fontWeight,
  },
}))`
`;

const bsCodes = ['BLE0020103', 'BLE0020104'];

function FilteredTable({
  columns, onSetFilter, onClearFilter, setSelectedBs,
  onSetOrder, displayList, code, selectedIds, setSelectedIds,
}) {
  return (
    <Table size="sm">
      <thead>
        <tr>
          {columns.map((clmn) => (
            <HeaderCell
              key={clmn.code}
              label={clmn.label}
              name={clmn.code}
              canFilter={clmn.canFilter}
              canOrder={clmn.canOrder}
              canSearch={clmn.canSearch}
              data={displayList}
              onSetFilter={onSetFilter}
              onClearFilter={onClearFilter}
              onSetOrder={onSetOrder}
              code={code}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {displayList.map((row) => (
          <tr
            key={row.id}
            onClick={(e) => {
              if (bsCodes.includes(row.code)) {
                setSelectedBs((prev) => {
                  if (prev.includes(row.id)) {
                    const index = prev.indexOf(row.id);
                    prev.splice(index, 1);
                    return prev;
                  }
                  return [...prev, row.id];
                });
              } else setSelectedBs([]);
              if (e.ctrlKey && !selectedIds.includes(row.id)) {
                setSelectedIds((prev) => [...prev, row.id]);
              } else if (e.ctrlKey && selectedIds.includes(row.id)) {
                setSelectedIds((prev) => prev.filter((i) => i !== row.id));
              } else (setSelectedIds([row.id]));
            }}
            style={{ backgroundColor: selectedIds.includes(row.id) && '#007bff' }}
          >
            {columns.map((clmn) => (
              <StyledTd
                key={clmn.code}
                color={
                  // eslint-disable-next-line no-nested-ternary
                  (selectedIds.includes(row.id)) ? 'white'
                  // eslint-disable-next-line no-nested-ternary
                    : (clmn.code === '_ACTUAL') || (clmn.code === '_SIGNEDBYFINDEP') ? row.analytics[clmn.code] === 'Так' ? 'var(--falcon-success)' : 'var(--falcon-danger)'
                    // eslint-disable-next-line no-nested-ternary
                      : (clmn.code === 'success') ? row.analytics[clmn.code] === 'Верифіковано' ? 'var(--falcon-success)' : 'var(--falcon-danger)'
                        // eslint-disable-next-line no-nested-ternary
                        : (clmn.code === '_PROJAPPROVED' && !selectedIds.includes(row.id)) ? (row.analytics[clmn.code] === 'Затверджено' ? 'var(--falcon-primary)' : 'var(--falcon-warning)')
                          : undefined
                }
                fontWeight={
                  (clmn.code === '_ACTUAL' || (clmn.code === '_SIGNEDBYFINDEP')) && row.analytics[clmn.code] === 'Так' ? 'bold' : undefined
                }
              >
                {clmn.code === 'success' && (row.analytics[clmn.code] === 'Верифіковано'
                  && (<FontAwesomeIcon icon={faCheck} mask={faFile} className="mr-2" size="lg" />))}

                {clmn.code === 'success' && (row.analytics[clmn.code] === 'З помилками'
                  && (<FontAwesomeIcon icon={faTimes} mask={faFile} className="mr-2" size="lg" />))}

                {(clmn.code === '_ACTUAL' || (clmn.code === '_SIGNEDBYFINDEP')) && (row.analytics[clmn.code] === 'Так'
                    && (<FontAwesomeIcon icon={faThumbsUp} className="mr-2" size="lg" />))}

                {(clmn.code === '_ACTUAL' || (clmn.code === '_SIGNEDBYFINDEP')) && (row.analytics[clmn.code] === 'Ні'
                    && (<FontAwesomeIcon icon={faThumbsDown} className="mr-2" size="lg" />))}

                {clmn.code === '_PROJAPPROVED' && (row.analytics[clmn.code] === 'Затверджено'
                    && (<FontAwesomeIcon icon={faSmile} className="mr-2" size="lg" />))}

                {clmn.code === '_PROJAPPROVED' && (row.analytics[clmn.code] === 'Проект'
                    && (<FontAwesomeIcon icon={faMeh} className="mr-2" size="lg" />))}

                {clmn.code === 'lastEditDate' || clmn.code === 'addDate'
                  ? (new Date(row[clmn.code])).toLocaleString('uk', {
                    day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit',
                  }) : row.analytics[clmn.code] }
              </StyledTd>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

FilteredTable.propTypes = {
  displayList: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    canFilter: PropTypes.bool,
  })).isRequired,
  code: PropTypes.string.isRequired,
  onSetFilter: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setSelectedBs: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FilteredTable;
