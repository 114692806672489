const frontendURL = '/doc/dataEducationPayment/';
const backendURL = '/api/merezha/expensesforwagesheader/';
const name = 'Дані про видатки на оплату праці з нарахуваннями працівників закладів загальної середньої освіти ';

const instance = {
  frontendURL,
  backendURL,
  name,
};

export default instance;
